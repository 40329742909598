import { useState, useEffect } from "react";
import ExchangeCandleChart from "./exchangeCandleChart";
import ExchangeLineChart from "./exchangeLineChart";
import useGetTrades from "../../../hooks/api/useGetTrades";
import { TRADE_TIMES } from "../../../data/trades";
import SkeletonLoader from "../../../components/skeletonLoader/skeletonLoader";

const ExchangeChartComponent = ({ symbol = "QQB", chartActiveTab }) => {
  const { data, getTrades, loading } = useGetTrades();
  const [time, setTime] = useState(TRADE_TIMES.ONE_DAY);

  const updateTime = (timeline) => {
    setTime(timeline);
  };

  useEffect(() => {
    getTrades(time, symbol);
  }, [time, symbol]);

  return (
    <>
      <div className="line-chart-toolbar">
        <button
          onClick={() => updateTime(TRADE_TIMES.TWELVE_HOUR)}
          className={`line_chart_button ${
            time === TRADE_TIMES.TWELVE_HOUR ? "chart_button_active" : ""
          }`}
        >
          12H
        </button>
        &nbsp;
        <button
          onClick={() => updateTime(TRADE_TIMES.ONE_DAY)}
          className={`line_chart_button ${
            time === TRADE_TIMES.ONE_DAY ? "chart_button_active" : ""
          }`}
        >
          1D
        </button>
        &nbsp;
        <button
          onClick={() => updateTime(TRADE_TIMES.ONE_WEEK)}
          className={`line_chart_button ${
            time === TRADE_TIMES.ONE_WEEK ? "chart_button_active" : ""
          }`}
        >
          1W
        </button>
        &nbsp;
      </div>

      {loading || data.length == 0 ? null : chartActiveTab === "line" ? (
        <ExchangeLineChart data={data} />
      ) : (
        <ExchangeCandleChart />
      )}
      {loading == false && data.length == 0 ? (
        <div className="exchange_chart_component_no_trade_found_countainer">
          <p style={{ fontSize: 18 }}>
            No trades found on token <strong>{symbol}</strong>
          </p>
        </div>
      ) : null}
      {loading ? (
        <div
          style={{ height: 350 }}
          className="exchange_chart_component_no_trade_found_countainer"
        >
          <SkeletonLoader />
        </div>
      ) : null}
    </>
  );
};

export default ExchangeChartComponent;
