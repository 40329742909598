import Avatar from "@mui/material/Avatar";
import Box from "@mui/material/Box";
import Stack from "@mui/material/Stack";
import * as moment from "moment";
import React, { useContext, useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import UserContext from "../../context/UserContext";
import GppGoodIcon from "@mui/icons-material/GppGood";
import FollowUnfollow from "../buttons/Follow-Unfollow";
import classes from "./index.module.css";

export const SidebarCards = () => {
  const navigate = useNavigate();
  const [streams, setStreams] = useState([]);
  const [streamers, setStreamers] = useState([]);
  const [topMonthlyStreams, setTopMonthlyStreams] = useState([]);
  const [topMonthlyStreamers, setTopMonthlyStreamers] = useState([]);
  const { servApiUrl, user } = useContext(UserContext);

  useEffect(() => {
    const getStreamers = () => {
      if (!user) return;
      let requestOptions = {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + localStorage.getItem("pg-auth-token"),
        },
      };
      fetch(`${servApiUrl}streamer/streamers`, requestOptions)
        .then((response) => response.json())
        .then((data) => {
          setStreamers(data.slice(0, 3));
        });
    };

    const getStreams = () => {
      if (!user) return;
      let requestOptions = {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + localStorage.getItem("pg-auth-token"),
        },
      };
      fetch(`${servApiUrl}engine/foryou/clips`, requestOptions)
        .then((response) => response.json())
        .then((data) => {
          setStreams(data.slice(0, 3));
        });
    };

    const getTopMonthlyStreams = () => {
      if (!localStorage.getItem("pg-auth-token")) return;
      let requestOptions = {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + localStorage.getItem("pg-auth-token"),
        },
      };
      fetch(`${servApiUrl}streamer/getTopStreamsMonth`, requestOptions)
        .then((response) => response.json())
        .then((data) => {
          setTopMonthlyStreams(data);
        });
    };

    const getTopMonthlyStreamers = () => {
      if (!localStorage.getItem("pg-auth-token")) return;
      let requestOptions = {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + localStorage.getItem("pg-auth-token"),
        },
      };
      fetch(`${servApiUrl}streamer/getTopStreamersMonth`, requestOptions)
        .then((response) => response.json())
        .then((data) => {
          setTopMonthlyStreamers(data);
        });
    };

    getStreamers();
    getStreams();

    getTopMonthlyStreams();
    getTopMonthlyStreamers();
  }, []);

  return (
    <div className={classes.sidebarcards_main_div}>
      <p className={classes.youMayLike}>Top Streamers this month</p>
      <div className={classes.mobile_card}>
        {/* {Object.keys(streamers).map((keyName) => {
            const card = streamers[keyName];
            return (
              <div
                onClick={() => navigate("/streamer-profile?id=" + card._id)}
                className={classes.sidebarCard_main}
                style={{ cursor: "pointer" }}
              >
                <Stack direction="row" spacing={2}>
                  <Avatar
                    alt="Avatar"
                    src={card.imageUrl}
                    className={classes.cardAvatar}
                  />
                  <div className={classes.card_details}>
                    <p>
                      {card.username}
                      <span>
                        <img alt="icon" src={tickIcon} />
                      </span>
                    </p>
                    <p>
                      {card.followers} followers |{" "}
                      <span>{card.viewsAccumulated} views</span>
                    </p>
                    <FollowUnfollow userId={card._id} />
                  </div>
                </Stack>
              </div>
            );
          })} */}
        {topMonthlyStreamers?.map((streamer, index) => (
          <div
            onClick={() => navigate("/streamer-profile?id=" + streamer._id)}
            className={classes.sidebarCard_main}
            style={{ cursor: "pointer" }}
            key={`monthly-streamers-${index}`}
          >
            <Stack direction="row" spacing={2}>
              <Avatar
                alt="Avatar"
                src={streamer.avatar}
                className={classes.cardAvatar}
              />
              <div className={classes.card_details}>
                <p>
                  {streamer.name}
                  <span>
                    <GppGoodIcon sx={{ color: "white", fontSize: "12px" }} />
                  </span>
                </p>
                <p>
                  {streamer.followers} followers |{" "}
                  <span>{streamer.total_viewers} views</span>
                </p>
                <FollowUnfollow userId={streamer._id} />
              </div>
            </Stack>
          </div>
        ))}
      </div>
      <Box sx={{ mt: 4 }} />
      <p className={classes.youMayLike}>Top Streams this month</p>
      <div className={classes.mobile_card}>
        {/* {Object.keys(streams).map((keyName) => {
            const card2 = streams[keyName];
            return (
              <Link to={{pathname : "/stream-video?id="+card2?._id }} className={classes.link_style}>
                <div className={classes.sidebarCard_main}>
                  <Stack direction="row" spacing={2}>
                    <Avatar
                      alt="Avatar"
                      src={card2.imageUrl}
                      variant="rounded"
                      className={classes.cardAvatar}
                    />
                    <div className={classes.card_details2}>
                      <p style={{color: "#fff"}}>{card2.programName}</p>
                      <p>{card2.uid ? card2.uid.name : ""}</p>
                      <p>
                        {card2.viewCount} views .{" "}
                        <span>{moment(card2.createdAt).fromNow()}</span>
                      </p>
                    </div>
                  </Stack>
                </div>
              </Link>
            );
          })} */}
        {topMonthlyStreams?.map((streamer, index) => (
          <Link
            to={{
              pathname: "/streamer-streaming",
              search: "?id=" + streamer?._id,
            }}
            key={`monthly-streams-${index}`}
            className={classes.link_style}
          >
            <div className={classes.sidebarCard_main}>
              <Stack direction="row" spacing={2}>
                <Avatar
                  alt="Avatar"
                  src={streamer.streamer_avatar}
                  variant="rounded"
                  className={classes.cardAvatar}
                />
                <div className={classes.card_details2}>
                  <p style={{ color: "#fff" }}>{streamer.title}</p>
                  <p>{streamer.streamer_name ? streamer.streamer_name : ""}</p>
                  <p>
                    {streamer.viewers} views .{" "}
                    <span>{moment(streamer.date).fromNow()}</span>
                  </p>
                </div>
              </Stack>
            </div>
          </Link>
        ))}
      </div>
    </div>
  );
};
