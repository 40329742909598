import { memo, useEffect } from "react";
import { useNavigate } from "react-router-dom";

import "./mobileAppBar.css";
// import HomeOutlinedIcon from "@mui/icons-material/HomeOutlined";
// import nftIcon from "../../images/nft-icon.png";
// import exchangeIcon from "../../images/icons/mobile-chart.svg";
// import ExploreOutlinedIcon from "@mui/icons-material/ExploreOutlined";
import { useUserContext } from "../../context/UserContext";
import { PAGES } from "../../data/pages";
import useGetCurrentPage from "../../hooks/useGetCurrentPage";
import PeopleIcon from "@mui/icons-material/People";
import InsightsOutlinedIcon from "@mui/icons-material/InsightsOutlined";
import SmartDisplayIcon from "@mui/icons-material/SmartDisplay";
import ChatIcon from "@mui/icons-material/Chat";
import Person4Icon from "@mui/icons-material/Person4";
import MobileDrawer from "../mobile/drawer/mobileDrawer";

const muiIconStyle = { fontSize: "24px", color: "var(--text-color)" };
const MobileAppBar = () => {
  const navigate = useNavigate();
  const { isLoggedIn } = useUserContext();
  const { page } = useGetCurrentPage();

  const getActiveLinkIndex = () => {
    switch (page) {
      case PAGES.HOME:
        return 0;
      case PAGES.EXCHANGE:
        return 1;
      case PAGES.POLY_VERSE:
        return 2;
      case PAGES.CHATS:
        return 3;
      case PAGES.POLY_COMMUNITY:
        return 4;
      default:
        return 0;
    }
  };

  const removeListActiveClass = (list) => {
    list.forEach((item) => item.classList.remove("active"));
  };

  const appBarFuncitonality = () => {
    const list = document.querySelectorAll(".list");

    list.forEach((item) =>
      item.addEventListener("click", () => {
        removeListActiveClass(list);
        item.classList.add("active");
      })
    );

    const activeLi = list[getActiveLinkIndex()];
    removeListActiveClass(list);

    activeLi.classList.add("active");
  };

  useEffect(() => {
    appBarFuncitonality();
  }, []);

  const onClickLink = (link) => {
    navigate(link);
  };

  return (
    <>
      <MobileDrawer />

      <div className="navigation-container">
        <div className="navigation">
          <ul>
            <li className="list">
              <a
                onClick={() =>
                  onClickLink(isLoggedIn ? PAGES.HOME : PAGES.HOME)
                }
              >
                <span className="icon">
                  <SmartDisplayIcon sx={muiIconStyle} />
                </span>
                <span className="text">PolyStream</span>
              </a>
            </li>
            {/* <li className="list">
              <a onClick={() => onClickLink("/nft-marketplace")}>
                <span className="icon">
                  <img src={nftIcon} alt="NFT" style={iconStyle} />
                </span>
                <span className="text">PolyNFT</span>
              </a>
            </li> */}
            <li className="list">
              <a onClick={() => onClickLink(PAGES.EXCHANGE)}>
                <span className="icon">
                  <InsightsOutlinedIcon sx={muiIconStyle} />
                </span>
                <span className="text">PolyX</span>
              </a>
            </li>
            <li className="list">
              <a onClick={() => onClickLink(PAGES.POLY_VERSE)}>
                <span className="icon">
                  <Person4Icon sx={muiIconStyle} />
                </span>
                <span className="text">PolyVerse</span>
              </a>
            </li>
            <li className="list">
              <a onClick={() => onClickLink(PAGES.CHATS)}>
                <span className="icon">
                  <ChatIcon sx={muiIconStyle} />
                </span>
                <span className="text">PolyChat</span>
              </a>
            </li>
            <li className="list">
              <a onClick={() => onClickLink(PAGES.POLY_COMMUNITY)}>
                <span className="icon">
                  <PeopleIcon sx={muiIconStyle} />
                </span>
                <span className="text">Community</span>
              </a>
            </li>
            <div className="indicator" />
          </ul>
        </div>
      </div>
    </>
  );
};

export default memo(MobileAppBar);
