import React from "react";
import Navbar from "./components/navbar";
import classes from "./default.module.css";
import Content from "./components/content";
import TelegramIcon from "@mui/icons-material/Telegram";

const DefaultLayout = ({
  children,
  breadCrumbs = [
    {
      title: "Welcome Back!",
      image: (
        <TelegramIcon
          sx={{ fontSize: "40px", color: "var(--primary-color)" }}
        />
      ),
    },
  ],
}) => {
  return (
    <main className={classes.container}>
      <Navbar />
      <div className={classes.content}>
        <Content breadCrumbs={breadCrumbs}>{children}</Content>
      </div>
    </main>
  );
};

export default DefaultLayout;
