import {
  BannerAdSize,
  BannerAdPosition,
  AdMob,
} from "@capacitor-community/admob";

const initializeAdmob = async () => {
  await AdMob.initialize({
    requestTrackingAuthorization: true,
  });
};

// Show banner ad
const showAdmobBanner = async () => {
  const options = {
    // For production
    adId: "ca-app-pub-7167498207118697/1302832590",
    // For testing
    // adId: "ca-app-pub-3940256099942544/6300978111",
    adSize: BannerAdSize.BANNER,
    position: BannerAdPosition.BOTTOM_CENTER,
    margin: 0,
  };
  AdMob.showBanner(options);
};

export const setupAdmobBanner = async () => {
  await initializeAdmob();
  showAdmobBanner();
};

// Stop playing video when ad is played
// Start playing video when ad is closed
export const showInterstitialVideoAd = async () => {
  const options = {
    adId: "ca-app-pub-7167498207118697/9921097758",
  };

  await AdMob.prepareInterstitial(options);
  await AdMob.showInterstitial();
};
