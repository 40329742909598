import React, { Fragment, useState, useContext } from "react";
import UserContext from "../../context/UserContext";
import "./chatbox.css";

function ChatBox() {
  const [messages, setMessages] = useState([
    {
      text: "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries",
      by_me: false,
    },
    {
      text: "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries",
      by_me: true,
    },
  ]);

  const { show_chat_box, user, setShowChatBox } = useContext(UserContext);

  const addMessage = () => {
    if (new_message.trim() !== "") {
      setMessages([...messages, { text: new_message, by_me: true }]);
      var elem = document.getElementById("chats_container");
      elem.scrollTop = elem.scrollHeight;
    }
    setNewMessage("");
  };

  const [new_message, setNewMessage] = useState("");

  if (!show_chat_box || !user) return null;

  return (
    <div id="chatbox_main_container">
      <div id="chatbox_top_bar">
        <div>
          <div></div>
          <span>
            <h5> Name </h5>
            <p>email</p>
          </span>
        </div>
        <button
          id="chatbox_close_modal"
          onClick={(e) => {
            e.preventDefault();
            setShowChatBox(!show_chat_box);
          }}
        >
          <i className="fa-solid fa-xmark"></i>
        </button>
      </div>
      <div id="chats_container">
        {messages.map((m) => {
          return m.by_me ? (
            <div className="outgoing_msg_row">
              <div className="outgoing_msg">{m.text}</div>
            </div>
          ) : (
            <div className="incoming_msg_row">
              <div className="incoming_msg"> {m.text} </div>{" "}
            </div>
          );
        })}
      </div>
      <div id="msg_input_box">
        <textarea
          placeholder="New Message"
          rows={2}
          value={new_message}
          onChange={(e) => {
            setNewMessage(e.target.value);
          }}
        />
        <button
          onClick={(e) => {
            e.preventDefault();
            addMessage();
          }}
        >
          <i className="fa-solid fa-paper-plane"></i>
        </button>
      </div>
    </div>
  );
}

export default ChatBox;
