import React, { useContext, useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import FollowUnfollow from "../../components/buttons/Follow-Unfollow";
// import ShareDialog from "../../components/dialogs/ShareDialog";

import UserContext from "../../context/UserContext";
import DashboardLayout from "../../wrappers/dashboard/index";
import classes from "./final.module.css";
import "./index.css";

import Box from "@mui/material/Box";
import Like from "../../images/like";
import LikeFilled from "../../svgs/heartFilled.svg";

import Button from "@mui/material/Button";

// import { Capacitor } from "@capacitor/core";

import VideoPlayer from "./videoplayer";
// import UserPgemCounter from "./components/userPgemCounter/userPgemCounter";
import useWindowSize from "../../hooks/useWindowSize";
import WatchToEarnAnimation from "../../components/watchToEarnAnimation/watchToEarnAnimation";
import SkeletonLoader from "../../components/skeletonLoader/skeletonLoader";
import DesktopLiveChat from "../../components/liveChats/desktopLiveChat";
import MobileLiveChat from "../../components/liveChats/mobileLiveChat";
import GoLive from "./components/goLiveStreamer";
import LinkSharing from "./components/linkSharing";
import { Capacitor } from "@capacitor/core";
import { LiveStreamActions } from "./final.constants";
import { getRequestOptions } from "../../functions/auth";
import ShareDialog from "../../components/dialogs/ShareDialog";
// import AdMobBanner from "../../components/mobile/admobBanner/admobBanner";

// const Share = ({ url }) => {
//   return <ShareDialog url={url} />;
// };

const ProfileDetails = ({
  data = {},
  customButton,
  btnProps = {},
  UserDetails,
}) => {
  // const [totalStreamerViews, setTotalStreamerViews] = useState(0);
  // const { servApiUrl } = useContext(UserContext);
  // useEffect(() => {
  //   const getTotalStreamerViews = () => {
  //     try {
  //       const requestOptions = {
  //         method: "GET",
  //         headers: {
  //           "Content-Type": "application/json",
  //           Authorization: "Bearer " + localStorage.getItem("pg-auth-token"),
  //         },
  //       };

  //       fetch(
  //         `${servApiUrl}streamer/getTotalStreamerViews/${data._id}`,
  //         requestOptions
  //       )
  //         .then((data) => data.json())
  //         .then((data) => {
  //           setTotalStreamerViews(data);
  //         });
  //     } catch (error) {
  //       console.log(error);
  //     }
  //   };
  //   // if (data?._id) {
  //   //   getTotalStreamerViews();
  //   // }
  // }, [data]);
  return (
    <div
      style={{
        width: "100%",
        height: "max-content",
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
      }}
    >
      <div style={{ display: "flex", alignItems: "center", gap: "10px" }}>
        {/* <Avatar
          src={data.avatar || Avatarprofile}
          sx={{ width: 65, height: 65 }}
        /> */}

        <div
          style={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
          }}
        >
          {UserDetails ? (
            <UserDetails />
          ) : (
            <>
              {/* <Link
                to={`/streamer-profile?id=${data._id}`}
                style={{ textDecoration: "none" }}
              >
                <p style={{ margin: 0, color: "#C6FF00" }}>
                  {data.name || data.username}
                </p>
              </Link> */}

              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  gap: "10px",
                  margin: 0,
                }}
              >
                {/* <div
                  style={{
                    display: "flex",
                    gap: "4px",
                    alignItems: "center",
                    fontSize: "13px",
                  }}
                >
                  <img src={viewr} />
                  <span style={{ margin: 0 }}>
                    {totalStreamerViews.total_views || 0}
                  </span>
                </div> */}
                {/* <div
                  style={{
                    display: "flex",
                    gap: "4px",
                    alignItems: "center",
                    fontSize: "13px",
                  }}
                >
                  <img src={user} />
                  <span style={{ margin: 0 }}>{data.followers || 0}</span>
                </div> */}
              </div>
            </>
          )}
        </div>
      </div>

      <div className="unblockbutton">
        {customButton || (
          <Button
            style={{
              textTransform: "none",
              padding: "5px 20px",
              fontSize: "0.8rem",
              borderRadius: "20px",
              color: "black",
              backgroundColor: "#f2994a",
            }}
            onClick={btnProps?.onClick || null}
            variant="contained"
            disableElevation
          >
            Unblock
          </Button>
        )}
      </div>
    </div>
  );
};

let userWatchSessionId;

const StreamerStreaming = () => {
  const location = useLocation();
  const stream_id_location = new URLSearchParams(location.search).get("id");
  const [stream, setStream] = useState({});
  const [streamer, setStreamer] = useState(null);
  const [streamStatus, setStreamStatus] = useState("upcoming");
  const [liveData, setLiveData] = useState(null);
  const { servApiUrl, socket, user } = useContext(UserContext);
  const [playerReady, setPlayerReady] = useState(false);
  const [width] = useWindowSize();
  const isStreamer = user.role == "streamer" && user._id == stream?.uid;

  const addStreamTime = async (streamId) => {
    let requestOptions = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + localStorage.getItem("pg-auth-token"),
      },
      body: JSON.stringify({
        streamId: streamId,
      }),
    };
    fetch(`${servApiUrl}user/addStreamTime`, requestOptions)
      .then((response) => response.json())
      .then((data) => {});
  };

  const [commentsArr, setCommentsArr] = useState([]);

  const updateCommentsArrayState = (comment) => {
    const commentsArrClone = [...commentsArr];
    commentsArrClone.push(comment);
    setCommentsArr(commentsArrClone);
  };

  var comment = () => {
    let text = document.getElementById("comment-text-streaming")?.value;
    if (!text || typeof text != "string" || text.trim().length == 0) {
      return false;
    }

    const commentObject = {
      comment: text.trim(),
      uid: {
        name: user.name,
        username: user.username,
        _id: user._id,
      },
      updatedAt: new Date(),
    };
    updateCommentsArrayState(commentObject);

    socket.emit(LiveStreamActions.SendMessage, {
      room: stream._id,
      text: text.trim(),
      user: { name: user.name, username: user.username, _id: user._id },
    });

    const chatBox = document.getElementById("comment-text-streaming");
    if (chatBox) {
      chatBox.value = "";
    }

    const payload = {
      sessionId: stream._id,
      comment: text.trim(),
    };

    const requestOptions = getRequestOptions("POST", true, payload);

    fetch(`${servApiUrl}session/comment`, requestOptions);
  };

  var getComments = (streamId) => {
    let requestOptions = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + localStorage.getItem("pg-auth-token"),
      },
      body: JSON.stringify({ sessionId: streamId }),
    };
    let getCommentsApiUrl = `${servApiUrl}session/search/comment/`;
    fetch(getCommentsApiUrl, requestOptions)
      .then((response) => response.json())
      .then((data) => {
        setCommentsArr(data);
      });
  };

  useEffect(() => {
    socket.on(LiveStreamActions.ReceiveMessage, (data) => {
      if (!data) return;

      const commentObject = {
        comment: data.text,
        uid: data.user,
        updatedAt: new Date(),
      };
      if (commentObject.uid?._id != user._id) {
        updateCommentsArrayState(commentObject);
      }
    });
  }, [socket, commentsArr]);

  const getStreamerDetails = async (streamerId) => {
    try {
      const requestOptions = {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + localStorage.getItem("pg-auth-token"),
        },
        body: JSON.stringify({ _id: streamerId }),
      };
      const response = await fetch(
        `${servApiUrl}streamer/search/profile/`,
        requestOptions
      );

      if (response.status !== 200) throw new Error("error");
      const streamer = await response.json();
      setStreamer(streamer);
    } catch (error) {}
  };

  const getStream = async (streamId) => {
    try {
      const requestOptions = {
        method: "GET",
        headers: {
          Authorization: "Bearer " + localStorage.getItem("pg-auth-token"),
        },
      };

      const response = await fetch(
        `${servApiUrl}event/event/` + streamId,
        requestOptions
      );

      if (response.status !== 200) throw new Error("error");

      const stream = await response.json();
      getStreamerDetails(stream.uid);
      setStream(stream);
      getComments(stream?._id);
      addStreamTime(stream?._id);
      if (stream.status == "live") {
        setLiveData({
          key: stream.pushKey,
          url: stream.pushUrl,
          playback: stream.playbackUrl,
        });
      }
    } catch (error) {}
  };

  const LikesCount = ({ stream }) => {
    const [likes, setLikes] = useState(stream.likes || 0);
    const { setMessage } = useContext(UserContext);

    useEffect(() => {
      setLikes(stream.likes);
    }, [stream]);

    const changeLikes = (liked) => {
      if (liked) setLikes((prev) => prev - 1);
      else setLikes((prev) => prev + 1);
    };

    const likeAndUnLikeStream = () => {
      try {
        const requestOptions = {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer " + localStorage.getItem("pg-auth-token"),
          },
          body: JSON.stringify({ sessionId: stream?._id }),
        };

        fetch(`${servApiUrl}session/like`, requestOptions)
          .then((data) => data.json())
          .then((data) => {
            setMessage({
              message: data.success ? "Like succesfull" : "Unlike successfull",
              type: "success",
            });
            getStream(stream?._id);
          });
      } catch (error) {
        setMessage({
          message: "Some error occured",
          type: "error",
        });
      }
    };

    return (
      <>
        <span style={{ cursor: "pointer" }} onClick={likeAndUnLikeStream}>
          {stream?.isLiked ? (
            <>
              <img
                src={LikeFilled}
                style={{ position: "relative", top: "-1px" }}
              />
            </>
          ) : (
            <>
              <Like style={{ position: "relative", top: "1px" }} />
            </>
          )}
        </span>
        <span>{stream?.likes}</span>
      </>
    );
  };

  const joinSocketSession = async (id) => {
    await socket.emit(LiveStreamActions.JoinLiveStream, {
      sessionId: id,
      userId: user._id,
      userWatchSessionId: userWatchSessionId,
      role: user.role,
    });
  };

  const leaveSocketSession = async (id) => {
    socket.emit(LiveStreamActions.LeaveLiveStream, {
      sessionId: id,
    });
  };

  const joinSessionAndGetStream = async (id) => {
    if (id) {
      getStream(id);
    }

    // If user is streamer join socket session here
    if (isStreamer) joinSocketSession(id);
  };

  useEffect(() => {
    setStreamStatus(stream.status);
  }, [stream]);

  const changeStatusToLive = (data) => {
    setStreamStatus("live");
    setLiveData(data);
  };

  useEffect(() => {
    const id = new URLSearchParams(location.search).get("id");

    userWatchSessionId = Date.now().toString();
    joinSessionAndGetStream(id);

    return () => {
      leaveSocketSession(id);
      userWatchSessionId = null;
    };
  }, []);

  // function initializeTCPPlayer(fileId) {
  //   if (playerReady) return;

  //   // eslint-disable-next-line
  //   const player = TCPlayer("player-container-id", {
  //     // The ID of the player container, which must be the same as that in the HTML
  //     fileID: fileId, // Pass in the ID of the file to play (required)
  //     appID: "1308820136", // Pass in the AppID of the VOD subapplication (required)
  //     psign: "", // Pass in the player signature (required)
  //     autoplay: true, // Whether to enable auto-play
  //     // For other parameters, view them in VOD documentation
  //     // width: "1000",
  //     // height: "400",
  //     language: "en",
  //     bigPlayButton: false,
  //     loop: true,
  //     muted: false,
  //     reportable: false,
  //     playbackRates: [],
  //     controlBar: {
  //       progressControl: false,
  //       currentTimeDisplay: false,
  //       durationDisplay: false,
  //       timeDivider: false,
  //       QualitySwitcherMenuButton: false,
  //     },
  //   });
  //   if (!player) return;
  //   player.ready(() => {
  //     setPlayerReady(true);
  //   });
  //   tencentPlayer = player;
  // }

  // React.useEffect(() => {
  // window.loadBanner();
  // if (isMobile || window.innerWidth < 650) {
  //   window.loadBanner();
  // } else {
  // }
  // if (Capacitor.isNativePlatform()) {
  //   window.loadBanner();
  // } else {
  // }
  // }, []);

  // React.useEffect(() => {
  //   if (stream?.status === "recorded") {
  //     initializeTCPPlayer(stream.playbackUrl);
  //   }
  // }, [stream]);

  // const tencentStream = useMemo(
  //   () => (
  //     <video
  //       id="player-container-id"
  //       preload="auto"
  //       className={classes.tencentPlayer}
  //     />
  //   ),
  //   [stream]
  // );

  const desktopView = (
    <div>
      <div style={{ display: "flex" }}>
        <main className={classes.container}>
          <section className={classes.videoContainer}>
            <div
              style={{ width: "100%" }}
              className="videoPlayer stream-desktop-container"
            >
              {stream?.status == "live" || stream?.status === "recorded" ? (
                stream?.playbackUrl ? (
                  <VideoPlayer
                    width="100%"
                    source={stream?.playbackUrl}
                    height="100%"
                    runAds={true}
                  />
                ) : (
                  <p> Can't get playback url of live stream</p>
                )
              ) : stream?.status == "upcoming" ? (
                stream?.waitingImage ? (
                  <img
                    src={stream.waitingImage}
                    style={{ width: "100%", height: "100%" }}
                  />
                ) : (
                  <p> Can't get cover image of upcoming stream. </p>
                )
              ) : stream?.status == "done" ? (
                stream?.playbackUrl ? (
                  <VideoPlayer
                    width="100%"
                    source={stream?.playbackUrl}
                    height="100%"
                  />
                ) : (
                  <p> Cant get playback url of ended stream.</p>
                )
              ) : stream?.status === "recorded" ||
                stream?.status === "paused" ? null : (
                <SkeletonLoader />
              )}
              {isStreamer && stream?.status === "paused" ? (
                <p>
                  The streaming is disconnected. Please check your OBS to make
                  sure your streaming connection is good.
                </p>
              ) : null}
            </div>
          </section>
        </main>

        <Box className="streamer-box stream-desktop-container">
          <DesktopLiveChat comment={comment} commentsArr={commentsArr} />
        </Box>
      </div>

      <div style={{ marginTop: "20px" }}>
        <div style={{ width: "99%", height: "0%", overflow: "hidden" }}>
          <div id="smt-134833523" style={{ padding: "0px" }}></div>
        </div>
        {isStreamer ? (
          <section className={classes.metadata}>
            <div className={classes.metdataChild}>
              <div>
                {streamStatus == "upcoming" && (
                  <GoLive
                    callBack={changeStatusToLive}
                    streamId={stream?._id}
                    streamerId={streamer?.profile?._id}
                  />
                )}
              </div>
            </div>

            {streamStatus == "live" && liveData && (
              <LinkSharing
                streamerId={streamer?.profile?._id}
                liveDetails={liveData}
                stream={stream}
                getStream={getStream}
              />
            )}
          </section>
        ) : null}
        {/* {userWatchSessionTimes && (
              <UserPgemCounter
                {...userWatchSessionTimes}
                joinSocketSession={joinSocketSession}
                id={stream_id_location}
                userWatchSessionId={userWatchSessionId}
                socket={socket}
                userId={user._id}
              />
            )} */}
        {!isStreamer && (
          <section
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <div style={{ display: "flex", gap: "10px" }}>
              <ProfileDetails
                data={streamer?.profile}
                customButton={<FollowUnfollow userId={streamer?.profile._id} />}
              />
              <ShareDialog url={window.location} />
            </div>
            <WatchToEarnAnimation
              joinSocketSession={joinSocketSession}
              id={stream_id_location}
              userWatchSessionId={userWatchSessionId}
              socket={socket}
              userId={user._id}
              role={user.role}
            />
          </section>
        )}
      </div>
    </div>
  );

  const mobileView = (
    <main className={classes.container}>
      <section className={classes.videoContainer} style={{ flex: "0 0" }}>
        <div
          style={{ width: "100%", height: "400px" }}
          className="videoPlayer stream-desktop-container"
        >
          {stream?.status == "live" || stream?.status === "recorded" ? (
            stream?.playbackUrl ? (
              <VideoPlayer
                width="100%"
                source={stream?.playbackUrl}
                height="100%"
                runAds={true}
              />
            ) : (
              <p> Can't get playback url of live stream</p>
            )
          ) : stream?.status == "upcoming" ? (
            stream?.waitingImage ? (
              <img
                src={stream.waitingImage}
                style={{ width: "100%", height: "100%" }}
              />
            ) : (
              <p> Can't get cover image of upcoming stream. </p>
            )
          ) : stream?.status == "done" ? (
            stream?.playbackUrl ? (
              <VideoPlayer
                width="100%"
                source={stream?.playbackUrl}
                height="100%"
              />
            ) : (
              <p> Cant get playback url of ended stream.</p>
            )
          ) : stream?.status === "recorded" ||
            stream?.status === "paused" ? null : (
            <SkeletonLoader />
          )}
          {isStreamer && stream?.status === "paused" ? (
            <p>
              The streaming is disconnected. Please check your OBS to make sure
              your streaming connection is good.
            </p>
          ) : null}
        </div>
      </section>
      {isStreamer ? (
        <section className={classes.metadata}>
          <div className={classes.metdataChild}>
            <div>
              {streamStatus == "upcoming" && (
                <GoLive
                  callBack={changeStatusToLive}
                  streamId={stream?._id}
                  streamerId={streamer?.profile?._id}
                />
              )}
            </div>
          </div>

          {streamStatus == "live" && liveData && (
            <LinkSharing
              streamerId={streamer?.profile?._id}
              liveDetails={liveData}
              stream={stream}
              getStream={getStream}
            />
          )}
        </section>
      ) : null}

      {!isStreamer && (
        <>
          <section
            style={{
              display: "flex",
              marginTop: "20px",
              justifyContent: "space-between",
              alignItems: "center",
              flex: "0 0",
            }}
          >
            <div style={{ display: "flex", gap: "10px" }}>
              <ProfileDetails
                data={streamer?.profile}
                customButton={<FollowUnfollow userId={streamer?.profile._id} />}
              />
              <ShareDialog url={window.location} iconFontSize={20} />
            </div>
            <WatchToEarnAnimation
              joinSocketSession={joinSocketSession}
              id={stream_id_location}
              userWatchSessionId={userWatchSessionId}
              socket={socket}
              userId={user._id}
              role={user.role}
            />
          </section>
        </>
      )}
      <MobileLiveChat
        user={user}
        comment={comment}
        commentsArr={commentsArr}
        auth={true}
      />
    </main>
  );

  return (
    <DashboardLayout
      showSidebar={false}
      noRightMargin={true}
      noPaddingBottom={width <= 650}
    >
      {width > 650 && !Capacitor.isNativePlatform() ? desktopView : mobileView}
    </DashboardLayout>
  );
};

export default StreamerStreaming;
