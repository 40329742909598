import React, { useEffect, useState } from "react";
import StreamerCard from "../../components/cards/StreamerCard";
import SeeAllGamesAndStreams from "../../wrappers/seeAllGamesAndStreams";
import classes from "./nftSeeAll.module.css";

// import {OBJModel} from 'react-3d-viewer'

import UserContext from "../../context/UserContext";

const NftSeeAll = () => {
  const [nfts, setNfts] = useState([]);

  useEffect(() => {
    const cards = [
      {
        id: 1,
        imgPath: require("../../images/nftCard1.png"),
        title: "My Streamer",
        desc: "MekaVerse",
        comment: 4410,
        mekaNo: 3139,
        status: "current Price",
      },
      {
        id: 2,
        imgPath: require("../../images/nftCard2.png"),
        title: "My Gamer",
        desc: "MekaVerse",
        comment: 4410,
        mekaNo: 3139,
        status: "current Bid",
      },
      {
        id: 3,
        imgPath: require("../../images/nftCard2.png"),
        title: "My Gamer",
        desc: "MekaVerse",
        comment: 4410,
        mekaNo: 3139,
        status: "current Bid",
      },
      {
        id: 4,
        imgPath: require("../../images/nftCard1.png"),
        title: "My Streamer",
        desc: "MekaVerse",
        comment: 4410,
        mekaNo: 3139,
        status: "current Price",
      },
      {
        id: 4,
        imgPath: require("../../images/nftCard1.png"),
        title: "My Streamer",
        desc: "MekaVerse",
        comment: 4410,
        mekaNo: 3139,
        status: "current Price",
      },
      {
        id: 4,
        imgPath: require("../../images/nftCard1.png"),
        title: "My Streamer",
        desc: "MekaVerse",
        comment: 4410,
        mekaNo: 3139,
        status: "current Price",
      },
    ];

    setNfts(cards);
  }, []);
  const { servApiUrl, setMessage } = React.useContext(UserContext);

  const [myNfts, setMyNfts] = React.useState([]);

  const getMyNfts = async () => {
    let requestOptions = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + localStorage.getItem("pg-auth-token"),
      },
      body: JSON.stringify({}),
    };
    await fetch(servApiUrl + "/user/getMyNfts", requestOptions)
      .then((response) => response.json())
      .then((data) => {
        console.log(data);
        if (!data.success) {
          setMessage({ message: data.message, type: "error" });
        } else {
          setMyNfts(data.myNfts);
        }
      });
  };
  useEffect(() => {
    getMyNfts();
  }, []);

  return (
    <SeeAllGamesAndStreams title="Find NFTs you want to buy?">
      {(search) => (
        <main className={classes.container}>
          {myNfts?.map((nft, index) => {
            if (!nft?.name?.toLowerCase().includes(search.toLowerCase()))
              return null;

            return (
              <StreamerCard data={nft} key={`streamer-nft-card-${index}`} />
            );
          })}
        </main>
      )}
    </SeeAllGamesAndStreams>
  );
};

export default NftSeeAll;
