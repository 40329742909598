import React, { useRef, useState } from "react";
import Dialog from "../../wrappers/Dialog";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormControl from "@mui/material/FormControl";
import FormLabel from "@mui/material/FormLabel";
import { PAGES } from "../../data/pages";
import { formatNumberToEnglish } from "../../functions/numberFormatter";

function BuySellPgemModal({
  source,
  confirmOrder,
  price,
  priceUnit = "IDR",
  checkAuthAndRedirect,
}) {
  const [type, setType] = useState("buy");

  const [qty, setQty] = useState("");
  const [error, setError] = useState("");

  return (
    <Dialog
      actions={{
        successBtnProps: {
          title: type == "buy" ? "Confirm Buy" : "Confirm Sell",
        },
      }}
      title="Confirm Swap"
      handler={{
        cancel: (setOpen) => {
          setOpen(false);
        },
        success: (setOpen) => {
          const qtyNum = parseInt(qty);
          if (qtyNum <= 0 || qtyNum > 1000000 || qty.length == 0) {
            setError("Quantity must be greater than 0 and less than 1 million");
            return;
          }

          const idrPrice = price * qty;
          const IDR_PRICE_MIN_THRESH_HOLD = 20000;

          if (idrPrice < IDR_PRICE_MIN_THRESH_HOLD) {
            setError(
              `IDR price must at least be ${formatNumberToEnglish(
                IDR_PRICE_MIN_THRESH_HOLD
              )} IDR`
            );
            return;
          }

          setOpen(false);
          confirmOrder(qtyNum, idrPrice.toFixed(2), type);
        },
      }}
      Source={({ setOpen }) => (
        <div
          onClick={() => {
            if (!checkAuthAndRedirect({ redirectTo: PAGES.EXCHANGE })) return;
            setOpen(true);
          }}
        >
          {source}
        </div>
      )}
    >
      <div
        id="share_main_container"
        style={{ display: "flex", flexDirection: "column" }}
      >
        <div
          id="swap_info_box"
          style={{
            padding: "10px",
            display: "flex",
            width: "100%",
          }}
        >
          <FormControl>
            <FormLabel sx={{ margin: 0 }}>Type</FormLabel>
            <RadioGroup
              row
              aria-labelledby="demo-row-radio-buttons-group-label"
              name="row-radio-buttons-group"
            >
              <FormControlLabel
                value="buy"
                control={<Radio />}
                checked={type === "buy"}
                onClick={(e) => setType(e.target.value)}
                label="Buy"
              />
              <FormControlLabel
                value="sell"
                control={<Radio />}
                checked={type === "sell"}
                onClick={(e) => setType(e.target.value)}
                label="Sell"
              />
            </RadioGroup>
          </FormControl>
        </div>
        <div
          style={{
            width: "100%",
            height: "1px",
            backgroundColor: "var(--primary-color)",
            marginBottom: "10px",
          }}
        />
        <div
          id="swap_details_box"
          style={{
            display: "flex",
            flexDirection: "column",
            gap: "17px",
            width: "100%",
            margin: "5px 0px",
          }}
        >
          <div>
            <p>Quantity</p>
            <input
              type="number"
              inputMode="numeric"
              value={qty}
              onChange={(e) => {
                setError("");
                setQty(e.target.value);
              }}
              placeholder="Enter number of tokens"
              style={{ marginTop: "5px" }}
            />
          </div>
          {error.length > 0 ? (
            <p style={{ marginLeft: "5px" }} className="form-error">
              {error}
            </p>
          ) : null}
          <p style={{ marginLeft: "5px" }}>
            <strong>
              {(price * qty).toFixed(2)} {priceUnit}
            </strong>
          </p>
        </div>
      </div>
    </Dialog>
  );
}

export default BuySellPgemModal;
