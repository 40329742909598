import MuiAlert from "@mui/material/Alert";
import Snackbar from "@mui/material/Snackbar";
import * as React from "react";
import UserContext from "../../context/UserContext";

const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

const CustomizedSnackbars = () => {
  const { message, setMessage } = React.useContext(UserContext);

  const handleClose = (e, reason) => {
    if (reason !== "clickaway") setMessage(null);
  };

  return (
    message && (
      <Snackbar open autoHideDuration={4000} onClose={handleClose}>
        <Alert
          onClose={handleClose}
          severity={message?.type || "info"}
          sx={{ width: "100%" }}
        >
          {message?.message}
        </Alert>
      </Snackbar>
    )
  );
};

export default React.memo(CustomizedSnackbars);
