import React, { useState, useMemo } from "react";

import moment from "moment";

import AccessTimeOutlinedIcon from "@mui/icons-material/AccessTimeOutlined";
import SentimentVerySatisfiedOutlinedIcon from "@mui/icons-material/SentimentVerySatisfiedOutlined";
import MoodBadOutlinedIcon from "@mui/icons-material/MoodBadOutlined";
import SentimentNeutralOutlinedIcon from "@mui/icons-material/SentimentNeutralOutlined";
import DoneIcon from "@mui/icons-material/Done";
import CloseIcon from "@mui/icons-material/Close";

import useGetOpinions from "../../../../hooks/api/useGetOpinions";
import SkeletonLoader from "../../../../components/skeletonLoader/skeletonLoader";
import { getAuthToken } from "../../../../functions/auth";
import { OPINION_TYPES } from "./opinions.data";

import "./opinions.css";

function Opinions({ servApiUrl, user }) {
  const [opinions, setOpinions] = useState([]);
  const [showFeedbackMsg, setShowFeedbackMsg] = useState(false);
  const [showErrorMsg, setShowErrorMsg] = useState(false);
  const { loading } = useGetOpinions(servApiUrl, user._id, setOpinions);

  const updateOpinionsState = (opinionType, opinionId, userId) => {
    const opinionsClone = [...opinions];

    for (let i = 0; i < opinionsClone.length; i++) {
      const opinion = opinionsClone[i];

      if (opinion._id == opinionId) {
        if (opinionType === OPINION_TYPES.LIKE) {
          opinion.likes += 1;
        } else if (opinionType === OPINION_TYPES.DISLIKE) {
          opinion.dislikes += 1;
        } else {
          opinion.neutral += 1;
        }
        if (!opinion.alreadyVoted) {
          opinion.alreadyVoted = {};
        }
        opinion.alreadyVoted[userId] = opinionType;
        break;
      }
    }

    setOpinions(opinionsClone);

    setTimeout(() => {
      setShowFeedbackMsg(false);
    }, 2000);
  };

  const findOpinion = (opinionId) => {
    return opinions.filter((opinion) => opinion._id == opinionId)[0];
  };

  const recordOpinion = async (opinion, opinionId, userId) => {
    const opinionObj = findOpinion(opinionId);

    if (opinionObj?.alreadyVoted && opinionObj.alreadyVoted[userId] == true) {
      setShowErrorMsg(true);

      setTimeout(() => {
        setShowErrorMsg(false);
      }, 2000);
      return;
    }

    const res = await fetch(`${servApiUrl}user/record-opinion`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: getAuthToken(),
      },
      body: JSON.stringify({ userId, opinion, opinionId }),
    });
    const response = await res.json();

    if (response.success === true) {
      setShowFeedbackMsg(true);
      updateOpinionsState(opinion, opinionId, userId);
    }
  };

  const getUserOpinion = (opinion) => opinion.alreadyVoted?.[user._id];

  const Opinion = ({ item }) => {
    const userOpinion = getUserOpinion(item);

    return (
      <div className="announcement-item">
        <div>
          <h3>{item.title}</h3>
          <div>
            <AccessTimeOutlinedIcon
              sx={{ color: "var(--text-color)", fontSize: "20px" }}
            />
            <p>
              {moment(item?.createdAt ?? new Date()).format(
                "MMM D, YYYY [at] h:mm A z"
              )}
            </p>
          </div>
        </div>
        <p>{item.text}</p>
        <div className="opinions-emojis-container">
          <div
            onClick={() =>
              recordOpinion(OPINION_TYPES.LIKE, item._id, user._id)
            }
          >
            {userOpinion === OPINION_TYPES.LIKE ? (
              <>🙂</>
            ) : (
              <SentimentVerySatisfiedOutlinedIcon
                sx={{ color: "var(--text-color)" }}
              />
            )}
            <p>{item.likes}</p>
          </div>
          <div
            onClick={() =>
              recordOpinion(OPINION_TYPES.DISLIKE, item._id, user._id)
            }
          >
            {userOpinion === OPINION_TYPES.DISLIKE ? (
              <>😦</>
            ) : (
              <MoodBadOutlinedIcon sx={{ color: "var(--text-color)" }} />
            )}
            <p>{item.dislikes}</p>
          </div>
          <div
            onClick={() =>
              recordOpinion(OPINION_TYPES.NEUTRAL, item._id, user._id)
            }
          >
            {userOpinion === OPINION_TYPES.NEUTRAL ? (
              <>😐</>
            ) : (
              <SentimentNeutralOutlinedIcon
                sx={{ color: "var(--text-color)" }}
              />
            )}
            <p>{item.neutral}</p>
          </div>
        </div>
      </div>
    );
  };

  const opinionsSection = useMemo(() => {
    if (!opinions) return;

    return (
      <div className="announcements-container">
        {showFeedbackMsg ? (
          <div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
            <DoneIcon sx={{ color: "green" }} />
            <p>Your opinion has been recorded</p>
          </div>
        ) : null}
        {showErrorMsg ? (
          <div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
            <CloseIcon sx={{ color: "red" }} />
            <p>Your opinion has already been recorded</p>
          </div>
        ) : null}
        {opinions.length === 0 ? (
          <h3 style={{ textAlign: "center" }}>
            Nothing new here. You can always submit your feedback.
          </h3>
        ) : (
          opinions.map((opinion, index) => (
            <Opinion key={`announcement-item-${index}`} item={opinion} />
          ))
        )}
      </div>
    );
  }, [loading, opinions, showFeedbackMsg, showErrorMsg]);

  const LoadingSkeletons = () => (
    <div className="announcements-loading-skeletons">
      <SkeletonLoader height="100px" />
      <SkeletonLoader height="100px" />
      <SkeletonLoader height="100px" />
      <SkeletonLoader height="100px" />
    </div>
  );

  return (
    <div style={{ width: "100%" }}>
      {loading ? <LoadingSkeletons /> : opinionsSection}
    </div>
  );
}

export default Opinions;
