import { useEffect, useState } from "react";
import { getAuthToken } from "../../functions/auth";

const useGetSocialMediaItemComments = (baseUrl, postId) => {
  const ITEMS_PER_PAGE = 5;
  const [data, setData] = useState([]);
  const [hideLoadMoreButton, setHideLoadMoreButton] = useState(false);

  const [loading, setLoading] = useState(false);
  const [currPage, setCurrPage] = useState(-1);
  const [pageCount, setPageCount] = useState(null);

  const getSocialMediaItems = async () => {
    if (pageCount && currPage > pageCount) return;
    setLoading(true);

    const GET_SOCIAL_MEDIA_ITEM_COMMENTS =
      "user/get-social-media-item-comments";

    const payload = {
      currPage: currPage,
      itemsPerPage: ITEMS_PER_PAGE,
      id: postId,
    };

    const res = await fetch(`${baseUrl}${GET_SOCIAL_MEDIA_ITEM_COMMENTS}`, {
      method: "POST",
      body: JSON.stringify(payload),
      headers: {
        "Content-Type": "application/json",
        Authorization: getAuthToken(),
      },
    });
    const response = await res.json();
    if (response.success) {
      setData([...data, ...response.data]);
      setPageCount(response.pageCount);
    } else {
      setHideLoadMoreButton(true);
    }

    setLoading(false);
  };

  const increasePageAndGetItems = () => {
    setCurrPage((prev) => prev + 1);
  };

  useEffect(() => {
    if (currPage == -1) return;
    getSocialMediaItems();
  }, [currPage]);

  useEffect(() => {
    if (pageCount && currPage == pageCount - 1) setHideLoadMoreButton(true);
  }, [currPage, pageCount]);

  return {
    loading,
    data,
    setData,
    hideLoadMoreButton,
    increasePageAndGetItems,
  };
};

export default useGetSocialMediaItemComments;
