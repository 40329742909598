import React, { useMemo, useState } from "react";
import SkeletonLoader from "../../../../components/skeletonLoader/skeletonLoader";
import AccessTimeOutlinedIcon from "@mui/icons-material/AccessTimeOutlined";

import useGetAnnouncements from "../../../../hooks/api/useGetAnnouncements";
import moment from "moment";

import "./announcements.css";

function Annoucements({ servApiUrl }) {
  const [announcements, setAnnouncements] = useState([]);
  const { loading } = useGetAnnouncements(servApiUrl, setAnnouncements);

  const Announcement = ({ item }) => (
    <div className="announcement-item">
      <div>
        <h3>{item.title}</h3>
        <div>
          <AccessTimeOutlinedIcon
            sx={{ color: "var(--text-color)", fontSize: "20px" }}
          />
          <p>
            {moment(item?.createdAt ?? new Date()).format(
              "MMM D, YYYY [at] h:mm A z"
            )}
          </p>
        </div>
      </div>
      <p>{item.text}</p>
    </div>
  );

  const announcementsSection = useMemo(() => {
    if (!announcements) return;

    return (
      <div className="announcements-container">
        {announcements.length === 0 ? (
          <h3 style={{ textAlign: "center" }}>No new announcements!</h3>
        ) : (
          announcements.map((announcement, index) => (
            <Announcement
              key={`announcement-item-${index}`}
              item={announcement}
            />
          ))
        )}
      </div>
    );
  }, [loading, announcements]);

  const LoadingSkeletons = () => (
    <div className="announcements-loading-skeletons">
      <SkeletonLoader height="100px" />
      <SkeletonLoader height="100px" />
      <SkeletonLoader height="100px" />
      <SkeletonLoader height="100px" />
    </div>
  );

  return (
    <div style={{ width: "100%" }}>
      {loading ? <LoadingSkeletons /> : announcementsSection}
    </div>
  );
}

export default Annoucements;
