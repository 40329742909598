import React, { useContext } from "react";
import UserContext from "../../context/UserContext";
import Dialog from "../../wrappers/Dialog";

const LogoutConfirmDialog = ({
  Source,
  title = "Are you sure you want to Logout",
  confirmText = "Get me out",
  onConfirm = null,
}) => {
  const { logout } = useContext(UserContext);

  return (
    <>
      <Dialog
        actions={{
          rootProps: {
            style: {
              borderTop: "none",
              marginBottom: "40px",
              padding: "20px 10%",
            },
          },
          successBtnProps: {
            title: confirmText,
          },
        }}
        header={false}
        handler={{
          cancel: (setOpen) => {
            setOpen(false);
          },
          success: (setOpen) => {
            onConfirm ? onConfirm() : logout();
            setOpen(false);
          },
        }}
        Source={({ setOpen }) => <Source setOpen={setOpen} />}
      >
        <div
          style={{
            padding: "0px 15%",
            width: "100%",
            boxSizing: "border-box",
            display: "flex",
            justifyContent: "center",
            color: "white",
            marginTop: "40px",
          }}
        >
          <p
            style={{
              fontFamily: "Poppins",
              fontSize: "23px",
              fontStyle: "normal",
              fontWeight: 500,
              letterSpacing: "0em",
              margin: 0,
              width: "100%",
              textAlign: "center",
              color: "var(--text-color)",
            }}
          >
            {title}
          </p>
        </div>
      </Dialog>
    </>
  );
};

export default LogoutConfirmDialog;
