import { lazy } from "react";
import { PAGES } from "../../data/pages";

const AddSchedule = lazy(() =>
  import("../../components/AddSchedule/AddSchedule")
);
const MySchedules = lazy(() => import("../../pages/schedules/MySchedules"));
// const Statistics = lazy(() => import("../../pages/statistics/Statistics"));
const AddSocialPost = lazy(() =>
  import("../../pages/addSocialPost/addSocialPost")
);

export const STREAMER_ROUTES = [
  // {
  //   path: PAGES.STATISTICS,
  //   element: <Statistics />,
  //   lazy: true,
  // },
  {
    path: PAGES.ADD_SCHEDULE,
    element: <AddSchedule />,
    lazy: true,
  },
  {
    path: PAGES.MY_SCHEDULES,
    element: <MySchedules />,
    lazy: true,
  },
  {
    path: PAGES.ADD_SOCIAL_POST,
    element: <AddSocialPost />,
    lazy: true,
  },
];
