import homeIcon from "../../images/icons/home.svg";
import nftIcon from "../../images/icons/nft.svg";
import exchangeIcon from "../../images/icons/Exchange.svg";
import polycoinIcon from "../../images/icons/Polycoin.svg";
import ChatIcon from "@mui/icons-material/Chat";
import PeopleIcon from "@mui/icons-material/People";
// import stakingIcon from "../../images/icons/staking_icon.svg";
import chatIcon from "../../images/icons/Chaticon.svg";
import leaderboardIcon from "../../images/icons/Leaderboard.svg";
import settingIcon from "../../images/icons/Settings.svg";
import { PAGES } from "../pages";
import Person4Icon from "@mui/icons-material/Person4";

const optionStyles = {
  borderRadius: "50%",
  height: "32px",
  width: "32px",
  margin: "0px",
  backgroundColor: "white",
};

const mobileOptionStyles = { borderRadius: "50%", height: "32px" };

export const AUTH_SIDEBAR_ROUTES = [
  {
    name: "PolyStream",
    link: PAGES.HOME,
    for: ["all"],
    image: (
      <img
        style={{
          ...optionStyles,
        }}
        src={homeIcon}
        alt="home icon"
        className="homeIcon icon-1"
      />
    ),
  },
  {
    name: "My Schedules",
    link: "/my-schedule",
    for: ["streamer"],
    image: (
      <img
        style={{
          ...optionStyles,
        }}
        src={nftIcon}
        alt="NFT icon"
        className="homeIcon icon-2"
      />
    ),
  },
  // {
  //   name: "PolyNFT",
  //   link: "/nft-marketplace",
  //   for: ["all"],
  //   image: (
  //     <img
  //       style={{
  //         ...optionStyles,
  //       }}
  //       src={nftIcon}
  //       alt="NFT icon"
  //       className="homeIcon icon-2"
  //     />
  //   ),
  // },
  {
    name: "PolyX",
    link: "/exchange",
    for: ["all"],
    image: (
      <img
        style={{
          ...optionStyles,
        }}
        src={exchangeIcon}
        alt="profile icon"
        className="homeIcon icon-3"
      />
    ),
  },
  {
    name: "PolyWallet",
    link: "/polycoins",
    for: ["all"],
    image: (
      <img
        style={{
          ...optionStyles,
        }}
        src={polycoinIcon}
        alt="profile icon"
        className="homeIcon icon-4"
      />
    ),
  },
  {
    name: "PolyChat",
    link: "/chats",
    for: ["all"],
    image: (
      <ChatIcon
        sx={{
          color: "#666687",
          fontSize: "28px",
        }}
      />
    ),
  },
  {
    name: "PolyCommunity",
    link: "/poly-community",
    for: ["all"],
    image: (
      <PeopleIcon
        sx={{
          color: "#666687",
          fontSize: "28px",
        }}
      />
    ),
  },
  {
    name: "PolyVerse",
    link: PAGES.POLY_VERSE,
    for: ["all"],
    image: (
      <Person4Icon
        sx={{
          color: "#666687",
          fontSize: "28px",
        }}
      />
    ),
  },
  // {
  //   name: "Staking",
  //   link: "/staking",
  //   for: ["all"],
  //   image: (
  //     <img
  //       style={{
  //         ...optionStyles,
  //       }}
  //       src={stakingIcon}
  //       alt="profile icon"
  //       className="homeIcon icon-4"
  //     />
  //   ),
  // },
  {
    name: "Be a streamer",
    link: "/submit-request",
    for: ["follower", "user"],
    image: (
      <img
        style={{
          ...optionStyles,
        }}
        src={chatIcon}
        alt="profile icon"
        className="homeIcon icon-4"
      />
    ),
  },
  {
    name: "LeaderBoard",
    link: "/leaderboard",
    for: ["all"],
    image: (
      <img
        style={{
          ...optionStyles,
        }}
        src={leaderboardIcon}
        alt="profile icon"
        className="homeIcon icon-4"
      />
    ),
  },
  {
    name: "Settings",
    link: "/settings",
    for: ["all"],
    image: (
      <img
        style={{
          ...optionStyles,
        }}
        src={settingIcon}
        alt="profile icon"
        className="homeIcon icon-4"
      />
    ),
  },
];

export const MOBILE_AUTH_SIDEBAR_ROUTES = [
  {
    name: "PolyStream",
    link: PAGES.HOME,
    for: ["all"],
    image: (
      <img
        style={{
          ...mobileOptionStyles,
        }}
        src={homeIcon}
        alt="home icon"
        className="homeIcon icon-1"
      />
    ),
  },
  {
    name: "My Schedules",
    link: "/my-schedule",
    for: ["streamer"],
    image: (
      <img
        style={{
          ...mobileOptionStyles,
        }}
        src={nftIcon}
        alt="NFT icon"
        className="homeIcon icon-2"
      />
    ),
  },
  // {
  //   name: "PolyNFT",
  //   link: "/nft-marketplace",
  //   for: ["all"],
  //   image: (
  //     <img
  //       style={{
  //         ...mobileOptionStyles,
  //       }}
  //       src={nftIcon}
  //       alt="NFT icon"
  //       className="homeIcon icon-2"
  //     />
  //   ),
  // },
  {
    name: "PolyX",
    link: "/exchange",
    for: ["all"],
    image: (
      <img
        style={{
          ...mobileOptionStyles,
        }}
        src={exchangeIcon}
        alt="profile icon"
        className="homeIcon icon-3"
      />
    ),
  },
  {
    name: "PolyWallet",
    link: "/polycoins",
    for: ["all"],
    image: (
      <img
        style={{
          ...mobileOptionStyles,
        }}
        src={polycoinIcon}
        alt="profile icon"
        className="homeIcon icon-4"
      />
    ),
  },
  {
    name: "PolyChat",
    link: "/chats",
    for: ["all"],
    image: (
      <ChatIcon
        sx={{
          color: "#666687",
          fontSize: "32px",
        }}
        className="homeIcon icon-4"
      />
    ),
  },
  {
    name: "PolyCommunity",
    link: "/poly-community",
    for: ["all"],
    image: (
      <PeopleIcon
        sx={{
          color: "#666687",
          fontSize: "32px",
        }}
        className="homeIcon icon-4"
      />
    ),
  },
  // {
  //   name: "Staking",
  //   link: "/staking",
  //   for: ["all"],
  //   image: (
  //     <img
  //       style={{
  //         ...mobileOptionStyles,
  //       }}
  //       src={stakingIcon}
  //       alt="profile icon"
  //       className="homeIcon icon-4"
  //     />
  //   ),
  // },
  {
    name: "Be a streamer",
    link: "/submit-request",
    for: ["follower", "user"],
    image: (
      <img
        style={{
          ...mobileOptionStyles,
        }}
        src={chatIcon}
        alt="profile icon"
        className="homeIcon icon-4"
      />
    ),
  },
  {
    name: "LeaderBoard",
    link: "/leaderboard",
    for: ["all"],
    image: (
      <img
        style={{
          ...mobileOptionStyles,
        }}
        src={leaderboardIcon}
        alt="profile icon"
        className="homeIcon icon-4"
      />
    ),
  },
  {
    name: "Settings",
    link: "/settings",
    for: ["all"],
    image: (
      <img
        style={{
          ...mobileOptionStyles,
        }}
        src={settingIcon}
        alt="profile icon"
        className="homeIcon icon-4"
      />
    ),
  },
];
