export const CHAT_GROUPS = [
  "General",
  "Pubg",
  "Free fire",
  "Valorant",
  "Dota 2",
  "Stumble Guys",
  "GTA V",
  "Mobile Legends",
  "Genshin Impact",
  "Apex Legends",
  "Albion",
];

export const GROUP_IMAGES = [
  {
    name: "General",
    image: "https://i.ibb.co/HgPCwrz/general.jpg",
  },
  {
    name: "Pubg",
    image: "https://i.ibb.co/BTRK4v3/pubg.jpg",
  },
  {
    name: "Free fire",
    image: "https://i.ibb.co/N6gP9rr/free-fire.jpg",
  },
  {
    name: "Valorant",
    image: "https://i.ibb.co/fCyLHd9/Valorant.jpg",
  },
  {
    name: "Dota 2",
    image: "https://i.ibb.co/BnwCFHr/dota-2.jpg",
  },
  {
    name: "Stumble Guys",
    image: "https://i.ibb.co/vdykKFb/stumble.jpg",
  },
  {
    name: "GTA V",
    image:
      "https://i.ibb.co/ZfPNQMn/new-grand-theft-auto-v-grand-theft-auto-5-game-wallpaper-preview.jpg",
  },
  {
    name: "Mobile Legends",
    image: "https://i.ibb.co/YRLT57J/download.jpg",
  },
  {
    name: "Genshin Impact",
    image:
      "https://i.ibb.co/QCXjr6v/EGS-Genshin-Impact-mi-Ho-Yo-Limited-S1-2560x1440-91c6cd7312cc2647c3ebccca10f30399.jpg",
  },
  {
    name: "Apex Legends",
    image: "https://i.ibb.co/KLkrCww/download-1.jpg",
  },
  {
    name: "Albion",
    image: "https://i.ibb.co/9YcGChg/capsule-616x353.jpg",
  },
];

export const ChatRoomActions = {
  JoinChatRoom: "chatroom_join",
  LeaveChatRoom: "chatroom_leave",
  SendMessage: "chatroom_message",
  ReceiveMessage: "receive_chatroom_message",
};
