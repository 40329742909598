import React from "react";

import Button from "@mui/material/Button";

import { useNavigate } from "react-router-dom";
import ConfirmSwapDialog from "../../components/dialogs/ConfirmSwapDialog";
import HistoryIcon from "@mui/icons-material/History";
import ConfirmSellDialog from "../../components/dialogs/ConfirmSellDialog";

import history_icon from "../../images/icons/filters.svg";

import DashboardLayout from "../../wrappers/dashboard/index";
import ContentWrapper from "../../wrappers/default/components/content";
import classes from "./exchange.module.css";
import Autocomplete from "@mui/material/Autocomplete";
import { TextField } from "@mui/material";
import { Box } from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";

import { useUserContext } from "../../context/UserContext";
import BuySellPgemModal from "../../components/buySellPgemModal/buySellPgemModal";
import { getRequestOptions } from "../../functions/auth";

import useIsAuth from "../../hooks/auth/useIsAuth";
import useCreateXenditInvoice from "../../hooks/api/mutators/useCreateXenditInvoice";
import ExchangeChartComponent from "./components/exchangeChartComponent";
import useCreatePayoutLink from "../../hooks/api/mutators/useCreatePayoutLink";

export const Exchange = () => {
  const navigate = useNavigate();
  const { checkAuthAndRedirect } = useIsAuth();

  let [quoteCoin, setQuoteCoin] = React.useState("QQB");
  let [tokenAmount, setTokenAmount] = React.useState(0);
  const [chartActiveTab, setChartActiveTab] = React.useState("line");
  const [loadingMarketsApi, setLoadingMarketsApi] = React.useState(false);

  const { servApiUrl, pgemTradingPairInfo, isLoggedIn } = useUserContext();

  const { createXenditInvoice } = useCreateXenditInvoice();
  const { createPayoutLink } = useCreatePayoutLink();

  function getPercentageChange(numA, numB) {
    let result = ((numA - numB) / numB) * 100;

    if (result < 0) result = -result;

    return result;
  }

  const columns = [
    {
      field: "quote_id",
      headerName: "Name",
      flex: 1,
      editable: true,
      valueGetter: (params) => `${params.row.quote_id?.symbol}`,
      renderCell: (params) => (
        <p style={{ fontWeight: "bold", fontSize: "16px" }}>
          {params.formattedValue}
        </p>
      ),
      renderHeader: (params) => <h1>Name</h1>,
    },
    {
      field: "last_price",
      headerName: "Price (PGEM)",
      flex: 1,
      editable: true,
      renderCell: (params) => {
        const color =
          params?.row?.base_pool / params?.row?.quote_pool >
          params.formattedValue
            ? "#ff0000"
            : "#16C784";

        return (
          <p style={{ color, fontWeight: "bold", fontSize: "14px" }}>
            {params.formattedValue.toFixed(2)}
          </p>
        );
      },
      renderHeader: (params) =>
        window.innerWidth < 650 ? (
          <p style={{ fontSize: "14px" }}>
            Price <span style={{ fontSize: "10px" }}> (PGEM)</span>
          </p>
        ) : (
          <h1>Price (PGEM)</h1>
        ),
    },
    {
      field: "last_24h_price",
      headerName: "24h %",
      flex: 0.8,
      editable: true,
      renderCell: (params) => {
        const percentAgeDifference = getPercentageChange(
          params.row?.last_price,
          params.row?.last_24h_price
        );

        const color =
          params?.row?.last_price > params?.row?.last_24h_price
            ? "#16C784"
            : "#ff0000";

        return (
          <p style={{ color, fontWeight: "bold", fontSize: "14px" }}>
            {percentAgeDifference.toFixed(2)}%
          </p>
        );
      },
      renderHeader: (params) => <h1>24h %</h1>,
    },
  ];
  if (isLoggedIn) {
    const actionsColumn = {
      field: "",
      headerName: "Actions",
      flex: 1,
      editable: false,
      sortable: false,
      renderCell: (params) => {
        return (
          <div className={classes.BuySell_Button}>
            <div className={classes.Buy_now}>
              <Button
                onClick={() => {
                  setQuoteCoin(params.row?.quote_id?.symbol);
                  getMarket(params.row?.quote_id?.symbol);
                  window.scrollTo(0, window.innerWidth < 650 ? 200 : 500);
                }}
              >
                Swap
              </Button>
            </div>
          </div>
        );
      },
      renderHeader: (params) => (
        <h1 style={{ marginRight: window.innerWidth > 650 ? "25px" : "0px" }}>
          Actions
        </h1>
      ),
    };
    columns.push(actionsColumn);
  }

  const getBaseWallet = async () => {
    let requestOptions1 = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + localStorage.getItem("pg-auth-token"),
      },
      body: JSON.stringify({ symbol: "PGEM" }),
    };
    await fetch(`${servApiUrl}user/getWallet`, requestOptions1)
      .then((response) => response.json())
      .then((data) => {
        setUserBaseWallet(data);
      });
  };

  const getTokens = async () => {
    const requestOptions = getRequestOptions("GET", isLoggedIn);
    const MARKETS_URL = isLoggedIn
      ? `${servApiUrl}user/getMarkets`
      : `${servApiUrl}noAuth/getMarkets`;
    await fetch(MARKETS_URL, requestOptions)
      .then((response) => response.json())
      .then(async (data) => {
        let options = [];
        data.forEach((market) => {
          options.push({
            label: market.quote_id.name,
            id: market.quote_id.symbol,
            symbol: market.quote_id.symbol,
            avatar: market.quote_id.streamer_id.avatar,
            data: market,
          });
        });
        setTokenListOptions(options);
        setMarkets(data);

        if (isLoggedIn) {
          getMarket(data[0]?.quote_id.symbol);
          getBaseWallet();
          setMarket(data[0]);
          setQuoteCoin(data[0]?.quote_id.symbol);
        }
      });
  };

  const tokenPrice = async () => {
    if (loadingMarketsApi) return;
    setLoadingMarketsApi(true);
    const requestOptions = getRequestOptions("GET", isLoggedIn);
    const MARKETS_URL = isLoggedIn
      ? `${servApiUrl}user/getMarkets`
      : `${servApiUrl}noAuth/getMarkets`;
    await fetch(MARKETS_URL, requestOptions)
      .then((response) => response.json())
      .then(async (data) => {
        setMarkets(data);
      })
      .finally(() => {
        setLoadingMarketsApi(false);
      });
  };

  let [tokenListOptions, setTokenListOptions] = React.useState([]);
  let [market, setMarket] = React.useState([]);
  let [markets, setMarkets] = React.useState([]);
  let [userQuoteWallet, setUserQuoteWallet] = React.useState({});
  let [userBaseWallet, setUserBaseWallet] = React.useState({});

  const getMarket = async (symbol) => {
    if (!isLoggedIn) return;
    markets?.forEach((market) => {
      if (market.quote_id.symbol == symbol) {
        setMarket(market);
        getSwapMachine();
      }
    });
    if (symbol && symbol.length > 0) {
      let requestOptions = {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + localStorage.getItem("pg-auth-token"),
        },
        body: JSON.stringify({ symbol: symbol }),
      };
      await fetch(`${servApiUrl}user/getWallet`, requestOptions)
        .then((response) => response.json())
        .then((data) => {
          setUserQuoteWallet(data);
        });
    }
  };

  const confirmOrder = async (qty, idrPrice, type = "buy") => {
    if (type == "buy") {
      const result = await createXenditInvoice("PGEM Tokens", idrPrice, qty);

      if (result && result.success == true) {
        window.location.assign(result.data);
      }
    } else {
      await createPayoutLink(qty);
      getBaseWallet();
    }
  };

  const getSwapMachine = async (symbol) => {};

  React.useEffect(() => {
    getTokens();
    const getTradesInterval = setInterval(() => {
      tokenPrice();
    }, 5000);
    return function cleanup() {
      clearInterval(getTradesInterval);
    };
  }, [isLoggedIn]);

  const handleChartClick = (chartTab) => {
    setChartActiveTab(chartTab);
  };

  const chartTabs = (
    <div className={classes.chart_tabs_container}>
      <button
        onClick={() => handleChartClick("line")}
        className={`${classes.chart_tab} ${
          chartActiveTab === "line" ? `${classes.chart_tab_active}` : ""
        }`}
      >
        Line
      </button>
      <button
        onClick={() => handleChartClick("candle")}
        className={`${classes.chart_tab} ${
          chartActiveTab === "candle" ? `${classes.chart_tab_active}` : ""
        }`}
      >
        Candle
      </button>
    </div>
  );

  return (
    <DashboardLayout showSidebar={false} noRightMargin={true}>
      <ContentWrapper
        breadCrumbs={[
          {
            title: "Esports Fan token Exchange",
            image: <img src={history_icon} height="100%" />,
          },
        ]}
        sideIcon={
          <div onClick={() => navigate("/transation-history")}>
            <HistoryIcon
              sx={{
                fontSize: "40px",
                color: "var(--primary-color)",
                "&:hover": { cursor: "pointer" },
              }}
            />
          </div>
        }
      >
        <div className={classes.main_div}>
          {chartTabs}
          <div
            style={{ display: "flex", flexDirection: "column", width: "100%" }}
          >
            <ExchangeChartComponent
              chartActiveTab={chartActiveTab}
              symbol={quoteCoin}
            />

            <div className={classes.money_exchange}>
              <div className={classes.swap_form}>
                <p
                  style={{
                    color: "var(--primary-color)",
                    fontWeight: "600",
                    fontSize: "24px",
                    marginBottom: "15px",
                  }}
                >
                  {quoteCoin}/PGEM
                </p>
                <p>Select Token</p>
                <div className={classes.fantoken} style={{ color: "#fff" }}>
                  <Autocomplete
                    id="tokenSelector"
                    options={tokenListOptions}
                    color="#ffffff"
                    sx={{
                      color: "#fff",
                      backgroundColor: "var(--input)",
                      borderRadius: "5px",
                      width: "100%",
                    }}
                    renderOption={(props, option) => (
                      <Box
                        component="li"
                        sx={{ "& > img": { mr: 2, flexShrink: 0 } }}
                        {...props}
                      >
                        <img
                          loading="lazy"
                          width="20"
                          src={option.avatar}
                          srcSet={option.avatar}
                          alt=""
                        />
                        {option.label} ({option.symbol})
                      </Box>
                    )}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label=""
                        placeholder="Search Token"
                        defaultValue="TEST"
                      />
                    )}
                    noOptionsText={"No Token found..."}
                    value={quoteCoin}
                    onChange={(event, value) => {
                      setQuoteCoin(value.id);
                      getMarket(value.id);
                    }}
                  />
                </div>
                <p style={{ "margin-bottom": "16px", "font-size": ".8em" }}>
                  <span>
                    {" "}
                    {market?.quote_id?.symbol || "[Select Token]"} Balance:{" "}
                  </span>
                  <span>{userQuoteWallet?.balance?.toFixed(4) || "00.00"}</span>
                </p>
                <p>Amount Of Tokens</p>
                <div className={classes.fantoken}>
                  <input
                    onChange={(event) => setTokenAmount(event.target.value)}
                    placeholder="Enter amount of tokens"
                  ></input>
                </div>
                <p style={{ "margin-bottom": "16px", "font-size": ".8em" }}>
                  <span> PGEM Balance: </span>
                  <span>{userBaseWallet?.balance?.toFixed(4) || "00.00"}</span>
                </p>
              </div>
              <div className={classes.pgemconis}>
                {quoteCoin && (
                  <div className={classes.swap_now}>
                    <div className={classes.buttonBuy}>
                      <ConfirmSwapDialog
                        swapData={{
                          quote: quoteCoin,
                          amount: tokenAmount,
                          type: "buy",
                          market: market,
                        }}
                      />
                    </div>
                    <div className={classes.buttonSell}>
                      <ConfirmSellDialog
                        swapData={{
                          quote: quoteCoin,
                          amount: tokenAmount,
                          type: "sell",
                          market: market,
                        }}
                      />
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>
          <div className={classes.vline}></div>
          <div
            className={classes.expand_more}
            style={{ backgroundColor: "#13171d" }}
          >
            <div className={classes.drax_pgem}>
              <div className={classes.drax_percentage}>
                <h6>PGEM/USD</h6>
              </div>
              <div className={classes.twohundred}>
                {pgemTradingPairInfo ? (
                  <h5>{pgemTradingPairInfo?.usd_price?.toFixed(4)}</h5>
                ) : (
                  <h5>Loading...</h5>
                )}
              </div>
            </div>
          </div>
          <div
            className={classes.expand_more}
            style={{ backgroundColor: "#13171d" }}
          >
            <div className={classes.drax_pgem}>
              <div className={classes.drax_percentage}>
                <h6>PGEM/IDR</h6>
              </div>
              <BuySellPgemModal
                source={
                  <div className={classes.BuySell_Button}>
                    <div className={classes.Buy_now}>
                      <Button>Swap</Button>
                    </div>
                  </div>
                }
                confirmOrder={confirmOrder}
                price={pgemTradingPairInfo?.idr_price}
                checkAuthAndRedirect={checkAuthAndRedirect}
              />

              <div className={classes.twohundred}>
                {pgemTradingPairInfo ? (
                  <h5>
                    {parseFloat(pgemTradingPairInfo?.idr_price).toFixed(2)}
                  </h5>
                ) : (
                  <h5>Loading...</h5>
                )}
              </div>
            </div>
          </div>

          <DataGrid
            style={{
              color: "var(--text-color)",
              borderRight: 0,
              borderLeft: 0,
              marginTop: "50px",
              width: "100%",
            }}
            rows={markets}
            columns={columns}
            slots={{}}
            initialState={{}}
            disableRowSelectionOnClick
            isCellEditable={() => false}
            rowSelection={false}
            hideFooter={true}
            autoHeight
            getRowId={(row) => row._id}
          />

          {/* {markets?.map((record) => (
            <Accordion className={classes.expand_more}>
              <AccordionSummary
                expandIcon={<ExpandMore />}
                aria-controls="panel1a-content"
                id="panel1a-header"
              >
                <div className={classes.drax_pgem}>
                  <div className={classes.drax_percentage}>
                    <img
                      src={record.quote_id.streamer_id.avatar}
                      style={{
                        width: "40px",
                        "border-radius": "50%",
                        height: "40px",
                      }}
                    />
                    <h6>
                      {record?.quote_id?.symbol}/{record?.base_id?.symbol}
                    </h6>
                  </div>
                  <div className={classes.twohundred}>
                    <h5
                      style={{
                        color:
                          record?.base_pool / record?.quote_pool >
                          record?.last_price
                            ? "#ff0000"
                            : "#008000",
                      }}
                    >
                      {record?.last_price
                        ? record.last_price.toFixed(8)
                        : (record.base_pool / record.quote_pool).toFixed(8)}
                    </h5>
                  </div>
                </div>
              </AccordionSummary>
              <AccordionDetails>
                <div className={classes.Buy_Sell}>
                  <div className={classes.streamers_details}>
                    <div className={classes.ByStreamer_main}>
                      <div className={classes.ByStreamer}>
                        <img
                          src={record.quote_id.streamer_id.avatar}
                          style={{ width: "80px", height: "80px" }}
                        />
                      </div>
                      <Stack direction="column">
                        <div className={classes.friendsIcon}>
                          <img src={FriendsIcon} />
                          <p>{record?.quote_id?.streamer_id?.followers}</p>
                        </div>
                        {record?.quote_id?.streamer_id?.instagram && (
                          <div className={classes.friendsIcon}>
                            <a
                              href={record?.quote_id?.streamer_id?.instagram}
                              target="_blank"
                            >
                              <img src={instaIcon} />
                            </a>
                          </div>
                        )}
                        {record?.quote_id?.streamer_id?.youtube && (
                          <div className={classes.friendsIcon}>
                            <a
                              href={record?.quote_id?.streamer_id?.youtube}
                              target="_blank"
                            >
                              <img src={youtubeIcon} />
                            </a>
                          </div>
                        )}
                        {record?.quote_id?.streamer_id?.tiktok && (
                          <div className={classes.friendsIcon}>
                            <a
                              href={record?.quote_id?.streamer_id?.tiktok}
                              target="_blank"
                            >
                              <img
                                src={TiktokImg}
                                style={{ width: "16px", height: "16px" }}
                              />
                            </a>
                          </div>
                        )}
                      </Stack>
                    </div>
                    <div className={classes.MekaVerse}>
                      <div className={classes.Streamer_name}>
                        <div>
                          <img src={StreamerIcon} />
                        </div>
                      </div>
                      <Link
                        to={`/streamer-profile?id=${record?.quote_id?.streamer_id?._id}`}
                        style={{ textDecoration: "none" }}
                      >
                        <h5 className={classes.mekaverse}>
                          {record?.quote_id?.streamer_id?.name}
                        </h5>
                      </Link>
                    </div>
                  </div>
                  <div className={classes.pgem_liquidity}>
                    <div className={classes.streamer_viewr}>
                      <h3>{record?.quote_pool?.toFixed(4)}</h3>
                      <p>Streamer Token pool</p>
                    </div>
                    <br />
                    <div className={classes.liquidity}>
                      <h3>{record?.base_pool?.toFixed(4)}</h3>
                      <p>PGEM liquidity</p>
                    </div>
                  </div>
                  {window.innerWidth > 768 && (
                    <div className={classes.BuySell_Button}>
                      <div className={classes.Buy_now}>
                        <Button
                          onClick={() => {
                            setQuoteCoin(record?.quote_id?.symbol);
                            getMarket(record?.quote_id?.symbol);
                            window.scrollTo(0, 0);
                          }}
                        >
                          Swap
                        </Button>
                      </div>
                    </div>
                  )}
                </div>
                {window.innerWidth < 769 && (
                  <div className={classes.BuySell_Button}>
                    <div className={classes.Buy_now}>
                      <Button
                        onClick={() => {
                          setQuoteCoin(record?.quote_id?.symbol);
                          getMarket(record?.quote_id?.symbol);
                          window.scrollTo(0, 0);
                        }}
                      >
                        Swap
                      </Button>
                    </div>
                  </div>
                )}
              </AccordionDetails>
            </Accordion>
          ))} */}
        </div>
      </ContentWrapper>
    </DashboardLayout>
  );
};
