import { lazy } from "react";
import { PAGES } from "../pages";

import Createnewnft from "../../components/Createnewnft/Createnewnft";
// import { CategoriesNFT } from "../../components/caregoriesNFT/CategoriesNFT";
import { Polycoins } from "../../components/polycoins/Polycoins";
import { StartStream } from "../../components/startStream/StartStream";
// import Chats from "../../pages/chats/chats";
// import { Exchange } from "../../pages/exchange/Exchange";
// import { NFTPage } from "../../pages/nftPage/NFTPage";
import NftSeeAll from "../../pages/nftSeeAll";
// import PolyCommunity from "../../pages/polyCommunity/polyCommunity";
import StreamerStreaming from "../../pages/streamerStreaming/final";

const Submit_Request = lazy(() =>
  import("../../components/submitRequest/Submit_Request")
);
const Change_password = lazy(() =>
  import("../../components/change_password/Change_password")
);
const Blocked_accounts = lazy(() =>
  import("../../components/Blockedaccounts/Blocked_accounts")
);
const Edit_profile = lazy(() =>
  import("../../components/EditProfile/Edit_profile")
);
const StreamerProfile = lazy(() =>
  import("../../pages/streamerProfile/StreamerProfile")
);
const TransactionHistory = lazy(() => import("../../pages/TransactionHistory"));
const GamesSeeAll = lazy(() => import("../../pages/gamesSeeAll"));
const StreamersSeeAll = lazy(() => import("../../pages/streamsSeeAll"));
const StreamSeeAll = lazy(() =>
  import("../../pages/streamsSeeAll/Streamseeall")
);
const GamesDetails = lazy(() => import("../../pages/gameDetails/GamesDetails"));
const StreamVideo = lazy(() =>
  import("../../pages/streamerStreaming/StreamVideo")
);
// const LeaderBoard = lazy(() => import("../../pages/leaderBoard/LeaderBoard"));
const Filters = lazy(() => import("../../pages/filters/Filters"));
const MyGames = lazy(() => import("../../pages/activities/myGames/MyGames"));
const MyStreamers = lazy(() =>
  import("../../components/myStreamers/MyStreamers")
);
const MySocialPosts = lazy(() =>
  import("../../pages/mySocialPosts/mySocialPosts")
);

// const Staking = lazy(() => import("../../pages/Staking/stakingmain"));

export const PROTECTED_ROUTES = [
  {
    path: PAGES.SUBMIT_STREAMER_REQUEST,
    element: <Submit_Request />,
    lazy: true,
  },
  // {
  //   path: PAGES.CHATS,
  //   element: <Chats />,
  // },
  {
    path: PAGES.SETTINGS_CHANGE_PASSWORD,
    element: <Change_password />,
    lazy: true,
  },
  {
    path: PAGES.SETTINGS_BLOCKED_ACCOUNTS,
    element: <Blocked_accounts />,
    lazy: true,
  },
  {
    path: PAGES.SETTINGS_EDIT_PROFILE,
    element: <Edit_profile />,
    lazy: true,
  },
  {
    path: PAGES.STREAMER_PROFILE,
    element: <StreamerProfile />,
    lazy: true,
  },
  {
    path: PAGES.TRANSACTION_HISTORY,
    element: <TransactionHistory />,
    lazy: true,
  },
  {
    path: PAGES.GAME_SEE_ALL,
    element: <GamesSeeAll />,
    lazy: true,
  },
  {
    path: PAGES.STREAMER_SEE_ALL,
    element: <StreamersSeeAll />,
    lazy: true,
  },
  {
    path: PAGES.STREAMS_SEE_ALL,
    element: <StreamSeeAll />,
    lazy: true,
  },
  {
    path: PAGES.NFT_WALLET,
    element: <NftSeeAll />,
  },
  {
    path: PAGES.GAME_DETAILS,
    element: <GamesDetails />,
    lazy: true,
  },
  {
    path: PAGES.STREAMER_STREAMING,
    element: <StreamerStreaming />,
  },
  {
    path: PAGES.STREAM_VIDEO,
    element: <StreamVideo />,
    lazy: true,
  },
  // {
  //   path: PAGES.LEADERBOARD,
  //   element: <LeaderBoard />,
  //   lazy: true,
  // },
  {
    path: PAGES.FILTERS,
    element: <Filters />,
    lazy: true,
  },
  {
    path: PAGES.MY_GAMES,
    element: <MyGames />,
    lazy: true,
  },
  {
    path: PAGES.POLYCOINS,
    element: <Polycoins />,
  },
  {
    path: PAGES.MY_STREAMERS,
    element: <MyStreamers />,
    lazy: true,
  },
  // {
  //   path: PAGES.EXCHANGE,
  //   element: <Exchange />,
  // },
  {
    path: PAGES.CREATE_NFT,
    element: <Createnewnft />,
  },
  {
    path: PAGES.START_STREAM,
    element: <StartStream />,
  },
  {
    path: PAGES.MY_SOCIAL_POSTS,
    element: <MySocialPosts />,
    lazy: true,
  },
];
