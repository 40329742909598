/* eslint-disable jsx-a11y/alt-text */
import React from "react";
import deemedLogo from "../../../../images/polygame-logo.svg";
import classes from "./navbar.module.css";
import { useNavigate } from "react-router-dom";
import { PAGES } from "../../../../data/pages";

const Navbar = () => {
  const navigate = useNavigate();

  return (
    <main className={classes.container}>
      <img
        style={{ cursor: "pointer" }}
        onClick={() => navigate(PAGES.HOME)}
        src={deemedLogo}
        height="60px"
      />
      {/* <img
        src={vector}
        style={{ transform: "scale(0.8)", cursor: "pointer" }}
        onClick={() => navigate(-1)}
      /> */}
    </main>
  );
};

export default Navbar;
