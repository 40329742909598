import { useState } from "react";
import { TABS } from "./exploreMenu.data";
import styles from "./exploreMenu.module.css";
import AccountTab from "./components/accountTab/accountTab";

import FeedbackTab from "./components/feedbackTab/feedbackTab";
import CloseIcon from "@mui/icons-material/Close";

function ExploreMenu({ setOpen }) {
  const tabs = Object.values(TABS);
  const [activeTab, setActiveTab] = useState(TABS.ACCOUNT);

  const Tab = ({ name, isActive }) => (
    <div
      className={`${isActive ? styles.tab_active : ""}`}
      onClick={() => setActiveTab(name)}
    >
      {name}
    </div>
  );

  const tabsContainer = (
    <div className={styles.tabs_container}>
      {tabs.map((tab, index) => (
        <Tab
          key={`mobile-tab-${index}`}
          name={tab}
          isActive={activeTab == tab}
        />
      ))}
    </div>
  );

  return (
    <div className={styles.container}>
      <div>
        {tabsContainer}
        {activeTab == TABS.ACCOUNT ? <AccountTab /> : null}
        {activeTab == TABS.FEEDBACKS ? <FeedbackTab /> : null}
      </div>
      <div className={styles.cross_container}>
        <div
          className={styles.cross_icon_container}
          onClick={() => {
            setOpen(false);
          }}
        >
          <CloseIcon />
        </div>
      </div>
    </div>
  );
}

export default ExploreMenu;
