import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";

import styles from "./confirmDeleteModal.module.css";

export default function ConfirmDeleteDialog({
  open,
  setOpen,
  onConfirm,
  onCancel = null,
}) {
  const handleClose = () => {
    onCancel ? onCancel() : setOpen(false);
  };

  return (
    <>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <div className={styles.delete_modal_container}>
          <DialogTitle id="alert-dialog-title">Are you sure?</DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              This action cannot be undone.
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button className={styles.cancel_button} onClick={handleClose}>
              No
            </Button>
            <Button
              className={styles.confirm_button}
              onClick={onConfirm}
              autoFocus
            >
              Yes
            </Button>
          </DialogActions>
        </div>
      </Dialog>
    </>
  );
}
