import { useUserContext } from "../../../../../../context/UserContext";
import styles from "./feedbackTab.module.css";
import { useNavigate } from "react-router-dom";

import Feedback from "../../../../../navbar/components/feedback/feedback";
import { PAGES } from "../../../../../../data/pages";

function FeedbackTab() {
  const { user, servApiUrl, isLoggedIn } = useUserContext();
  const navigate = useNavigate();

  const loginForFeedback = (
    <div className={styles.login_for_feedback_container}>
      <h2>Login to provide feedback</h2>
      <button onClick={() => navigate(PAGES.LOGIN)}>Login</button>
    </div>
  );

  return (
    <>
      {isLoggedIn ? <Feedback user={user} servApiUrl={servApiUrl} /> : null}
      {!isLoggedIn ? loginForFeedback : null}
    </>
  );
}

export default FeedbackTab;
