import Button from "@mui/material/Button";
import { useFormik } from "formik";
import React, { useContext, useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import * as yup from "yup";
import UserContext from "../../context/UserContext";
import card from "../../svgs/login.svg";
import DefaultLayout from "../../wrappers/default";
import InputWrapper from "../../wrappers/InputText";
import GoogleSignIn from "../buttons/GoogleSignIn";
import classes from "./welcomeBack.module.css";
import "./welcome_back.css";
import { PAGES } from "../../data/pages";
import { DISCORD_OAUTH2_LINK } from "../../data/discord";
import useDiscordLogin from "../../hooks/api/mutators/useDiscordLogin";

const Welcome_back = () => {
  const { login } = useContext(UserContext);
  const navigate = useNavigate();
  const location = useLocation();
  const discordAuthCode = new URLSearchParams(location.search).get("code");

  const { discordLogin } = useDiscordLogin();

  useEffect(() => {
    const MIN_AUTH_LENGTH = 5;

    if (
      typeof discordAuthCode != "string" ||
      discordAuthCode.length < MIN_AUTH_LENGTH
    )
      return;

    discordLogin(discordAuthCode);
  }, [discordAuthCode]);

  const handleLogin = (type = "login", func, values = null) => {
    let redirectLocation = null;
    if (location.state) {
      redirectLocation = location.state.redirectTo;
      const id = location.state.id;
      if (id) {
        redirectLocation += "?id=" + id;
      }
    }

    if (type == "login") {
      let user = {
        username: values.username,
        password: values.password,
      };

      func(user, redirectLocation);
    } else {
      func(redirectLocation);
    }
  };

  const formik = useFormik({
    initialValues: {
      username: "",
      password: "",
    },
    onSubmit: (values) => {
      handleLogin("login", login, values);
    },
    validationSchema: yup.object({
      username: yup
        .string()
        .min(3, "must be 3 character or more")
        .required("Username/Email is required."),
      password: yup.string().required("Password is required."),
    }),
  });

  return (
    <DefaultLayout>
      <main className={classes.container}>
        <section>
          <p style={{ padding: "15px 0px" }}>
            Hi,kindly login to continue battle
          </p>
          <form onSubmit={formik.handleSubmit}>
            <InputWrapper
              label="Username/Email"
              onChange={formik.handleChange}
              value={formik.values.username}
              required
              id="username"
              error={formik.errors?.username}
            />

            <InputWrapper
              label="Password"
              type="password"
              id="password"
              onChange={formik.handleChange}
              value={formik.values.password}
              required
              error={formik.errors?.password}
            />

            <h6
              style={{
                alignSelf: "flex-end",
                padding: 0,
                margin: 0,
                cursor: "pointer",
              }}
            >
              <a onClick={() => navigate(PAGES.FORGET_PASSWORD)}>
                Forgot Password?
              </a>
            </h6>
            <Button
              style={{
                background: "var(--primary-color)",
                color: "var(--text-color-light)",
                padding: "10px 0px",
              }}
              type="submit"
            >
              let's combat!
            </Button>
            <div
              style={{
                alignSelf: "center",
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
              }}
            >
              <p>Connect With:</p>
              <div
                style={{
                  display: "flex",
                  gap: "20px",
                  alignItems: "center",
                  marginTop: "10px",
                }}
              >
                <GoogleSignIn handleLogin={handleLogin} />
                {/* <FacebookSignIn /> */}
                <i
                  className={`fa-brands fa-discord`}
                  onClick={() => {
                    window.location.assign(DISCORD_OAUTH2_LINK);
                  }}
                  style={{ color: "#404eed", fontSize: "32px" }}
                />
              </div>
            </div>
            <div
              style={{
                alignSelf: "center",
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
              }}
            >
              <h6 style={{ margin: "6px 0px", padding: 0 }}>
                Dont's have an account?
              </h6>
              <p
                onClick={() => navigate("/create-profile")}
                className={classes.welcome_back_create_account_button}
              >
                create account
              </p>
            </div>
          </form>
        </section>
        <section>
          <img src={card} alt="image1" />
        </section>
      </main>
    </DefaultLayout>
  );
};

export default Welcome_back;
