import { ContentType } from "../data/contentType";

const AUTH_TOKEN_KEY = "pg-auth-token";

export const isAuth = () => {
  const token = localStorage.getItem(AUTH_TOKEN_KEY);
  return typeof token != "string" || token.length == 0 ? false : true;
};

export const saveAuthToken = (token) => {
  const MIN_TOKEN_LENGTH = 10;

  if (typeof token != "string" || token.length < MIN_TOKEN_LENGTH) return;

  localStorage.setItem(AUTH_TOKEN_KEY, token);
};

export const removeToken = () => {
  localStorage.removeItem(AUTH_TOKEN_KEY);
};

export const getAuthToken = () => {
  return "Bearer " + localStorage.getItem(AUTH_TOKEN_KEY);
};

/**
 * getRequestOptions - Function to prepare request options
 * @param {string} method - Api method POST, GET, PUT, DELETE
 * @param {boolean} isAuth - If the api request is authenticated with token or not
 * @param {object} body - JSON object to pass as payload
 * @param {string} contentType - Content Type for request
 * @return {any}
 */

export const getRequestOptions = (
  method = "POST",
  isAuth = true,
  body = null,
  contentType = ContentType.ApplicationJson
) => {
  let headers = {
    "Content-Type": contentType,
  };

  if (isAuth) headers["Authorization"] = getAuthToken();

  let returnObj = {
    method: method,
    headers: headers,
  };

  if (body) {
    returnObj["body"] = JSON.stringify(body);
  }

  return returnObj;
};
