import { CardActionArea, Grid } from "@mui/material";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import CardMedia from "@mui/material/CardMedia";
import React from "react";
import "react-multi-carousel/lib/styles.css";
import commentIcon from "../../images/icons/comments.png";
import SellNft from "../dialogs/sellNftDialog";
import BuyNft from "../dialogs/buyNftDialog";
import ConnectWallet from "../dialogs/ConnectWallet";
import classes from "./index.module.css";
import { Link, useNavigate } from "react-router-dom";
import { OBJModel } from "react-3d-viewer";
const StreamerCard = ({ data = {}, nftOrder = {} }) => {
  const {
    _id,
    img_path,
    name,
    about,
    streamer_id,
    owner_id,
    nft_type,
    file_path,
  } = data;
  const navigate = useNavigate();
  return (
    <Card
      sx={{
        maxWidth: 200,
        width: "48%",
        background: "#13171D",
        borderRadius: "8px",
        justifyContent: "space-evenly",
        paddingBottom: 0,
        marginBottom: "5px",
      }}
    >
      <CardActionArea className={classes.streamercard_main}>
        {/* <CardMedia
          component="img"
          className={classes.nft_card_img}
          image={img_path}
          height="200px"
          alt="NFT Image"
          onClick={() => navigate("/nft?id=" + _id)}
        /> */}
        <Grid
          item
          xs={12}
          lg={6}
          md={6}
          className={classes.nft_image_grid}
          onClick={() => navigate("/nft?id=" + _id)}
        >
          {img_path || file_path ? (
            nft_type == "obj" ? (
              <OBJModel
                src={file_path ? file_path : img_path}
                texPath={file_path ? file_path : img_path}
                position={{ x: 0, y: 0, z: 0 }}
                height="200px"
                width="200px"
              />
            ) : nft_type == "video" ? (
              <video
                height="200px"
                width="200px"
                src={file_path ? file_path : img_path}
                controls
              ></video>
            ) : nft_type == "gif" ? (
              <img
                src={file_path ? file_path : img_path}
                style={{ width: "200px", height: "200px" }}
              />
            ) : (
              <div style={{ width: "200px", height: "200px" }}></div>
            )
          ) : (
            <div style={{ width: "200px", height: "200px" }}></div>
          )}
        </Grid>
        <CardContent
          sx={{ padding: "10px" }}
          style={{ padding: "10px !important" }}
          className={classes.card_content}
        >
          <p>{name}</p>
          <div className={classes.desc_status}>
            <p>{about}</p>
          </div>
          <div className={classes.meka_comments}>
            <p>
              <b> Owned By#</b> {streamer_id.name}
            </p>
          </div>
          <div className={classes.nft_card_btn + " " + classes.nft_btn2}>
            {nftOrder?._id ? (
              <BuyNft nft={data} nftOrder={nftOrder} />
            ) : (
              <SellNft nft={data} />
            )}
          </div>
        </CardContent>
      </CardActionArea>
    </Card>
  );
};

export default StreamerCard;
