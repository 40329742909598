import React, { useContext, useMemo, useState, useEffect } from "react";

import { Box } from "@mui/material";

import DashboardLayout from "../../wrappers/dashboard";

import { getRequestOptions } from "../../functions/auth";
import useGetChatRoom from "../../hooks/api/useGetChatRoom";
import UserContext from "../../context/UserContext";

import { CHAT_GROUPS, ChatRoomActions, GROUP_IMAGES } from "./chats.constants";

import "./chats.css";
import DesktopLiveChat from "../../components/liveChats/desktopLiveChat";
import useIsAuth from "../../hooks/auth/useIsAuth";

import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import { isMobile } from "react-device-detect";

function Chats() {
  const { servApiUrl, socket, user } = useContext(UserContext);
  const { checkAuthAndRedirect } = useIsAuth();
  const [activeGroup, setActiveGroup] = useState(CHAT_GROUPS[0]);
  const [currentGroupId, setCurrentGroupId] = useState("");
  const [commentsArr, setCommentsArr] = useState([]);
  const { loading } = useGetChatRoom(
    servApiUrl,
    activeGroup,
    setCommentsArr,
    setCurrentGroupId
  );

  const joinSocketSession = async () => {
    await socket.emit(ChatRoomActions.JoinChatRoom, {
      sessionId: currentGroupId,
    });
  };

  const leaveSocketSession = async () => {
    await socket.emit(ChatRoomActions.LeaveChatRoom, {
      sessionId: currentGroupId,
    });
  };

  useEffect(() => {
    if (currentGroupId === "") return;
    joinSocketSession();

    return () => {
      leaveSocketSession();
    };
  }, [currentGroupId]);

  const updateCommentsArrayState = (comment) => {
    const commentsArrClone = [...commentsArr];
    commentsArrClone.push(comment);
    setCommentsArr(commentsArrClone);
  };

  useEffect(() => {
    socket.on(ChatRoomActions.ReceiveMessage, (data) => {
      if (!data) return;

      const commentObject = {
        comment: data.comment,
        uid: data.user,
        updatedAt: new Date(),
      };
      if (!user || commentObject.uid?._id != user._id) {
        updateCommentsArrayState(commentObject);
      }
    });
  }, [socket, commentsArr]);

  const comment = async () => {
    if (!checkAuthAndRedirect()) return;
    let text = document.getElementById("comment-text-streaming")?.value;
    if (!text || typeof text != "string" || text.trim().length == 0) {
      return false;
    }

    const commentObject = {
      comment: text.trim(),
      uid: {
        name: user.name,
        username: user.username,
        _id: user._id,
      },
      updatedAt: new Date(),
    };
    updateCommentsArrayState(commentObject);

    const userObj = user;

    socket.emit(ChatRoomActions.SendMessage, {
      comment: text,
      user: {
        name: userObj.name,
        username: userObj.username,
        _id: userObj._id,
      },
      name: activeGroup,
      sessionId: currentGroupId,
    });

    const chatBox = document.getElementById("comment-text-streaming");
    if (chatBox) {
      chatBox.value = "";
    }

    const API_URL = `${servApiUrl}user/comment-chat-room`;
    const requestOptions = getRequestOptions("POST", true, {
      name: activeGroup,
      comment: text.trim(),
    });

    await fetch(API_URL, requestOptions);
  };

  const ChatGroup = ({ group }) => {
    return (
      <div
        className={`chats-chat-group-item ${
          activeGroup === group ? "chats-chat-group-item-active" : ""
        }`}
        onClick={() => setActiveGroup(group)}
      >
        {group}
      </div>
    );
  };

  const chatGroups = useMemo(() => {
    return (
      <>
        <div className="chats-chat-groups-container">
          {CHAT_GROUPS.map((group, index) => (
            <ChatGroup group={group} key={`chats-group-${index}`} />
          ))}
          <div className="chats-chat-groups-right-arrow">
            {isMobile ? <ChevronRightIcon /> : null}
          </div>
        </div>
      </>
    );
  }, [activeGroup]);

  const getImageLink = () => {
    for (let i = 0; i < GROUP_IMAGES.length; i++) {
      const group_image = GROUP_IMAGES[i];

      if (activeGroup === group_image.name) {
        return group_image.image;
      }
    }
  };

  const LoadingSkeletons = () => {
    return (
      <div className="news-skeleton-container" style={{ marginTop: "10px" }}>
        <img
          alt="Image"
          src={getImageLink()}
          style={{ width: "100%", height: "100%" }}
        />
      </div>
    );
  };

  const chatSection = useMemo(() => {
    if (!commentsArr) return;

    return (
      <>
        <div className="chats-chat-section-container">
          <div className="chats-chat-section-inner-container">
            {loading ? (
              <LoadingSkeletons />
            ) : (
              <Box
                className="streamer-box stream-desktop-container streamer-box-chats"
                sx={{ width: "100% !important" }}
              >
                <DesktopLiveChat commentsArr={commentsArr} comment={comment} />
              </Box>
            )}
          </div>
        </div>
      </>
    );
  }, [loading, commentsArr, activeGroup, currentGroupId]);

  return (
    <DashboardLayout showSidebar={false} noRightMargin={true}>
      <div className="chats_main_container">
        <div className="chats_main_inner_container">
          {chatGroups}
          {chatSection}
        </div>
      </div>
    </DashboardLayout>
  );
}

export default Chats;
