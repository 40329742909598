import * as React from "react";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import { useNavigate } from "react-router-dom";

const NavbarDesktopDropdown = ({ anchorEl, open, handleClose }) => {
  const navigate = useNavigate();
  // Close dropdown then open the link
  const openLinkAndCloseDropdown = (link) => {
    handleClose();
    window.open(link, "_blank");
  };

  return (
    <div>
      <Menu
        id="basic-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          "aria-labelledby": "basic-button",
        }}
        anchorOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "left",
        }}
        className="navbar-desktop-dropdown-root"
      >
        <div>
          <MenuItem
            className="navbar-desktop-dropdown-item-container"
            onClick={() =>
              openLinkAndCloseDropdown("https://linktr.ee/polygame.io")
            }
          >
            <div className="navbar-desktop-dropdown-item">About Us</div>
          </MenuItem>
          <MenuItem
            className="navbar-desktop-dropdown-item-container"
            onClick={() =>
              openLinkAndCloseDropdown(
                "https://datastudio.google.com/reporting/407c5a25-b449-47bf-93cf-053702131de4/page/1M"
              )
            }
          >
            <div className="navbar-desktop-dropdown-item">View Traction</div>
          </MenuItem>
          {/* <MenuItem
            className="navbar-desktop-dropdown-item-container"
            onClick={() =>
              openLinkAndCloseDropdown(
                "https://docsend.com/view/is2jzeuvf966kask"
              )
            }
          >
            <div className="navbar-desktop-dropdown-item">Tokenomics</div>
          </MenuItem> */}
          <MenuItem
            className="navbar-desktop-dropdown-item-container"
            onClick={() => navigate("/news")}
          >
            <div className="navbar-desktop-dropdown-item">News</div>
          </MenuItem>
        </div>
      </Menu>
    </div>
  );
};

export default NavbarDesktopDropdown;
