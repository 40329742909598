// import MenuIcon from "@mui/icons-material/Menu";
import NotificationsIcon from "@mui/icons-material/Notifications";
import { NAVBAR_TABS } from "./navbar.constants.js";
import AppBar from "@mui/material/AppBar";
import Avatar from "@mui/material/Avatar";
import Badge from "@mui/material/Badge";
import Box from "@mui/material/Box";
import IconButton from "@mui/material/IconButton";
import Popover from "@mui/material/Popover";
import Stack from "@mui/material/Stack";
import { makeStyles } from "@mui/styles";
import Toolbar from "@mui/material/Toolbar";
import React, { useContext, useState } from "react";
import { useNavigate } from "react-router-dom";
import UserContext from "../../context/UserContext";
import avatarImg from "../../images/navbar-avatar.png";
import "./navbar.css";
import { Button } from "@mui/material";
import AccountCircleSharpIcon from "@mui/icons-material/AccountCircleSharp";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import { useMemo } from "react";
import NavbarDesktopDropdown from "./components/navbarDesktopDropdown/navbarDesktopDropdown";
import Annoucements from "./components/announcements/announcements.jsx";
import Feedback from "./components/feedback/feedback.jsx";
import Opinions from "./components/opinions/opinions.jsx";
import { PAGES } from "../../data/pages.js";
import { isMobile } from "react-device-detect";

const useStyles = makeStyles((theme) => ({
  root: {
    background: "transparent",
    maxWidth: "unset !important",
  },
}));

const buttonStyles = {
  background: "var(--primary-color)",
  color: "var(--text-color-light)",
  "&:hover": {
    background: "var(--text-hover-color)",
    color: "var(--text-hover-color-dark)",
  },
};

const navbarStyles = {
  position: "fixed",
  top: 0,
  zIndex: 9999,
  background: "var(--bg-color)",
  width: "100%",
  boxShadow: "0 0 2px var(--grey-color-light)",
};

export default function Navbar({ handleDrawerToggle, mobileOpen }) {
  const Notification = () => {
    const classes = useStyles();
    const [openPopover, setOpenPopover] = React.useState(null);
    const open = Boolean(openPopover);
    const id = open ? "simple-popover" : undefined;
    const [activeTab, setActiveTab] = useState(NAVBAR_TABS.ANNOUNCEMENTS);
    const { servApiUrl, user } = useContext(UserContext);

    const handleNotificationClick = (e) => {
      if (!openPopover) {
        setOpenPopover(e.currentTarget);
      } else {
        setOpenPopover(null);
      }
    };
    const handleClose = () => {
      setOpenPopover(null);
    };

    // const getNotifications = () => {
    //   if (!user) return;
    //   let requestOptions = {
    //     method: "GET",
    //     headers: {
    //       "Content-Type": "application/json",
    //       Authorization: "Bearer " + localStorage.getItem("pg-auth-token"),
    //     },
    //   };
    //   fetch(`${servApiUrl}notification/notifications`, requestOptions)
    //     .then((response) => response.json())
    //     .then((data) => {
    //       // setGames(data);
    //     });
    // };

    // useEffect(() => {
    //   getNotifications();
    // }, []);

    return (
      <>
        {user ? (
          <IconButton
            size="large"
            color="inherit"
            className="notification-icon-home"
          >
            <Badge
              variant="dot"
              sx={{
                "& .MuiBadge-badge": {
                  color: "var(--primary-color)",
                  backgroundColor: "var(--primary-color)",
                  fontSize: "12px",
                },
              }}
            >
              <IconButton
                onClick={handleNotificationClick}
                classes={classes.notficon}
                sx={{
                  "& .MuiSvgIcon-root": {
                    color: "var(--primary-color) !important",
                  },
                }}
              >
                <NotificationsIcon
                  className={classes.notification}
                  htmlColor="green"
                />
              </IconButton>
              <Popover
                id={id}
                open={open}
                anchorReference="anchorPosition"
                anchorPosition={{ top: 0, left: 0 }}
                anchorOrigin={{
                  vertical: "top",
                  horizontal: "left",
                }}
                transformOrigin={{
                  vertical: "top",
                  horizontal: "right",
                }}
                sx={{ top: "60px" }}
              >
                <div
                  style={{
                    height: "80vh",
                    width: "96vw",
                    background: "var(--bg-color)",
                    border: "2px rgba(128, 128, 128, 0.7) solid",
                    borderRadius: "10px",
                  }}
                >
                  <div
                    className="notification-heading"
                    style={{
                      display: "flex",
                      alignItems: "center",
                      borderBottom: "1px solid var(--primary-color)",
                      width: "100%",
                      color: "var(--text-color)",
                      justifyContent: "space-around",
                      height: "45px",
                    }}
                  >
                    <div
                      className={`notification-tab ${
                        activeTab === NAVBAR_TABS.ANNOUNCEMENTS
                          ? "notification-tab-active"
                          : ""
                      }`}
                      onClick={() => setActiveTab(NAVBAR_TABS.ANNOUNCEMENTS)}
                    >
                      <p>Announcements</p>
                    </div>
                    <div
                      className={`notification-tab ${
                        activeTab === NAVBAR_TABS.OPINIONS
                          ? "notification-tab-active"
                          : ""
                      }`}
                      onClick={() => setActiveTab(NAVBAR_TABS.OPINIONS)}
                    >
                      <p>Opinions</p>
                    </div>
                    {!isMobile ? (
                      <div
                        className={`notification-tab ${
                          activeTab === NAVBAR_TABS.FEEDBACKS
                            ? "notification-tab-active"
                            : ""
                        }`}
                        onClick={() => setActiveTab(NAVBAR_TABS.FEEDBACKS)}
                      >
                        <p>Feedbacks</p>
                      </div>
                    ) : null}
                  </div>
                  {activeTab === NAVBAR_TABS.ANNOUNCEMENTS ? (
                    <Annoucements servApiUrl={servApiUrl} />
                  ) : activeTab === NAVBAR_TABS.FEEDBACKS && !isMobile ? (
                    <Feedback
                      setOpen={setOpenPopover}
                      servApiUrl={servApiUrl}
                      user={user}
                    />
                  ) : (
                    <Opinions servApiUrl={servApiUrl} user={user} />
                  )}
                </div>
              </Popover>
            </Badge>
          </IconButton>
        ) : null}
      </>
    );
  };

  const navigate = useNavigate();

  const loginSignUpLogo = useMemo(() => {
    return (
      <div className="dummyUserLogo" onClick={(e) => navigate(PAGES.LOGIN)}>
        <AccountCircleSharpIcon
          style={{ fontSize: 45, color: "var(--text-color)" }}
        />
      </div>
    );
  }, []);

  const { user } = useContext(UserContext);
  const [anchorEl, setAnchorEl] = React.useState(null);

  // useEffect(async () => {
  //   if (!user) {
  //     navigate("/");
  //   }
  // }, []);

  const handleClick = (event) => {
    if (anchorEl) handleClose();
    else setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const threeDotsDropdown = useMemo(() => {
    const open = Boolean(anchorEl);

    return (
      <>
        <NavbarDesktopDropdown
          anchorEl={anchorEl}
          open={open}
          handleClose={handleClose}
        />
        <div
          className="three-dots-icon"
          id="basic-button"
          aria-controls={open ? "basic-menu" : undefined}
          aria-haspopup="true"
          aria-expanded={open ? "true" : undefined}
          onClick={handleClick}
          style={{ marginTop: "10px" }}
        >
          <MoreVertIcon sx={{ fontSize: "30px", color: "var(--text-color)" }} />
        </div>
      </>
    );
  }, [anchorEl]);

  return (
    <div
      style={{
        paddingTop: "80px",
      }}
    >
      <Box style={navbarStyles} sm={{ flexGrow: 1 }}>
        <AppBar position="static" className="app_bar">
          <Toolbar sx={{ padding: "0px 10px !important" }}>
            {/* <IconButton
              color="inherit"
              className="menu-icon"
              aria-label="open drawer"
              onClick={handleDrawerToggle}
              sx={{
                display: {
                  lg: "none",
                  md: "none",
                  sm: "none",
                  margin: "10px 10px",
                },
                alignSelf: "flex-start",
              }}
            >
              <MenuIcon sx={{ color: "var(--text-color)" }} />
            </IconButton> */}
            <div
              className={"polygameLogo"}
              style={{ cursor: "pointer" }}
              onClick={() => navigate(PAGES.HOME)}
            />

            <Box sx={{ flexGrow: 1 }} className="navbar-desktop-tabs-container">
              {window.innerWidth > 767 && (
                <>
                  <a
                    target="_blank"
                    href="https://linktr.ee/polygame.io"
                    className="navbar-tab-button"
                  >
                    <Button
                      variant="contained"
                      sx={buttonStyles}
                      className="navbar-tab-desktop-button"
                    >
                      About Us
                    </Button>
                  </a>

                  <a
                    target="_blank"
                    href="https://datastudio.google.com/reporting/407c5a25-b449-47bf-93cf-053702131de4/page/1M"
                    className="navbar-tab-button"
                  >
                    <Button
                      variant="contained"
                      sx={buttonStyles}
                      className="navbar-tab-desktop-button"
                    >
                      View Traction
                    </Button>
                  </a>
                  {/* <a
                    onClick={() => navigate("/news")}
                    className="navbar-tab-button"
                  >
                    <Button
                      variant="contained"
                      sx={buttonStyles}
                      className="navbar-tab-desktop-button"
                    >
                      News
                    </Button>
                  </a> */}
                  {threeDotsDropdown}
                </>
              )}
            </Box>
            <Box
              sx={{ display: { xs: "flex", md: "flex" } }}
              className="profile-box"
            >
              <Stack direction="row" spacing={1} className="avatar">
                {user ? (
                  <Avatar
                    alt="avatar"
                    src={user?.avatar || avatarImg}
                    onClick={() => navigate("/settings/edit-profile")}
                  />
                ) : (
                  loginSignUpLogo
                )}
                {user ? (
                  <div
                    className="avatarName"
                    style={{ cursor: "pointer" }}
                    onClick={() => navigate("/settings/edit-profile")}
                  >
                    <p>{user?.username}</p>
                  </div>
                ) : null}
              </Stack>
              <Notification />
            </Box>
          </Toolbar>
        </AppBar>
        {/* {renderMobileMenu*/}
        {/* {renderMenu} */}
      </Box>
    </div>
  );
}
