import "./watchToEarnAnimation.css";
// import AttachMoneyIcon from "@mui/icons-material/AttachMoney";
import logo from "../../images/logo.svg";
import { useNavigate } from "react-router-dom";
import { useEffect, memo } from "react";

let saveInDatabaseInterval;

const WatchToEarnAnimation = ({
  joinSocketSession,
  id,
  userWatchSessionId,
  socket,
  userId,
  role = "viewer",
}) => {
  const navigate = useNavigate();

  const updateSocketSessionEndTime = () => {
    socket.emit("livestream_update_session_end_time", {
      userId,
      userWatchSessionId: userWatchSessionId,
    });
  };

  const startSaveInDatabaseInterval = () => {
    const INTERVAL_TIME = 1000 * 30;
    saveInDatabaseInterval = setInterval(() => {
      updateSocketSessionEndTime();
    }, INTERVAL_TIME);
  };

  const startIntervals = async () => {
    await joinSocketSession(id);
    if (role !== "streamer") {
      startSaveInDatabaseInterval();
    }
  };

  useEffect(() => {
    startIntervals();

    return () => {
      clearInterval(saveInDatabaseInterval);
      saveInDatabaseInterval = null;
    };
  }, []);

  return (
    <>
      <div
        className="watchToEarn-container"
        onClick={() => navigate("/polycoins")}
      >
        <img src={logo} />
      </div>
    </>
  );
};

export default memo(WatchToEarnAnimation);
