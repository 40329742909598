import React, { useMemo, useState } from "react";

import { Box } from "@mui/material";
import TextField from "@mui/material/TextField";
import SendIcon from "@mui/icons-material/Send";

import classes from "./chat.module.css";

function MobileLiveChat({ commentsArr, comment = null }) {
  const [text, setText] = useState("");

  const getUserName = (user) => {
    if (!user) return "";
    return user.username || user.name;
  };

  const handleComment = () => {
    setText("");
    comment();
  };

  const commentsContainer = useMemo(
    () => (
      <Box className="streamer-box stream-desktop-container">
        {Object.keys(commentsArr)
          .reverse()
          .map((keyName, index) => {
            const cardData = commentsArr[keyName];
            return (
              <div
                style={{ width: "100%", margin: "10px 0px" }}
                key={`comment-arr-${index}`}
              >
                {/* {index !== 0 ? (
                      <Divider sx={{ backgroundColor: "white" }} />
                    ) : null} */}
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                  }}
                >
                  <p
                    style={{
                      fontWeight: "bold",
                      fontSize: "12px",
                      color: "var(--primary-color)",
                    }}
                  >
                    {getUserName(cardData.uid)}:{"  "}
                    <span className="stream-comment-class">
                      {cardData.comment}
                    </span>
                  </p>
                </div>
              </div>
            );
          })}
      </Box>
    ),
    [commentsArr]
  );

  const NoCommentsText = () => (
    <div className={classes.no_comments_container}>
      <h2>No Comments</h2>
      <p className={classes.no_comments_text}>Start the conversation!</p>
    </div>
  );

  const textField = (
    <div
      style={{ marginBottom: "5px", marginTop: "0px" }}
      className="reply-input"
    >
      <TextField
        variant="standard"
        size="medium"
        placeholder="Say Something"
        InputProps={{
          disableUnderline: true,
        }}
        onKeyUp={(e) => {
          if (e.key === "Enter") {
            e.preventDefault();
            handleComment();
          }
        }}
        id={"comment-text-streaming"}
        onChange={(e) => setText(e.target.value)}
      />
      <span className="sendIcons" onClick={() => handleComment()}>
        <SendIcon
          sx={{
            fontSize: "20px",
            color:
              text.trim().length == 0 ? "lightgray" : "var(--primary-color)",
            marginLeft: "5px",
          }}
        />
      </span>
    </div>
  );

  return (
    <Box
      sx={{
        overflow: "hidden",
        height: "40vh",
        marginTop: "20px",
      }}
    >
      {textField}
      {commentsArr.length == 0 ? NoCommentsText() : commentsContainer}
    </Box>
  );
}

export default MobileLiveChat;
