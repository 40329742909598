import { useNavigate } from "react-router-dom";
import { isAuth } from "../../functions/auth";
import { PAGES } from "../../data/pages";
import { useUserContext } from "../../context/UserContext";

function useIsAuth() {
  const navigate = useNavigate();
  const { isLoggedIn } = useUserContext();

  const checkAuthAndRedirect = (state = null) => {
    if (isLoggedIn) return true;
    navigate(PAGES.LOGIN, { state });
    return false;
  };

  return { checkAuthAndRedirect };
}

export default useIsAuth;
