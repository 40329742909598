import { useUserContext } from "../../../context/UserContext";

const GoLive = ({ streamId, getStream, callBack, streamerId }) => {
  const { setMessage, servApiUrl, user } = useUserContext();

  const goLive = () => {
    let requestOptions = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + localStorage.getItem("pg-auth-token"),
      },
      body: JSON.stringify({ _id: streamId, domain: window.location.origin }),
    };
    fetch(servApiUrl + "session/start-stream", requestOptions)
      .then((response) => {
        if (response.status === 200) return response.json();
        else throw response;
      })
      .then((data) => {
        setMessage({ message: "Stream is live!", type: "success" });
        getStream?.(streamId);
        callBack?.(data);
      });
  };

  return (
    streamerId === user?._id && (
      <button
        style={{
          backgroundColor: "var(--primary-color)",
          padding: "5px 10px",
          fontWeight: "bold",
          borderRadius: "15%",
          borderColor: "var(--primary-color)",
          cursor: "pointer",
          color: "var(--text-color-light)",
        }}
        onClick={goLive}
      >
        Go Live
      </button>
    )
  );
};

export default GoLive;
