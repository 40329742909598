import * as React from "react";
import Skeleton from "@mui/material/Skeleton";

export default function SkeletonLoader({
  width = "100%",
  height = "100%",
  variant = "rectangular",
  animation = "wave",
}) {
  return (
    <Skeleton
      variant={variant}
      width={width}
      height={height}
      animation={animation}
    />
  );
}
