import Swal from "sweetalert2";

export const swalFormError = (
  error,
  toast = false,
  position = "center",
  text = ""
) => {
  Swal.fire({
    icon: "error",
    title: error,
    toast,
    position: position,
    showConfirmButton: false,
    timer: 1500,
    text,
    customClass: {
      container: "swal-container",
    },
  });
};

export const swalFormSuccess = (
  msg,
  toast = false,
  position = "center",
  text = ""
) => {
  Swal.fire({
    icon: "success",
    title: msg,
    toast,
    position: position,
    showConfirmButton: false,
    timer: 1500,
    text,
    customClass: {
      container: "swal-container",
    },
  });
};
