const UserService = {
  getUser: async (servApiUrl) => {
    const token = localStorage.getItem("pg-auth-token");
    if (token) {
      let requestOptions = {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + localStorage.getItem("pg-auth-token"),
        },
      };
      return await fetch(`${servApiUrl}user/profile`, requestOptions)
        .then((response) => {
          if (response.status === 200) return response.json();
          else throw new Error("Some error occured");
        })
        .then((data) => {
          return data;
        });
    } else {
      throw new Error("no user present!");
    }
  },
};
export default UserService;
