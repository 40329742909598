import { useState } from "react";

import { getAuthToken } from "../../../functions/auth";
import { swalFormError } from "../../../functions/swal";
import { useUserContext } from "../../../context/UserContext";

const useCreateXenditInvoice = () => {
  const [loading, setLoading] = useState(false);
  const { user, servApiUrl } = useUserContext();

  const createXenditInvoice = async (productName, amount, pgem) => {
    setLoading(true);
    let result = {};

    const payload = {
      userId: user._id,
      productName,
      phoneNumber: user.phoneNumber,
      amount,
      pgem,
    };

    try {
      const res = await fetch(`${servApiUrl}payment/pgem-invoice`, {
        method: "POST",
        body: JSON.stringify(payload),
        headers: {
          "Content-Type": "application/json",
          Authorization: getAuthToken(),
        },
      });
      const response = await res.json();
      result = response;
      if (response.success != true) {
        swalFormError(response.msg);
      }
    } catch (err) {
      result.success = false;
      swalFormError("Some error occurred");
    } finally {
      setLoading(false);
    }

    return result;
  };

  return { createXenditInvoice, loading };
};

export default useCreateXenditInvoice;
