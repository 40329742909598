import React, { useContext } from "react";
import { Navigate, Outlet } from "react-router-dom";
import UserContext from "../../context/UserContext";
import { PAGES } from "../../data/pages";

const UnprotectedRoute = () => {
  const { isLoggedIn } = useContext(UserContext);

  return !isLoggedIn ? <Outlet /> : <Navigate to={PAGES.HOME} />;
};

export default UnprotectedRoute;
