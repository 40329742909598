import React, { useContext } from "react";
import { Navigate, Outlet } from "react-router-dom";
import UserContext from "../../context/UserContext";
import { PAGES } from "../../data/pages";

const StreamerRoute = ({ children }) => {
  const { user, isLoggedIn } = useContext(UserContext);

  if (!isLoggedIn) return <Navigate to="/login" />;

  if (user.role === "follower") return <Navigate to={PAGES.HOME} />;

  return <Outlet />;
};

export default StreamerRoute;
