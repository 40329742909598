import DownArrow from "@mui/icons-material/ArrowDownward";
import SwapIcon from "@mui/icons-material/SwapHoriz";
import { Button } from "@mui/material";
import { HTML5_FMT } from "moment";
import React, { useContext } from "react";
import logo from "../../images/logo.png";
import Dialog from "../../wrappers/Dialog";
import UserContext from "../../context/UserContext";
import useIsAuth from "../../hooks/auth/useIsAuth";

function BuyNft({ nft, nftOrder }) {
  const { servApiUrl, setMessage } = useContext(UserContext);
  const { checkAuthAndRedirect } = useIsAuth();

  const buyNft = async (setOpen) => {
    if (!checkAuthAndRedirect()) return;
    let requestOptions = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + localStorage.getItem("pg-auth-token"),
      },
      body: JSON.stringify({
        orderId: nftOrder._id,
      }),
    };
    await fetch(`${servApiUrl}user/buyNft`, requestOptions)
      .then((response) => response.json())
      .then((data) => {
        if (!data.success) {
          setMessage({ message: data.message, type: "error" });
        } else {
          setMessage({ message: data.message, type: "success" });
          setOpen(false);
        }
      });
  };
  React.useEffect(() => {}, []);
  return (
    <Dialog
      actions={{
        successBtnProps: {
          title: "Buy NFT# " + nft?.name,
        },
      }}
      title={"Buy NFT# " + nft?.name}
      handler={{
        cancel: (setOpen) => {
          setOpen(false);
        },
        success: (setOpen) => {
          buyNft(setOpen);
        },
      }}
      Source={({ setOpen }) => (
        <Button
          onClick={setOpen.bind(this, true)}
          variant="contained"
          className="buttonSell"
        >
          BUY
        </Button>
      )}
    >
      <label
        htmlFor="sell-nft-price"
        style={{ color: "var(--text-color)", marginLeft: 0 }}
      >
        Price
      </label>
      {/* <input type="number" id="sell-nft-price" style={{border: "1px solid grey", "margin-top": "10px"}} /> */}
      <h2 style={{ color: "var(--text-color)" }}>{nftOrder?.price} PGEM</h2>
    </Dialog>
  );
}

export default BuyNft;
