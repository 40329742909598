import React, { useState, useEffect, useMemo } from "react";

import classes from "../../../pages/activities/activities.module.css";
import moment from "moment";

import { subtractDate } from "../../../functions/subtractDate";

const config = {
  headers: { Authorization: `Bearer ${localStorage.getItem("pg-auth-token")}` },
};

function StreamerDashboard({ servApiUrl, user }) {
  const [streamerAnalyticsData, setStreamerAnalyticsData] = useState(null);
  const [filterData, setFilterData] = useState({
    from: subtractDate("month"),
    to: moment().format("YYYY-MM-DDTHH:MM"),
  });

  useEffect(() => {
    getStreamerData();
  }, []);

  const getStreamTimeHours = (streamTime) => {
    if (!streamTime) return "0h 0m";

    const hours = Math.floor(streamTime / 60);
    const minutes = Math.round(streamTime % 60);
    const result = hours + "h " + minutes + "m";
    return result;
  };

  const StreamItem = (heading, value) => (
    <div style={{ display: "flex" }}>
      <p
        className={classes.activitiesavatardetailsp}
        style={{ fontWeight: 400 }}
      >
        {heading}: &nbsp;
      </p>
      <p className={classes.activitiesavatardetailsp}>{value}</p>
    </div>
  );

  const getStreamerData = () => {
    fetch(
      `${servApiUrl}admin/getStreamerDetailedData/${user._id}?from=${moment(
        filterData.from
      ).format("YYYY-MM-DD")}&to=${moment(filterData.to).format("YYYY-MM-DD")}`,
      { method: "GET", headers: config.headers }
    ).then(async (res) => {
      const response = await res.json();
      setStreamerAnalyticsData(response);
    });
  };

  const getStreamerData2 = (from, to) => {
    fetch(
      `${servApiUrl}admin/getStreamerDetailedData/${user._id}?from=${moment(
        from
      ).format("YYYY-MM-DD")}&to=${moment(to).format("YYYY-MM-DD")}`,
      { method: "GET", headers: config.headers }
    ).then(async (res) => {
      const response = await res.json();
      setStreamerAnalyticsData(response);
    });
  };

  const streamerData = useMemo(
    () => (
      <div>
        <div className={classes.stream_time_select_main}>
          <div className={classes.stream_time_select_container}>
            <div
              style={{ paddingRight: "20px" }}
              className={classes.activityStreamerDatInputContainer}
            >
              <p>From</p>
              <input
                type="datetime-local"
                id="birthdaytime"
                name="birthdaytime"
                value={filterData.from}
                onChange={(e) =>
                  setFilterData({ ...filterData, from: e.target.value })
                }
                onBlur={getStreamerData}
                style={{
                  color: "var(--text-color-light)",
                  colorScheme: "dark",
                }}
              />
            </div>
            <div
              style={{ paddingRight: "20px" }}
              className={classes.activityStreamerDatInputContainer}
            >
              <p>To</p>
              <input
                type="datetime-local"
                id="birthdaytime"
                name="birthdaytime"
                value={filterData.to}
                onChange={(e) =>
                  setFilterData({ ...filterData, to: e.target.value })
                }
                onBlur={getStreamerData}
                style={{
                  color: "var(--text-color-light)",
                  colorScheme: "dark",
                }}
              />
            </div>
          </div>
          <p>or</p>
          <div style={{ padding: "0 20px" }}>
            <p className={classes.activityTimelineHeader}>Select timeline:</p>
            <select
              name="timeline"
              id="timeline"
              defaultValue="month"
              style={{
                color: "var(--text-color-light)",
                backgroundColor: "var(--input)",
                border: "black",
                padding: "5px 10px",
              }}
              onChange={(e) => {
                const from = subtractDate(e.target.value);
                const to = moment().format("YYYY-MM-DDTHH:mm:ss");
                setFilterData({
                  ...filterData,
                  from,
                  to,
                });
                getStreamerData2(from, to);
              }}
            >
              <option value="day">Last 24hrs</option>
              <option value="week">Last week</option>
              <option value="month">Last month</option>
              <option value="year">Last year</option>
            </select>
          </div>
        </div>
        <div className={classes.activitiesstreamdetails}>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              gap: "20px",
              color: "var(--text-color-light)",
            }}
          >
            {StreamItem("Salary", streamerAnalyticsData?.salary ?? 0)}
            {StreamItem(
              "Total Days",
              streamerAnalyticsData?.totalNumOfDays ?? 0
            )}
            {StreamItem(
              "Total Days Streamed",
              streamerAnalyticsData?.numOfDaysStreamed ?? 0
            )}
            {StreamItem("Valid days", streamerAnalyticsData?.validDays ?? 0)}
            {StreamItem(
              "Invalid days",
              streamerAnalyticsData?.invalidDays ?? 0
            )}
            {StreamItem(
              "Total Streamed Time (mins)",
              Math.round(streamerAnalyticsData?.totalStreamTime ?? 0)
            )}
            {StreamItem(
              "Total Streamed Time (hours)",
              getStreamTimeHours(streamerAnalyticsData?.totalStreamTime)
            )}
          </div>
        </div>
      </div>
    ),
    [streamerAnalyticsData, filterData]
  );

  return <>{streamerData}</>;
}

export default StreamerDashboard;
