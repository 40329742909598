import React, { useState, useEffect, lazy, Suspense } from "react";
import { useNavigate } from "react-router-dom";
import HeroHeading from "../../components/heroHeading/HeroHeading";
import DashboardLayout from "../../wrappers/dashboard/index";
import "./home.css";
import { useUserContext } from "../../context/UserContext";

// import Stack from "@mui/material/Stack";
// import GroupsIcon from "@mui/icons-material/Groups";
// import VisibilityIcon from "@mui/icons-material/Visibility";
import SkeletonLoader from "../../components/skeletonLoader/skeletonLoader";

import HomeVideoCarousel from "./components/homeVideoCarousel/homeVideoCarousel";
import useGetTrendingStreams from "../../hooks/api/useGetTrendingStreams";
import { CarouselIds } from "../../data/carousel";
import useGetDailyMostWatchedStreams from "../../hooks/api/useGetDailyMostWatchedStreams";
const GameCards = lazy(() => import("../../components/gameCard/GameCards"));

export const Home = () => {
  const { servApiUrl, user } = useUserContext();
  const navigate = useNavigate();
  const {
    getTrendingStreams,
    loading: loadingTrendingStreams,
    trendingStreams,
    setTrendingStreams,
  } = useGetTrendingStreams();
  const {
    dailyWatchedStreams,
    getDailyMostWatchedStreams,
    loading: loadingDailyWatchedStreams,
    setDailyWatchedStreams,
  } = useGetDailyMostWatchedStreams();

  const [liveStreams, setLiveStreams] = useState([]);

  const getLiveStreams = () => {
    let requestOptions;
    if (user) {
      requestOptions = {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + localStorage.getItem("pg-auth-token"),
        },
      };
    } else {
      requestOptions = {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
        },
      };
    }

    let getStreamApiUrl = servApiUrl;
    getStreamApiUrl += user
      ? "engine/foryou/liveEvents"
      : "noAuth/foryou/liveEvents";

    fetch(getStreamApiUrl, requestOptions)
      .then((response) => response.json())
      .then((data) => {
        if (!data || !data instanceof Array) return;
        data.reverse();
        setLiveStreams(data);
      });
  };

  useEffect(() => {
    getLiveStreams();

    return () => {
      setLiveStreams([]);
      setTrendingStreams([]);
      setDailyWatchedStreams([]);
    };
  }, [user]);

  useEffect(() => {
    if (liveStreams.length == 0) return;
    getDailyMostWatchedStreams();
  }, [liveStreams]);

  useEffect(() => {
    if (loadingDailyWatchedStreams || trendingStreams.length > 0) return;
    getTrendingStreams();
  }, [loadingDailyWatchedStreams]);

  const [activeSlot, setActiveSlot] = React.useState(2);
  const handleBecomeStream = () => {
    setActiveSlot(1);
    // setStreamerId(1);
  };
  const handleWatchStream = () => {
    setActiveSlot(2);
    // setStreamerId(2);
  };

  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 400,
    bgcolor: "#2a2a2b",
    border: "2px solid #000",
    boxShadow: 24,
    p: 4,
  };

  const StreamersBioLoading = () => (
    <div
      style={{ height: "300px", display: "flex", gap: "20px", width: "100vwv" }}
    >
      <SkeletonLoader />
      <SkeletonLoader />
      <SkeletonLoader />
    </div>
  );

  return (
    <div style={{ position: "relative" }}>
      <DashboardLayout showSidebar={false} noRightMargin={true} showFooter>
        <div className="home-main">
          <HeroHeading />
          <div className="home-top-heading">
            <HomeVideoCarousel
              liveStreams={liveStreams}
              id={CarouselIds.LIVE}
            />
          </div>
          {!loadingDailyWatchedStreams &&
          dailyWatchedStreams.length == 0 ? null : (
            <div className="home-top-heading-with-text">
              {loadingDailyWatchedStreams ? null : (
                <div className="games-header" style={{ marginBottom: "25px" }}>
                  <p>Most Watched Today</p>
                </div>
              )}

              <HomeVideoCarousel
                liveStreams={dailyWatchedStreams}
                id={CarouselIds.DAILY_MOST_WATCHED}
                autoplay={false}
              />
            </div>
          )}
          {loadingTrendingStreams ? null : (
            <div className="home-top-heading-with-text">
              <div className="games-header" style={{ marginBottom: "25px" }}>
                <p>Trending</p>
              </div>

              <HomeVideoCarousel
                liveStreams={trendingStreams}
                id={CarouselIds.TRENDING}
                autoplay={false}
              />
            </div>
          )}
          <div className="games-card">
            <Suspense fallback={<StreamersBioLoading />}>
              <div className="games-header">
                <p>Games</p>
                <p onClick={() => navigate("/games/seeAll")}>See all</p>
              </div>
              <GameCards />
            </Suspense>
          </div>
        </div>
      </DashboardLayout>
    </div>
  );
};
