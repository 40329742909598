import { useEffect, useState } from "react";
import { getAuthToken } from "../../functions/auth";

const useGetOpinions = (baseUrl, userId, setData) => {
  const [loading, setLoading] = useState(true);

  const getOpinions = async () => {
    setLoading(true);

    const res = await fetch(`${baseUrl}user/get-opinions`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: getAuthToken(),
      },
      body: JSON.stringify({ userId }),
    });
    const response = await res.json();
    if (response.success) {
      setData(response.data);
    }

    setLoading(false);
  };

  useEffect(() => {
    getOpinions();
  }, []);

  return { loading };
};

export default useGetOpinions;
