import React from "react";
import classes from "./inputText.module.css";

const InputWrapper = ({ label, id, error, rootStyles = {}, ...rest }) => {
  return (
    <main style={rootStyles} className={classes.container}>
      <label htmlFor={id || label}>{label}</label>
      <input id={id || label} type="text" {...rest} />
      {error && <p>{error}</p>}
    </main>
  );
};

export default InputWrapper;
