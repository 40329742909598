import { useState } from "react";

import { getRequestOptions } from "../../../functions/auth";
import { swalFormError, swalFormSuccess } from "../../../functions/swal";
import { useUserContext } from "../../../context/UserContext";

const useCreatePayoutLink = () => {
  const [loading, setLoading] = useState(false);
  const { servApiUrl } = useUserContext();

  const createPayoutLink = async (pgemQty) => {
    setLoading(true);

    const requestOptions = getRequestOptions("POST", true, {
      pgem: parseFloat(pgemQty),
    });

    try {
      const res = await fetch(
        `${servApiUrl}payment/create-payout-link`,
        requestOptions
      );
      const response = await res.json();
      if (response.success == true) {
        swalFormSuccess("Please check your email for the payment link");
      } else {
        swalFormError(response.message);
      }
    } catch (err) {
      swalFormError("Some error occurred");
    } finally {
      setLoading(false);
    }
  };

  return { createPayoutLink, loading };
};

export default useCreatePayoutLink;
