import { useState } from "react";

import { getAuthToken } from "../../../functions/auth";
import { swalFormError } from "../../../functions/swal";

const usePostSocialMediaComment = (baseUrl) => {
  const [loading, setLoading] = useState(false);

  const postSocialMediaComment = async (text, parent, userId, postId) => {
    setLoading(true);
    let result;

    const payload = { text, parent, userId, postId };

    try {
      const res = await fetch(`${baseUrl}user/comment-social-media-item`, {
        method: "POST",
        body: JSON.stringify(payload),
        headers: {
          "Content-Type": "application/json",
          Authorization: getAuthToken(),
        },
      });
      const response = await res.json();
      result = response;
      if (response.success != true) {
        swalFormError("Some error occurred");
      }
    } catch (err) {
      result.success = false;
      swalFormError("Some error occurred");
    } finally {
      setLoading(false);
    }

    return result;
  };

  return { postSocialMediaComment, loading };
};

export default usePostSocialMediaComment;
