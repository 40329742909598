import { useEffect, useState } from "react";
import { getAuthToken } from "../../functions/auth";

const useGetSocialMediaItems = (baseUrl) => {
  const ITEMS_PER_PAGE = 5;
  const [data, setData] = useState([]);

  const [loading, setLoading] = useState(false);
  const [currPage, setCurrPage] = useState(-1);
  const [pageCount, setPageCount] = useState(null);

  const [stopLoading, setStopLoading] = useState(false);

  const getSocialMediaItems = async () => {
    if (pageCount && currPage > pageCount) return;
    setLoading(true);

    const GET_SOCIAL_MEDIA_ITEMS = "noAuth/get-social-media-items";

    const payload = { currPage: currPage, itemsPerPage: ITEMS_PER_PAGE };

    try {
      const res = await fetch(`${baseUrl}${GET_SOCIAL_MEDIA_ITEMS}`, {
        method: "POST",
        body: JSON.stringify(payload),
        headers: {
          "Content-Type": "application/json",
          Authorization: getAuthToken(),
        },
      });
      const response = await res.json();
      if (response.success) {
        const dataArr = response.data;

        if (dataArr instanceof Array) {
          if (dataArr.length == 0) setStopLoading(true);
          setData([...data, ...dataArr]);
          setPageCount(response.pageCount);
        }
      }
    } catch (err) {
      console.error(err);
    } finally {
      setLoading(false);
    }
  };

  const increasePageAndGetItems = () => {
    if (stopLoading) return;

    setCurrPage((prev) => prev + 1);
  };

  useEffect(() => {
    if (currPage == -1) return;
    getSocialMediaItems();
  }, [currPage]);

  return {
    loading,
    data,
    setData,
    increasePageAndGetItems,
    stopLoading,
  };
};

export default useGetSocialMediaItems;
