import DownArrow from "@mui/icons-material/ArrowDownward";
import SwapIcon from "@mui/icons-material/SwapHoriz";
import { Button } from "@mui/material";
import { HTML5_FMT } from "moment";
import React, { useContext } from "react";
import logo from "../../images/logo.png";
import Dialog from "../../wrappers/Dialog";
import UserContext from "../../context/UserContext";

function SellNft({ nft }) {
  const { servApiUrl, setMessage } = useContext(UserContext);

  const sellNft = async (setOpen) => {
    const price = document.getElementById("sell-nft-price").value;
    if (!price) {
      setMessage({ message: "Please enter a valid price", type: "error" });
      return;
    }
    let requestOptions = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + localStorage.getItem("pg-auth-token"),
      },
      body: JSON.stringify({
        nftId: nft._id,
        price: price,
      }),
    };
    await fetch(`${servApiUrl}user/sellNft`, requestOptions)
      .then((response) => response.json())
      .then((data) => {
        if (!data.success) {
          setMessage({ message: data.message, type: "error" });
        } else {
          setMessage({ message: data.message, type: "success" });
          setOpen(false);
        }
      });
  };
  React.useEffect(() => {}, []);
  return (
    <Dialog
      actions={{
        successBtnProps: {
          title: "Sell NFT# " + nft?.name,
        },
      }}
      title={"Sell NFT# " + nft?.name}
      handler={{
        cancel: (setOpen) => {
          setOpen(false);
        },
        success: (setOpen) => {
          sellNft(setOpen);
        },
      }}
      Source={({ setOpen }) => (
        <Button
          onClick={setOpen.bind(this, true)}
          variant="contained"
          className="buttonSell"
        >
          SELL
        </Button>
      )}
    >
      <label
        htmlFor="sell-nft-price"
        style={{ margin: 0, color: "var(--text-color)" }}
      >
        Enter Price
      </label>
      <input
        type="number"
        id="sell-nft-price"
        style={{
          border: "1px solid grey",
          "margin-top": "10px",
          color: "var(--text-color-light)",
        }}
      />
    </Dialog>
  );
}

export default SellNft;
