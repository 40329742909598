import { useState } from "react";

import BottomDrawer from "./drawer";
import ExploreMenu from "../../../components/mobileAppBar/components/exploreMenu/exploreMenu";

const MobileDrawer = () => {
  const [openDrawer, setOpenDrawer] = useState(false);

  return (
    <BottomDrawer openDrawer={openDrawer} setOpenDrawer={setOpenDrawer}>
      <ExploreMenu setOpen={setOpenDrawer} />
    </BottomDrawer>
  );
};

export default MobileDrawer;
