import { useLocation } from "react-router-dom";

function useGetCurrentPage() {
  const location = useLocation();

  const getSearchParams = (id) => {
    const param = new URLSearchParams(location.search).get(id);
    return param;
  };

  return { page: location.pathname, getSearchParams };
}

export default useGetCurrentPage;
