import { useState } from "react";

import FavoriteBorderIcon from "@mui/icons-material/FavoriteBorder";
import FavoriteOutlinedIcon from "@mui/icons-material/FavoriteOutlined";
import DeleteOutlineOutlinedIcon from "@mui/icons-material/DeleteOutlineOutlined";

import styles from "./polyCommunityItem.module.css";
import useDeleteSocialPost from "../../../../hooks/api/mutators/useDeleteSocialPost";
import ConfirmDeleteDialog from "../../../../components/modals/confirmDeleteModal/confirmDeleteModal";
import CommentsSocialPost from "../commentsSocialPost/commentsSocialPost";
import useIsAuth from "../../../../hooks/auth/useIsAuth";
import { formatNumberToEnglish } from "../../../../functions/numberFormatter";

function PolyCommunityItem({
  type = "image",
  link = "https://i.ibb.co/HgPCwrz/general.jpg",
  likes = 0,
  postedBy,
  _id,
  likedUsers,
  user,
  likeUnlikeSocialMediaItem,
  description,
  getSocialMediaItems = null,
  servApiUrl,
  showDeleteIcon = false,
  commentsCount = 0,
  thumbnail,
}) {
  const { deleteSocialPost, loading } = useDeleteSocialPost(servApiUrl);
  const [openDeleteModal, setOpenDeleteModal] = useState(false);
  const [openCommentsModal, setOpenCommentsModal] = useState(false);
  const { checkAuthAndRedirect } = useIsAuth();

  const getIsLiked = (likedUsers) => {
    if (!user || typeof likedUsers[user._id] === "undefined") return false;

    return likedUsers[user._id];
  };

  const isLiked = getIsLiked(likedUsers);

  const getUserName = (user) => user?.username || user?.name;

  const handleDeleteSocialPost = async () => {
    if (loading) return;

    const response = await deleteSocialPost(_id);
    if (response == false) return;

    await getSocialMediaItems();
  };

  const handleCommentIconClick = () => {
    if (!checkAuthAndRedirect()) return;
    setOpenCommentsModal(true);
  };

  const header = (
    <div className={styles.header}>
      <div className={styles.user}>
        <img src={postedBy.avatar} />
        {getUserName(postedBy)}
      </div>
      {postedBy._id == user?._id && showDeleteIcon == true ? (
        <div onClick={() => setOpenDeleteModal(true)}>
          <DeleteOutlineOutlinedIcon />
        </div>
      ) : null}
    </div>
  );

  const imgVideo = (
    <div className={styles.img_container}>
      {type === "image" || type == "img" ? (
        <img src={link} alt="Community" />
      ) : (
        <video poster={thumbnail} src={link} controls preload="none" />
      )}
    </div>
  );

  const toolbar = (
    <div className={styles.toolbar}>
      <div
        className={styles.like}
        onClick={() => likeUnlikeSocialMediaItem(_id)}
      >
        {isLiked ? <FavoriteOutlinedIcon /> : <FavoriteBorderIcon />}
      </div>
      <a className={styles.comment} onClick={handleCommentIconClick}>
        <svg
          fill="#262626"
          height={24}
          role="img"
          viewBox="0 0 48 48"
          width={24}
        >
          <path
            clipRule="evenodd"
            d="M47.5 46.1l-2.8-11c1.8-3.3 2.8-7.1 2.8-11.1C47.5 11 37 .5 24 .5S.5 11 .5 24 11 47.5 24 47.5c4 0 7.8-1 11.1-2.8l11 2.8c.8.2 1.6-.6 1.4-1.4zm-3-22.1c0 4-1 7-2.6 10-.2.4-.3.9-.2 1.4l2.1 8.4-8.3-2.1c-.5-.1-1-.1-1.4.2-1.8 1-5.2 2.6-10 2.6-11.4 0-20.6-9.2-20.6-20.5S12.7 3.5 24 3.5 44.5 12.7 44.5 24z"
            fillRule="evenodd"
          />
        </svg>
      </a>
      <a className={styles.transfer}>
        <svg fill="#262626" height={24} viewBox="0 0 48 48" width={24}>
          <path d="M47.8 3.8c-.3-.5-.8-.8-1.3-.8h-45C.9 3.1.3 3.5.1 4S0 5.2.4 5.7l15.9 15.6 5.5 22.6c.1.6.6 1 1.2 1.1h.2c.5 0 1-.3 1.3-.7l23.2-39c.4-.4.4-1 .1-1.5zM5.2 6.1h35.5L18 18.7 5.2 6.1zm18.7 33.6l-4.4-18.4L42.4 8.6 23.9 39.7z" />
        </svg>
      </a>
    </div>
  );

  const descriptionContainer = (
    <>
      <p className={styles.likes_count}>{formatNumberToEnglish(likes)} likes</p>
      {commentsCount && commentsCount != 0 ? (
        <p onClick={handleCommentIconClick} className={styles.comments_count}>
          View all {commentsCount} comments
        </p>
      ) : null}
      <div className={styles.description}>
        <strong>{getUserName(postedBy)}&nbsp;&nbsp;</strong>
        {description}
      </div>
    </>
  );

  const section = (
    <div className={styles.section}>
      {header}
      {imgVideo}
      {toolbar}
      {descriptionContainer}
    </div>
  );

  return (
    <>
      {openDeleteModal ? (
        <ConfirmDeleteDialog
          open={openDeleteModal}
          setOpen={setOpenDeleteModal}
          onConfirm={handleDeleteSocialPost}
        />
      ) : null}
      {openCommentsModal ? (
        <CommentsSocialPost
          open={openCommentsModal}
          setOpen={setOpenCommentsModal}
          postId={_id}
          postedBy={postedBy}
        />
      ) : null}

      <div className={styles.main}>
        <div className={styles.container}>{section}</div>
      </div>
    </>
  );
}

export default PolyCommunityItem;
