import React from "react";
import "./Createnewnft.css";
import vectorimage from "../../images/icons/filters.svg";
// import priceimg from "../../images/icons/tagBlackIcon.png";
// import bidsimage from "../../images/icons/bidIcon.png";
// import timeimage from "../../images/icons/timerWhiteIcon.png";
// import Switch from "@mui/material/Switch";
// import Navbar from "../navbar/Navbar";
// import { SidebarCards } from "../sidebarCards/SidebarCards";
// import { Sidebar } from "../sidebar/Sidebar";
import Grid from "@mui/material/Grid";
import DashboardLayout from "../../wrappers/dashboard/index";
import ContentWrapper from "../../wrappers/default/components/content";
import "react-dropzone-uploader/dist/styles.css";

import Dropzone from "react-dropzone-uploader";

import UserContext from "../../context/UserContext";
import { swalFormError } from "../../functions/swal";

export default function Createnewnft() {
  const { servApiUrl, setMessage } = React.useContext(UserContext);

  let nftData = new FormData();

  // specify upload params and url for your files
  const getUploadParams = ({ meta }) => {
    return { url: "https://httpbin.org/post" };
  };

  // called every time a file's `status` changes
  const handleChangeStatus = ({ meta, file }, status) => {
    if (status == "done") {
      nftData.append("img_path", file);
    }
  };
  const handleChangeStatusObj = ({ meta, file }, status) => {
    if (status == "done") {
      nftData.append("file_path", file);
      // setMessage({ message: "File uploaded successfully", type: "success" });
    }
  };

  // receives array of files that are done uploading when submit button is clicked
  const handleSubmit = (files, allFiles) => {
    allFiles.forEach((f) => f.remove());
  };
  const handleSubmitObj = (files, allFiles) => {
    allFiles.forEach((f) => f.remove());
  };

  const createNft = async () => {
    const name = document.getElementById("create-nft-name").value;
    const about = document.getElementById("create-nft-about").value;
    const contractAddress = document
      .getElementById("create-nft-contract-address")
      .value.split(",");
    const tags = document.getElementById("create-nft-tags").value;

    if (
      !name ||
      name.length === 0 ||
      !about ||
      about.length === 0 ||
      !contractAddress ||
      contractAddress.length === 0 ||
      !tags ||
      tags.length === 0
    ) {
      swalFormError("Please fill all fields", true);
      return;
    }

    nftData.append("name", name);
    nftData.append("about", about);
    nftData.append("contract_address", contractAddress);

    switch (nftType) {
      case ".obj":
        nftData.append("nftType", "obj");
        break;
      case "video/*,audio/*":
        nftData.append("nftType", "video");
        break;
      case "image/*":
        nftData.append("nftType", "gif");
        break;
      default:
        break;
    }

    nftData.append("tags", tags);
    let requestOptions = {
      method: "POST",
      headers: {
        Authorization: "Bearer " + localStorage.getItem("pg-auth-token"),
      },
      body: nftData,
    };
    await fetch(servApiUrl + "/user/createNft", requestOptions)
      .then((response) => response.json())
      .then((data) => {
        if (!data.success) {
          setMessage({ message: data.message, type: "error" });
        } else {
          setMessage({ message: data.message, type: "success" });
        }
      });
  };

  const [nftType, setNftType] = React.useState("video/*");
  const changeNftType = () => {
    setNftType(document.getElementById("create-nft-type").value);
  };
  const label = { inputProps: { "aria-label": "Switch demo" } };
  return (
    <DashboardLayout>
      <div style={{ position: "relative", width: "100%", padding: "0px 20px" }}>
        <ContentWrapper
          breadCrumbs={[
            {
              title: "Create New NFT",
              image: <img src={vectorimage} />,
            },
          ]}
        >
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              width: "100%",
              flexDirection: "column",
            }}
          >
            <div style={{ marginTop: "10px" }}>
              <label htmlFor="">
                NFT Type
                <select
                  className="formbg"
                  placeholder="Payoneer"
                  id="create-nft-type"
                  onChange={changeNftType}
                >
                  <option value="video/*,audio/*" selected>
                    Video(.mp4)
                  </option>
                  <option value=".obj">3D File(.obj)</option>
                  <option value="image/*">Image(.jpeg,.png,.gif)</option>
                </select>
              </label>
            </div>
            <p className="uploadfiletitle">Upload Object File</p>
            <Dropzone
              // getUploadParams={getUploadParams}
              onChangeStatus={handleChangeStatusObj}
              onSubmit={handleSubmitObj}
              accept={nftType}
              maxFiles={1}
              multiple={false}
              canCancel={true}
              inputContent="Drop A File"
              classNames="image-selector"
            />
            <p className="uploadfiletitle">Upload Image File</p>
            <Dropzone
              // getUploadParams={getUploadParams}
              onChangeStatus={handleChangeStatus}
              onSubmit={handleSubmit}
              accept="image/*,audio/*,video/*,"
              maxFiles={1}
              multiple={false}
              canCancel={true}
              inputContent="Drop A File"
              classNames="image-selector"
            />

            <div className="sectionrowflex">
              <div
                className="sectiondivsize formpadding"
                style={{ "margin-top": "30px" }}
              >
                <div>
                  <label htmlFor="">
                    Name
                    <input
                      type="text"
                      className="formbg"
                      placeholder="Payoneer"
                      id="create-nft-name"
                    />
                  </label>
                </div>
                <div className="labelmargin">
                  <label htmlFor="" className="">
                    Description
                    <textarea
                      name=""
                      cols="30"
                      rows="15"
                      placeholder="Payoneer"
                      className="formbg"
                      id="create-nft-about"
                    ></textarea>
                  </label>
                </div>
                <div>
                  <label htmlFor="">
                    Contract Address
                    <input
                      type="text"
                      className="formbg"
                      placeholder="Payoneer"
                      id="create-nft-contract-address"
                    />
                  </label>
                </div>
                <div>
                  <label htmlFor="">
                    Tags (Comma-Seperated)
                    <input
                      type="text"
                      className="formbg"
                      placeholder="Payoneer"
                      id="create-nft-tags"
                    />
                  </label>
                </div>
                <div className="createnftbtnrow justifybuttons">
                  <button className="cancelbutton">CANCEL</button>
                  <button className="createnftbutton" onClick={createNft}>
                    CREATE NFT
                  </button>
                </div>
              </div>
            </div>
          </div>
        </ContentWrapper>
      </div>
    </DashboardLayout>
  );
}
