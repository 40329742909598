import { swalFormError, swalFormSuccess } from "../../../functions/swal";
import { useUserContext } from "../../../context/UserContext";
import { useNavigate } from "react-router-dom";
import { PAGES } from "../../../data/pages";
import { useRef } from "react";

import UserService from "../../../services/UserService";
import { saveAuthToken } from "../../../functions/auth";
import { DISCORD_PLATFORM } from "../../../data/discord";

const useDiscordLogin = () => {
  const { setLoading, servApiUrl, setUser } = useUserContext();
  const ranOneTimeRef = useRef(false);
  const navigate = useNavigate();

  const discordLogin = async (token) => {
    if (ranOneTimeRef.current == true) return;

    ranOneTimeRef.current = true;
    setLoading(true);

    const payload = { code: token, platform: DISCORD_PLATFORM };

    try {
      const res = await fetch(`${servApiUrl}discord`, {
        method: "POST",
        body: JSON.stringify(payload),
        headers: {
          "Content-Type": "application/json",
        },
      });
      const response = await res.json();

      saveAuthToken(response.token);
      const user = await UserService.getUser(servApiUrl);
      swalFormSuccess("Welcome aboard");

      if (user) {
        setUser(user);
      }

      if (response.success != true) {
        swalFormError("Some error occurred");
      }
    } catch (err) {
      swalFormError("Some error occurred");
    } finally {
      navigate(PAGES.HOME);
      setLoading(false);
    }
  };

  return { discordLogin };
};

export default useDiscordLogin;
