import { useNavigate } from "react-router-dom";
import { IMAGES } from "../../data/images";
import DashboardLayout from "../../wrappers/dashboard";

import classes from "./PolyVerse.module.css";
import { PAGES } from "../../data/pages";
import { useMemo, useState } from "react";
import DownloadAppModal from "./components/DownloadAppModal/DownloadAppModal";

const PolyVerse = () => {
  const navigate = useNavigate();
  const [openDownloadAppModal, setOpenDownloadAppModal] = useState(false);

  const openAvatarsPage = () => {
    navigate(PAGES.AVATAR);
  };

  const openAppModal = () => {
    setOpenDownloadAppModal(true);
  };

  const PageContent = useMemo(
    () => (
      <div className={classes.container}>
        <div className={classes.gaming_container}>
          <img src={IMAGES.GAMING_CHARACTER} alt="Gaming" />
          <div className={classes.avatar_button_container}>
            <button className={classes.button} onClick={openAvatarsPage}>
              Create Gaming Avatars
            </button>
          </div>
        </div>
        <div className={classes.gif_container}>
          <img src={IMAGES.GENSHIN_GIF} alt="Genshin" />
          <div className={classes.avatar_button_container}>
            <button className={classes.button} onClick={openAppModal}>
              Create Gaming Videos
            </button>
          </div>
        </div>
      </div>
    ),
    []
  );

  return (
    <DashboardLayout>
      {openDownloadAppModal ? (
        <DownloadAppModal setOpen={setOpenDownloadAppModal} />
      ) : null}
      {PageContent}
    </DashboardLayout>
  );
};

export default PolyVerse;
