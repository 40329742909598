import styles from "./calculationsPopup.module.css";

import AppModal from "../../../modals/appModal/appModal";
import CloseIcon from "@mui/icons-material/Close";
import moment from "moment";

const CalculationsPopup = ({ open, setOpen }) => {
  const informationTable = (
    <table className={styles.table}>
      <thead>
        <tr>
          <th>Min / day</th>
          <th>Earnings</th>
          <th>Bonus</th>
        </tr>
      </thead>
      <tbody>
        <tr>
          <td>0 - 30 mins</td>
          <td>2 IDR / min</td>
          <td>4.5 IDR on completion of 30 Mins</td>
        </tr>
        <tr>
          <td>30 - 60 mins</td>
          <td>2.5 IDR / min</td>
          <td>7 IDR on completion of 60 Mins</td>
        </tr>
        <tr>
          <td>60 - 120 mins</td>
          <td>3.2 IDR / min</td>
          <td>12 IDR on completion of 120 Mins</td>
        </tr>
        <tr>
          <td>120 - 250 mins</td>
          <td>4 IDR / min</td>
          <td>After completion of 120 minutes 5 IDR every 30 mins</td>
        </tr>
        <tr>
          <td>250+ mins</td>
          <td>5.2 IDR / min</td>
          <td>As previously mentioned</td>
        </tr>
      </tbody>
    </table>
  );

  const crossContainer = (
    <div onClick={() => setOpen(false)}>
      <CloseIcon />
    </div>
  );

  if (!open) return null;

  return (
    <AppModal setOpen={setOpen}>
      <div className={styles.container}>
        {crossContainer}
        <div>
          <p>
            <strong>&#8226;</strong> These prices are updated as of{" "}
            {moment(new Date()).subtract(1, "days").format("DD MMM")} 23:59:59
          </p>
          <p>
            <strong>&#8226;</strong> The IDR prices may fluctuate according to
            the live prices on the exchange
          </p>
        </div>
        {informationTable}
      </div>
    </AppModal>
  );
};

export default CalculationsPopup;
