import React from "react";
// import './index.css';
import Grid from "@mui/material/Grid";
import { SidebarCards } from "../../components/sidebarCards/SidebarCards";
import Box from "@mui/material/Box";
// import ReactPlayer from "react-player/lazy";
import Button from "@mui/material/Button";
import FiberManualRecordIcon from "@mui/icons-material/FiberManualRecord";
import likeIcon from "../../images/icons/likeIcon.png";
import chatIcon from "../../images/icons/ChatIcon.png";
import shareIcon from "../../images/icons/share.png";
import avatarImg from "../../images/nft-avatar.png";
import Stack from "@mui/material/Stack";
import Avatar from "@mui/material/Avatar";
import Navbar from "../../components/navbar/Navbar";
import { Sidebar } from "../../components/sidebar/Sidebar";
import DashboardLayout from "../../wrappers/dashboard/index";
import ContentWrapper from "../../wrappers/default/components/content";

export const StartStream = () => {
  return (
    <DashboardLayout>
      <div style={{ position: "relative", width: "100%", padding: "0px 20px" }}>
        <ContentWrapper
          breadCrumbs={[
            {
              title: "Start Stream",
            },
          ]}
        >
          <div
            style={{ boxSizing: "border-box" }}
            className="streamer-profile-main"
          >
            <Grid container spacing={0}>
              <Grid item xs={12} lg={9.9} md={8}>
                <div className="video-streaming">
                  {/* <ReactPlayer
                    url="https://www.youtube.com/watch?v=4JEW0q1JuwM"
                    width="95%"
                    height="500px"
                    className="videoPlayer"
                  /> */}
                </div>
                <p className="video-title">
                  Lorem Ipsum is simply dummy text of the printing and
                  typesetting industry. Lorem Ipsum has been the industry's
                </p>
                <div className="Streaaming-views">
                  <p>
                    125,908 views{" "}
                    <span>
                      <FiberManualRecordIcon className="dot-icon" />
                    </span>{" "}
                    <span>2h 26min</span>
                  </p>
                  <div className="stream-status-btn">
                    <Button variant="contained">Fortnite</Button>
                    <Button variant="contained">Live</Button>
                  </div>
                </div>
                <Box sx={{ mt: -2 }} />
                <div className="likeAndComment">
                  <p>
                    <span>
                      <img alt="comment icon" src={likeIcon} />
                    </span>
                    344
                  </p>
                  <p>
                    <span>
                      <img alt="likes icon" src={chatIcon} />
                    </span>
                    34
                  </p>
                  <img alt="likes icon" src={shareIcon} />
                </div>
                <div className="streamer-profile">
                  <Stack direction="row" spacing={2}>
                    <Avatar
                      alt="Avatar"
                      src={avatarImg}
                      variant="rounded"
                      className="streamer-avatar"
                    />
                    <div className="streamer-avatar-detail">
                      <p>Drax345</p>
                      <p>draxdeman666@gmail.com</p>
                    </div>
                  </Stack>
                  <div className="follow-btn">
                    <Button variant="contained">Follow</Button>
                  </div>
                </div>
                <Box sx={{ mt: 3, mr: 5 }} className="streamer-box"></Box>
              </Grid>
              <Grid item xs={12} lg={2.1} md={4}>
                <div>
                  <SidebarCards />
                </div>
              </Grid>
            </Grid>
          </div>
        </ContentWrapper>
      </div>
    </DashboardLayout>
  );
};
