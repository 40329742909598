import { useState } from "react";

import { getAuthToken } from "../../../functions/auth";
import { swalFormError, swalFormSuccess } from "../../../functions/swal";

const useDeleteSocialPost = (baseUrl) => {
  const [loading, setLoading] = useState(false);

  const deleteSocialPost = async (id) => {
    if (!id || typeof id != "string" || id.length == 0) return;
    setLoading(true);

    let result = false;
    try {
      const res = await fetch(`${baseUrl}user/delete-social-media-item/${id}`, {
        method: "DELETE",
        headers: {
          Authorization: getAuthToken(),
        },
      });
      const response = await res.json();
      if (response.success == true) {
        swalFormSuccess("Post deleted successfully!");
        result = true;
      } else {
        swalFormError("Some error occurred");
      }
    } catch (err) {
      swalFormError("Some error occurred");
    } finally {
      setLoading(false);
    }

    return result;
  };

  return { deleteSocialPost, loading };
};

export default useDeleteSocialPost;
