import React, { isValidElement } from "react";
import classes from "./content.module.css";
import { useNavigate } from "react-router-dom";
import ArrowRightIcon from "@mui/icons-material/ArrowRight";

const Content = ({ breadCrumbs, children, sideIcon: Component }) => {
  const navigate = useNavigate();

  return (
    <main className={classes.container}>
      <section className={classes.path}>
        <div className={classes.path_child}>
          {breadCrumbs.map(({ title, image, path }, idx) => (
            <>
              {idx !== 0 && (
                <ArrowRightIcon
                  sx={{
                    fontSize: "30px",
                    color: "var(--primary-color)",
                  }}
                />
              )}
              <div
                onClick={() => (path ? navigate(path) : null)}
                className={classes.breadCrumb}
              >
                {image}
                <h3>{title}</h3>
              </div>
            </>
          ))}
        </div>
        {Component ? (
          !isValidElement(Component) ? (
            <Component />
          ) : (
            Component
          )
        ) : null}
      </section>
      <section className={classes.data}>{children}</section>
    </main>
  );
};

export default Content;
