import React from "react";
import { Sidebar } from "../../components/sidebar/Sidebar";
import { SidebarCards } from "../../components/sidebarCards/SidebarCards";
import classes from "./dashboard.module.css";
import Navbar from "../../components/navbar/Navbar";
import MobileAppBar from "../../components/mobileAppBar/mobileAppBar";
import { isMobile } from "react-device-detect";

const DashboardLayout = ({
  children,
  RightSidebar,
  showSidebar = true,
  noRightMargin = false,
  showLeftSideBar = true,
  noPaddingBottom = false,
  showFooter = false,
}) => {
  const [mobileOpen, setMobileOpen] = React.useState(false);
  const props = {};
  if (noPaddingBottom === true) {
    props.paddingBottom = 0;
  }

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };
  return (
    <main className={classes.container}>
      <Navbar mobileOpen={mobileOpen} handleDrawerToggle={handleDrawerToggle} />
      {isMobile ? <MobileAppBar /> : null}
      <section>
        {showLeftSideBar ? (
          <Sidebar
            mobileOpen={mobileOpen}
            handleDrawerToggle={handleDrawerToggle}
          />
        ) : null}

        <div
          style={{
            marginRight:
              window.location.pathname !== "/leaderboard" || noRightMargin
                ? 0
                : "",
            ...props,
          }}
          className={classes.content}
        >
          {children}
          {showFooter ? (
            <div className={classes.footer_container}>
              <div className={classes.footer_inner_container}>
                <p>Polygame PTE. Limited</p>
                <p>32, Pekin Street #05-01 Singapore-048762</p>
              </div>
            </div>
          ) : null}
        </div>
        {showSidebar === true
          ? window.location.pathname === "/leaderboard" && (
              <>{RightSidebar ? <RightSidebar /> : <SidebarCards />}</>
            )
          : null}
      </section>
    </main>
  );
};

export default DashboardLayout;
