import { useState } from "react";
import { getAuthToken } from "../../functions/auth";

const useGetSocialMediaItemReplyComments = (baseUrl) => {
  const [loading, setLoading] = useState(false);

  const getSocialMediaItemReplyComments = async (parent) => {
    setLoading(true);

    const payload = { parent };
    let result = {};

    const GET_USER_SOCIAL_MEDIA_ITEM_COMMENTS =
      "user/get-social-media-item-reply-comments";

    try {
      const res = await fetch(
        `${baseUrl}${GET_USER_SOCIAL_MEDIA_ITEM_COMMENTS}`,
        {
          method: "POST",
          body: JSON.stringify(payload),
          headers: {
            "Content-Type": "application/json",
            Authorization: getAuthToken(),
          },
        }
      );
      const response = await res.json();
      result = { ...response };
    } catch (err) {
      console.warn(err);
      result.success = false;
    } finally {
      setLoading(false);
    }

    return result;
  };

  return { loading, getSocialMediaItemReplyComments };
};

export default useGetSocialMediaItemReplyComments;
