import { useEffect, useMemo, useRef, useState } from "react";
import AppModal from "../../../../components/modals/appModal/appModal";
import styles from "./commentsSocialPost.module.css";
import { useUserContext } from "../../../../context/UserContext";
import usePostSocialMediaComment from "../../../../hooks/api/mutators/usePostSocialMediaComment";
import useGetSocialMediaItemComments from "../../../../hooks/api/useGetSocialMediaItemComments";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";

import CloseIcon from "@mui/icons-material/Close";
import moment from "moment";
import useGetSocialMediaItemReplyComments from "../../../../hooks/api/useGetSocialMediaItemReplyComments";
import { isDesktop } from "react-device-detect";
import Close from "@mui/icons-material/Close";
import { getDefaultUserAvatar } from "../../../../functions/default";

const CommentsSocialPost = ({ open, setOpen, postId, postedBy }) => {
  const inputRef = useRef(null);

  const [commentText, setCommentText] = useState("");
  const [parent, setParent] = useState(null);
  const [parentName, setParentName] = useState(null);
  const [replyCommentsState, setReplyCommentsState] = useState({});

  const { servApiUrl, user } = useUserContext();

  const { postSocialMediaComment } = usePostSocialMediaComment(servApiUrl);

  const {
    data: comments,
    setData: setComments,
    increasePageAndGetItems,
    hideLoadMoreButton,
    loading,
  } = useGetSocialMediaItemComments(servApiUrl, postId);
  const { getSocialMediaItemReplyComments } =
    useGetSocialMediaItemReplyComments(servApiUrl);

  useEffect(() => {
    increasePageAndGetItems();
  }, []);

  const getUserName = (user) => user?.username || user?.name;

  const crossContainer = (
    <div className={styles.cross_container}>
      <div className={styles.posted_by_container}>
        <img src={postedBy?.avatar} alt="Avatar" />
        {getUserName(postedBy)}
      </div>
      <div onClick={() => setOpen(false)}>
        <CloseIcon />
      </div>
    </div>
  );

  const replyToComment = (name, id) => {
    setParent(id);
    setCommentText("@" + name + " ");
    setParentName(name);
    inputRef.current.focus();
  };

  const resetCommentState = () => {
    setParent(null);
    setCommentText("");
    setParentName(null);
  };

  const updateReplyCommentsState = (parent, newComment) => {
    const commentsStateClone = { ...replyCommentsState };
    commentsStateClone[parent] = newComment;
    setReplyCommentsState(commentsStateClone);
  };

  const getParentComment = (id) =>
    comments.filter((comment) => comment._id == id)[0];

  const getReplyComments = async (parent) => {
    const comment = replyCommentsState[parent];
    const parentComment = getParentComment(parent);

    // In case comment object already exists
    // Also check if replyCount for parent is not greater than replyState comments
    if (comment && comment.comments?.length == parentComment.replyCount) {
      // If comment is already opened hide it
      if (comment.view == true) {
        updateReplyCommentsState(parent, {
          view: false,
          comments: comment.comments,
        });
      }
      // In case it is hidden and we have already got comments from api
      // Then return comments from state don't hit api
      else {
        updateReplyCommentsState(parent, {
          view: true,
          comments: comment.comments,
        });
      }
      return;
    }

    const result = await getSocialMediaItemReplyComments(parent);
    if (result.success) {
      updateReplyCommentsState(parent, { view: true, comments: result.data });
    }
  };

  const viewOrHideReplies = (id) => {
    const comment = replyCommentsState[id];

    if (!comment) return false;

    return comment["view"];
  };

  const getReplyStateComments = (id) => {
    const comment = replyCommentsState[id];

    if (!comment) return [];

    return comment["comments"];
  };

  const ReplyComment = ({ createdAt, text, name, avatar, id }) => (
    <div className={styles.comment_container}>
      <img src={getDefaultUserAvatar(avatar)} alt="Avatar" />
      <div className={styles.comment_description_container}>
        <div>
          <strong>{name}</strong>
          <p>{text}</p>
        </div>
        <div className={styles.comment_info_container}>
          <p>{moment(createdAt).fromNow(true)}</p>
        </div>
      </div>
    </div>
  );

  const ReplyComments = ({ id }) => (
    <div className={styles.reply_comments_container}>
      {getReplyStateComments(id)
        .reverse()
        .map((comment, index) => (
          <ReplyComment
            key={`reply-comment-${index}`}
            createdAt={comment.createdAt}
            text={comment.text}
            name={getUserName(comment.user)}
            avatar={comment.user?.avatar}
            id={comment._id}
          />
        ))}
    </div>
  );

  const CommentItem = ({ createdAt, text, name, avatar, id, replyCount }) => (
    <div className={styles.comment_container}>
      <img src={getDefaultUserAvatar(avatar)} alt="Avatar" />
      <div className={styles.comment_description_container}>
        <div>
          <strong>{name}</strong>
          <p>{text}</p>
        </div>
        <div className={styles.comment_info_container}>
          <p>{moment(createdAt).fromNow(true)}</p>
          <p onClick={() => replyToComment(name, id)}>Reply</p>
          {replyCount > 0 ? (
            <p onClick={() => getReplyComments(id)}>
              {viewOrHideReplies(id) ? "Hide replies" : "View replies"} (
              {replyCount})
            </p>
          ) : null}
        </div>
        {replyCount > 0 && viewOrHideReplies(id) ? (
          <ReplyComments id={id} />
        ) : null}
      </div>
    </div>
  );

  const NoCommentsContainer = () => (
    <div className={styles.no_comments_container}>
      <p className={styles.no_comments_header}>No Comments</p>
      <p className={styles.no_comments_text}>Start the conversation!</p>
    </div>
  );

  const commentsContainer = useMemo(
    () => (
      <div className={styles.comments_container}>
        {comments.length == 0 ? NoCommentsContainer() : null}
        {comments.length != 0
          ? comments.map((comment, key) => (
              <CommentItem
                key={`comment-social-media-post-${key}`}
                createdAt={comment.createdAt}
                text={comment.text}
                name={getUserName(comment.user)}
                avatar={comment.user?.avatar}
                id={comment._id}
                replyCount={comment.replyCount}
              />
            ))
          : null}
        {comments.length == 0 && (hideLoadMoreButton || loading) ? null : (
          <div
            className={styles.load_comments_container}
            onClick={increasePageAndGetItems}
          >
            <AddCircleOutlineIcon />
          </div>
        )}
      </div>
    ),
    [comments, hideLoadMoreButton, loading, replyCommentsState]
  );

  const updateCommentsState = (comment) => {
    // Simply add to comments state
    if (comment.parent == null) {
      const commentsClone = [...comments];
      commentsClone.unshift(comment);
      setComments(commentsClone);
    }

    // Increase parent replyCount by 1
    else {
      const commentsClone = [...comments];
      for (let i = 0; i < commentsClone.length; i++) {
        const commentCur = commentsClone[i];
        if (commentCur._id == comment.parent) {
          commentCur.replyCount += 1;
          break;
        }
      }
      setComments(commentsClone);
    }
  };

  const postCommentApi = async () => {
    if (commentText.trim().length == 0) return;
    const result = await postSocialMediaComment(
      commentText,
      parent,
      user?._id,
      postId
    );
    resetCommentState();

    if (result.success) {
      updateCommentsState(result.comment);
    }
  };

  const replyCommentContainer = useMemo(
    () => (
      <div className={styles.reply_comment_container}>
        <div>
          <p>
            Replying to <strong>{parentName}</strong>
          </p>
          <div
            className={styles.close_icon_container}
            onClick={resetCommentState}
          >
            <Close />
          </div>
        </div>
      </div>
    ),
    [parentName]
  );

  const postComment = useMemo(
    () => (
      <div className={styles.post_comment_container}>
        {parentName ? replyCommentContainer : null}
        <div className={styles.post_comment_input_container}>
          <input
            type="text"
            autoFocus={isDesktop}
            placeholder="Add a comment"
            value={commentText}
            onChange={(e) => setCommentText(e.target.value)}
            onKeyUp={(e) => {
              if (e.key === "Enter") {
                e.preventDefault();
                postCommentApi();
              }
            }}
            ref={inputRef}
          />
          <button
            className={`${styles.post_button} ${
              commentText.trim().length > 0
                ? `${styles.post_button_active}`
                : ""
            }`}
            onClick={postCommentApi}
          >
            Post
          </button>
        </div>
      </div>
    ),
    [commentText, parent, parentName]
  );

  if (!open) return null;

  return (
    <AppModal setOpen={setOpen}>
      <div className={styles.container}>
        {crossContainer}
        {commentsContainer}
        {postComment}
      </div>
    </AppModal>
  );
};

export default CommentsSocialPost;
