import React, { useState } from "react";
import { getAuthToken } from "../../../../functions/auth";
import Swal from "sweetalert2";

import "./feedback.css";

function Feedback({ setOpen = null, servApiUrl, user }) {
  const [text, setText] = useState("");
  const [loading, setLoading] = useState(false);
  const [errorMsg, setErrorMsg] = useState("");

  const submitFeedback = async () => {
    if (text === "") {
      setErrorMsg("Feedback text cannot be empty!");
      return;
    }

    setLoading(true);
    setErrorMsg("");

    try {
      await fetch(`${servApiUrl}user/submit-feedback`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: getAuthToken(),
        },
        body: JSON.stringify({ text, userId: user._id }),
      });
      if (setOpen) setOpen(null);
      setText("");
      Swal.fire({
        icon: "success",
        title: "Success",
        text: "Your feedback has been recorded",
        position: "center",
        showConfirmButton: false,
        timer: 1500,
      });
    } catch (err) {
      setErrorMsg(err.message);
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="feedback-container">
      <div>
        <h3>Enter your valuable feedback</h3>
        <textarea
          value={text}
          onChange={(e) => setText(e.target.value)}
          placeholder="Start typing here..."
        />
        {errorMsg.length > 0 ? (
          <p className="feedback-error-msg">{errorMsg}</p>
        ) : null}
        <div>
          <button
            onClick={() => submitFeedback()}
            disabled={loading || text.trim().length == 0}
          >
            Submit
          </button>
        </div>
      </div>
    </div>
  );
}

export default Feedback;
