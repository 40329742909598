import { useNavigate } from "react-router-dom";
import { useUserContext } from "../../../context/UserContext";
import InputWrapper from "../../../wrappers/InputText";
import { PAGES } from "../../../data/pages";

const LinkSharing = ({ liveDetails, streamerId, stream, getStream }) => {
  const { setMessage, servApiUrl, user } = useUserContext();
  const navigate = useNavigate();

  const endStream = () => {
    let requestOptions = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + localStorage.getItem("pg-auth-token"),
      },
      body: JSON.stringify({ _id: stream._id }),
    };
    fetch(servApiUrl + "session/end-stream", requestOptions)
      .then((response) => {
        if (response.status === 200) return response.json();
        else throw response;
      })
      .then((data) => {
        if (data.success) {
          setMessage({ message: data.message, type: "success" });
          navigate(PAGES.HOME);
        }
      })
      .catch(() => {});
  };

  return (
    streamerId === user?._id && (
      <div
        style={{
          width: "100%",
          display: "flex",
          gap: "10px",
          flexWrap: "wrap",
          alignItems: "flex-end",
        }}
      >
        {["key", "url"].map((v, index) => (
          <InputWrapper
            key={`input-wrapper-${index}`}
            label={v}
            value={liveDetails[v]}
            style={{ minHeight: "40px", color: "grey" }}
            rootStyles={{
              width: "33.33%",
              minWidth: "260px",
              flex: 1,
              maxWidth: "100%",
            }}
          />
        ))}
        {/* <div
          style={{
            flex: 1,
            minHeight: "45px",
            width: "33.33%",
            maxHeight: "40px",
            border: "1px var(--primary-color) solid",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            color: "var(--primary-color)",
            borderRadius: "8px",
            fontSize: "0.8rem",
            boxSizing: "border-box",
            minWidth: "260px",
          }}
        >
          Take a break
        </div> */}
        <div
          style={{
            flex: 1,
            minHeight: "45px",
            maxHeight: "40px",
            border: "1px var(--primary-color) solid",
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            borderRadius: "8px",
            fontSize: "0.8rem",
            boxSizing: "border-box",
            minWidth: "260px",
            color: "grey",
            overflow: "hidden",
          }}
        >
          <p style={{ paddingLeft: "10px" }}>{liveDetails.playback}</p>
        </div>
        <div
          onClick={() => {
            endStream();
          }}
          style={{
            flex: 1,
            minHeight: "45px",
            width: "33.33%",
            maxHeight: "40px",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            color: "white",
            borderRadius: "8px",
            fontSize: "0.8rem",
            boxSizing: "border-box",
            background: "#EB5757",
            minWidth: "260px",
            cursor: "pointer",
          }}
        >
          End Stream
        </div>
      </div>
    )
  );
};

export default LinkSharing;
