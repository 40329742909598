import xTwitterIcon from "../../images/icons/x-twitter.svg";
import tiktokIcon from "../../images/icons/tiktok.svg";

export const SOCIAL_MEDIA_SHARE_ITEMS = [
  {
    iconClass: "fa-brands fa-facebook",
    iconColor: "#0866ff",
    link: "https://www.facebook.com/polygame2020",
  },
  {
    iconUrl: xTwitterIcon,
    link: "https://twitter.com/PolygameNFT?t=F4Z3D76P7GmYF4ySSQPMVg&s=09",
  },
  {
    iconClass: "fa-brands fa-discord",
    iconColor: "#404eed",
    link: "https://discord.gg/tpj3NZdb",
  },
  {
    iconClass: "fa-brands fa-instagram",
    iconColor: "#ff0000",
    link: "https://www.instagram.com/polygame.streams?igsh=MTF0ZDUyb3U0YTRjOQ==",
  },
  {
    iconClass: "fa-brands fa-reddit",
    iconColor: "#ff4500",
    link: "https://www.reddit.com/r/Polygame/s/YsxicAFSdU",
  },
  {
    iconUrl: tiktokIcon,
  },
];
