import React, { useEffect } from "react";
import { Navigate, Route, Routes } from "react-router-dom";

import ChatBox from "./components/ChatandMessageBox/ChatBox";
import { GlobalProvider } from "./context/UserContext";
import NotificationService from "./wrappers/Notifications";
import ProtectedRoute from "./wrappers/routes/ProtectedRoute";
import StreamerRoute from "./wrappers/routes/StreamerRoute";
import UnprotectedRoute from "./wrappers/routes/UnprotectedRoute";
import { App as CapacitorApp } from "@capacitor/app";

import ReactGA from "react-ga";
import { UN_PROTECTED_ROUTES } from "./data/routes/unprotectedRoutes";
import { COMMON_ROUTES } from "./data/routes/commonRoutes";
import { PROTECTED_ROUTES } from "./data/routes/protectedRoutes";
import { STREAMER_ROUTES } from "./data/routes/streamerRoutes";
import { Suspense } from "react";
import { BoxLoading } from "react-loadingg";

import "./App.css";
import "./components/all-nft/index.css";
import { PAGES } from "./data/pages";

const TRACKING_ID = "UA-247683142-1";
ReactGA.initialize(TRACKING_ID);

CapacitorApp.addListener("backButton", ({ canGoBack }) => {
  if (!canGoBack) {
    CapacitorApp.exitApp();
  } else {
    window.history.back();
  }
});
function App() {
  document.addEventListener("ionBackButton", (ev) => {
    ev.detail.register(10, () => {
      console.log("Handler was called!");
    });
  });

  useEffect(() => {
    ReactGA.pageview(window.location.pathname);
  }, []);

  const getRouteElement = (route) =>
    route.lazy == true ? (
      <Suspense fallback={<BoxLoading />}>{route.element}</Suspense>
    ) : (
      route.element
    );

  return (
    <GlobalProvider>
      <NotificationService />
      <ChatBox />
      <Routes>
        {COMMON_ROUTES.map((route, index) => (
          <Route
            key={`common-route-${index}`}
            path={route.path}
            element={getRouteElement(route)}
          />
        ))}

        <Route element={<StreamerRoute />}>
          {STREAMER_ROUTES.map((route, index) => (
            <Route
              key={`streamer-route-${index}`}
              path={route.path}
              element={getRouteElement(route)}
            />
          ))}
        </Route>

        <Route element={<UnprotectedRoute />}>
          {UN_PROTECTED_ROUTES.map((route, index) => (
            <Route
              key={`unproteced-route-${index}`}
              path={route.path}
              element={getRouteElement(route)}
            />
          ))}
        </Route>

        <Route element={<ProtectedRoute />}>
          {PROTECTED_ROUTES.map((route, index) => (
            <Route
              key={`proteced-route-${index}`}
              path={route.path}
              element={getRouteElement(route)}
            />
          ))}
          {/* {isMobile ? (
            <Route path="/discover" element={<DiscoverPage />} />
          ) : null} */}
        </Route>
        <Route path="*" element={<Navigate to={PAGES.HOME} />} />
      </Routes>
    </GlobalProvider>
  );
}

export default App;
