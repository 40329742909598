import React, { useContext } from "react";
import UserContext from "../../context/UserContext";
import GoogleImg from "../../images/Google.png";

const GoogleSignIn = ({ handleLogin = null }) => {
  const { googleSignIn } = useContext(UserContext);
  return (
    <img
      onClick={() => {
        handleLogin ? handleLogin("google", googleSignIn) : googleSignIn();
      }}
      src={GoogleImg}
      alt="google sign in"
    />
  );
};

export default GoogleSignIn;
