import React, { useContext, useEffect, useState, useMemo } from "react";
import { useLocation, useNavigate } from "react-router-dom";
// import FollowUnfollow from "../../components/buttons/Follow-Unfollow";
import ShareDialog from "../../components/dialogs/ShareDialog";
import UserContext from "../../context/UserContext";
import DashboardLayout from "../../wrappers/dashboard/index";
import classes from "./final.module.css";
import "./index.css";
// import moment from "moment";
// import { isMobile } from "react-device-detect";

import Box from "@mui/material/Box";
// import Divider from "@mui/material/Divider";
// import Stack from "@mui/material/Stack";
// import Avatar from "@mui/material/Avatar";
// import allNftAvatar from "../../images/Oval.png";
import Like from "../../images/like";
import LikeFilled from "../../svgs/heartFilled.svg";

import Button from "@mui/material/Button";

import useWindowSize from "../../hooks/useWindowSize";
// import { Capacitor } from "@capacitor/core";

import VideoPlayer from "./videoplayer";
import SkeletonLoader from "../../components/skeletonLoader/skeletonLoader";
import DesktopLiveChat from "../../components/liveChats/desktopLiveChat";
import MobileLiveChat from "../../components/liveChats/mobileLiveChat";
import { Capacitor } from "@capacitor/core";
import { PAGES } from "../../data/pages";
// import AdMobBanner from "../../components/mobile/admobBanner/admobBanner";

const Share = ({ url }) => {
  return <ShareDialog url={url} />;
};

const ProfileDetails = ({
  data = {},
  customButton,
  btnProps = {},
  UserDetails,
}) => {
  return (
    <div
      style={{
        width: "100%",
        height: "max-content",
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
      }}
    >
      <div style={{ display: "flex", alignItems: "center", gap: "10px" }}>
        {/* <Avatar
          src={data.avatar || Avatarprofile}
          sx={{ width: 65, height: 65 }}
        /> */}

        <div
          style={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
          }}
        >
          {UserDetails ? (
            <UserDetails />
          ) : (
            <>
              {/* <p style={{ margin: 0, color: "#C6FF00" }}>
                {data.name || data.username}
              </p> */}

              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  gap: "10px",
                  margin: 0,
                }}
              >
                {/* <div
                  style={{
                    display: "flex",
                    gap: "4px",
                    alignItems: "center",
                    fontSize: "13px",
                  }}
                >
                  <img src={viewr} />
                  <span style={{ margin: 0 }}>
                    {data.viewsAccumulated || "125k"}
                  </span>
                </div> */}
                {/* <div
                  style={{
                    display: "flex",
                    gap: "4px",
                    alignItems: "center",
                    fontSize: "13px",
                  }}
                >
                  <img src={user} />
                  <span style={{ margin: 0 }}>{data.followers || "25k"}</span>
                </div> */}
              </div>
            </>
          )}
        </div>
      </div>

      <div className="unblockbutton">
        {customButton || (
          <Button
            style={{
              textTransform: "none",
              padding: "5px 20px",
              fontSize: "0.8rem",
              borderRadius: "20px",
              color: "black",
              backgroundColor: "#f2994a",
            }}
            onClick={btnProps?.onClick || null}
            variant="contained"
            disableElevation
          >
            Unblock
          </Button>
        )}
      </div>
    </div>
  );
};

const NonAuthStreaming = () => {
  const location = useLocation();
  const stream_id_location = new URLSearchParams(location.search).get("id");
  const [stream, setStream] = useState({});
  const [streamer, setStreamer] = useState(null);
  const [streamStatus, setStreamStatus] = useState("upcoming");
  const [liveData, setLiveData] = useState(null);
  const { servApiUrl, socket } = useContext(UserContext);
  const [width] = useWindowSize();
  const navigate = useNavigate();

  let [commentsArr, setCommentsArr] = useState([]);

  const scrollChatContainer = () => {
    // Return on mobile screen
    if (window.screen.width <= 650) return;
    const chatContainer = document.getElementsByClassName("streamer-box")[0];
    if (chatContainer) {
      chatContainer.scrollTop = chatContainer.scrollHeight;
    }
  };

  var getComments = (streamId) => {
    let requestOptions = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ sessionId: streamId }),
    };
    let getCommentsApiUrl = `${servApiUrl}noAuth/session/search/comment/`;
    fetch(getCommentsApiUrl, requestOptions)
      .then((response) => response.json())
      .then((data) => {
        setCommentsArr(data);
        scrollChatContainer();
      });
  };

  const getStreamerDetails = async (streamerId) => {
    try {
      const requestOptions = {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ _id: streamerId }),
      };
      const response = await fetch(
        `${servApiUrl}noAuth/streamer/search/profile/`,
        requestOptions
      );

      if (response.status !== 200) throw new Error("error");
      const streamer = await response.json();
      setStreamer(streamer);
    } catch (error) {}
  };

  const getStream = async (streamId) => {
    try {
      const requestOptions = {
        method: "GET",
      };

      const response = await fetch(
        `${servApiUrl}noAuth/event/` + streamId,
        requestOptions
      );

      if (response.status !== 200) throw new Error("error");

      const stream = await response.json();
      // joinStream(streamId);
      getStreamerDetails(stream.uid);
      setStream(stream);
      getComments(stream?._id);
      if (stream.status == "live") {
        setLiveData({
          key: stream.pushKey,
          url: stream.pushUrl,
          playback: stream.playbackUrl,
        });
      }
    } catch (error) {}
  };

  const redirectToLogin = () => {
    navigate("/login");
  };

  const LikesCount = ({ stream }) => {
    return (
      <>
        <span style={{ cursor: "pointer" }} onClick={redirectToLogin}>
          {stream?.isLiked ? (
            <>
              <img
                src={LikeFilled}
                style={{ position: "relative", top: "-1px" }}
              />
            </>
          ) : (
            <>
              <Like style={{ position: "relative", top: "1px" }} />
            </>
          )}
        </span>
        <span>{stream?.likes}</span>
      </>
    );
  };

  const updateCommentsArrayState = (comment) => {
    const commentsArrClone = [...commentsArr];
    commentsArrClone.push(comment);
    setCommentsArr(commentsArrClone);
  };

  useEffect(() => {
    socket.on("receive_message", (data) => {
      if (!data) return;

      const commentObject = {
        comment: data.text,
        uid: data.user,
        updatedAt: new Date(),
      };
      updateCommentsArrayState(commentObject);
    });
  }, [socket, commentsArr]);

  const joinSocketSession = async (id) => {
    socket.emit("livestream_join", { sessionId: id });
  };

  const leaveSocketSession = async (id) => {
    socket.emit("livestream_leave", { sessionId: id });
  };

  useEffect(() => {
    const id = new URLSearchParams(location.search).get("id");

    if (id) {
      joinSocketSession(id);
      getStream(id);
    }

    return () => {
      leaveSocketSession(id);
    };
  }, [location]);

  useEffect(() => {
    setStreamStatus(stream.status);
  }, [stream]);

  // function initializeTCPPlayer(fileId) {
  //   // eslint-disable-next-line
  //   const player = TCPlayer("player-container-id", {
  //     // The ID of the player container, which must be the same as that in the HTML
  //     fileID: fileId, // Pass in the ID of the file to play (required)
  //     appID: "1308820136", // Pass in the AppID of the VOD subapplication (required)
  //     psign: "", // Pass in the player signature (required)
  //     autoplay: true, // Whether to enable auto-play
  //     // For other parameters, view them in VOD documentation
  //     // width: "1000",
  //     // height: "400",
  //     language: "en",
  //     bigPlayButton: false,
  //     loop: true,
  //     muted: false,
  //     reportable: false,
  //     playbackRates: [],
  //     controlBar: {
  //       progressControl: false,
  //       currentTimeDisplay: false,
  //       durationDisplay: false,
  //       timeDivider: false,
  //       QualitySwitcherMenuButton: false,
  //     },
  //   });
  //   tencentPlayer = player;
  // }

  //  React.useEffect(() => {
  // window.loadBanner();
  // if (isMobile || window.innerWidth < 650) {
  //   window.loadBanner();
  // }
  // if (Capacitor.isNativePlatform()) {
  //   window.loadBanner();
  // } else {
  // }

  //   return () => {
  //     if (!tencentPlayer) return;
  //     tencentPlayer.dispose();
  //   };
  // }, []);

  // React.useEffect(() => {
  //   if (stream?.status === "recorded") {
  //     initializeTCPPlayer(stream.playbackUrl);
  //   }
  // }, [stream]);

  // const tencentStream = useMemo(
  //   () => (
  //     <video
  //       id="player-container-id"
  //       preload="auto"
  //       playsinline
  //       webkit-playsinline
  //       x5-playsinline
  //       className={classes.tencentPlayer}
  //     ></video>
  //   ),
  //   [stream]
  // );

  const comment = () => {
    navigate(PAGES.LOGIN);
    // let text = document.getElementById("comment-text-streaming")?.value;
    // if (!text || typeof text != "string" || text.trim().length == 0) {
    //   return false;
    // }

    // const requestOptions = getRequestOptions("POST", false, {
    //   sessionId: stream._id,
    //   comment: text.trim(),
    // });

    // const chatBox = document.getElementById("comment-text-streaming");
    // if (chatBox) {
    //   chatBox.value = "";
    // }

    // const userObj = {
    //   name: "unknown",
    //   username: "unknown",
    // };

    // fetch(`${servApiUrl}noAuth/session-comment`, requestOptions)
    //   .then((response) => response.json())
    //   .then((data) => {
    //     if (!data.success) return;
    //     socket.emit("message", { room: stream._id, text, user: userObj });
    //     if (chatBox) {
    //       chatBox.value = "";
    //     }
    //   })
    //   .catch(() => {});
  };

  const desktopView = (
    <div>
      <div style={{ display: "flex" }}>
        <main className={classes.container}>
          <section className={classes.videoContainer}>
            <div
              style={{ width: "100%" }}
              className="videoPlayer stream-desktop-container"
            >
              {stream?.status == "live" || stream?.status === "recorded" ? (
                stream?.playbackUrl ? (
                  <VideoPlayer
                    width="100%"
                    source={stream?.playbackUrl}
                    height="100%"
                  />
                ) : (
                  <p> Can't get playback url of live stream</p>
                )
              ) : stream?.status == "upcoming" ? (
                stream?.waitingImage ? (
                  <img src={stream.waitingImage} style={{ width: "100%" }} />
                ) : (
                  <p> Can't get cover image of upcoming stream. </p>
                )
              ) : stream?.status == "done" ? (
                stream?.playbackUrl ? (
                  <VideoPlayer
                    width="100%"
                    source={stream?.playbackUrl}
                    height="100%"
                  />
                ) : (
                  <p> Cant get playback url of ended stream.</p>
                )
              ) : stream?.status === "recorded" ? null : (
                <SkeletonLoader />
              )}
            </div>
          </section>
        </main>
        <Box className="streamer-box stream-desktop-container">
          <DesktopLiveChat commentsArr={commentsArr} comment={comment} />
        </Box>
      </div>
      <div style={{ marginTop: "10px" }}>
        <ShareDialog url={window.location} />
      </div>
    </div>
  );

  const mobileView = (
    <main className={classes.container}>
      <section className={classes.videoContainer} style={{ flex: "0 0" }}>
        <div
          style={{ width: "100%", height: "400px" }}
          className="videoPlayer stream-desktop-container"
        >
          {stream?.status == "live" || stream?.status === "recorded" ? (
            stream?.playbackUrl ? (
              <VideoPlayer
                width="100%"
                source={stream?.playbackUrl}
                height="100%"
                runAds={true}
              />
            ) : (
              <p> Can't get playback url of live stream</p>
            )
          ) : stream?.status == "upcoming" ? (
            stream?.waitingImage ? (
              <img src={stream.waitingImage} style={{ width: "100%" }} />
            ) : (
              <p> Can't get cover image of upcoming stream. </p>
            )
          ) : stream?.status == "done" ? (
            stream?.playbackUrl ? (
              <VideoPlayer
                width="100%"
                source={stream?.playbackUrl}
                height="100%"
              />
            ) : (
              <p> Cant get playback url of ended stream.</p>
            )
          ) : stream?.status === "recorded" ? null : (
            <SkeletonLoader />
          )}
        </div>
      </section>

      <div
        style={{
          marginTop: "10px",
          display: "flex",
          justifyContent: "flex-end",
        }}
      >
        <ShareDialog url={window.location} />
      </div>

      <MobileLiveChat commentsArr={commentsArr} comment={comment} />
    </main>
  );

  return (
    <DashboardLayout
      showSidebar={false}
      noRightMargin={true}
      noPaddingBottom={width <= 650}
    >
      {width > 650 && !Capacitor.isNativePlatform() ? desktopView : mobileView}
    </DashboardLayout>
  );
};

export default NonAuthStreaming;
