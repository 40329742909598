import { useState } from "react";
import { useUserContext } from "../../context/UserContext";
import { getAuthToken } from "../../functions/auth";

const useGetTrendingStreams = () => {
  const [trendingStreams, setTrendingStreams] = useState([]);
  const [loading, setLoading] = useState(false);
  const { isLoggedIn, servApiUrl } = useUserContext();

  const getTrendingStreams = async () => {
    setLoading(true);

    const ApiUrl = isLoggedIn
      ? "/engine/foryou/trendingEvents"
      : "/noAuth/foryou/trendingEvents";

    try {
      const response = await fetch(`${servApiUrl}${ApiUrl}`, {
        method: "GET",
        headers: {
          Authorization: getAuthToken(),
        },
      });

      const data = await response.json();
      if (data instanceof Array && data.length > 0) {
        setTrendingStreams(data);
      }
    } catch (err) {
      console.error(err);
    } finally {
      setLoading(false);
    }
  };

  return { trendingStreams, loading, getTrendingStreams, setTrendingStreams };
};

export default useGetTrendingStreams;
