export const PAGES = {
  CREATE_PROFILE: "/create-profile",
  FORGET_PASSWORD: "/forgot-password",
  RESET_PASSWORD: "/reset-password",
  LOGIN: "/login",
  NON_AUTH_STREAMING: "/streamer-streaming-guest",
  START_SCREEN: "/welcome",
  NEWS: "/news",
  PREFERENCE: "/preference",
  SUBMIT_STREAMER_REQUEST: "/submit-request",
  PRIVACY_POLICY: "/settings/privacy-policy",
  ABOUT_US: "/settings/about-us",
  TERMS_AND_CONDITIONS: "/settings/terms-and-conditions",
  CHATS: "/chats",
  SETTINGS_CHANGE_PASSWORD: "/settings/change-password",
  SETTINGS_BLOCKED_ACCOUNTS: "/settings/blocked-accounts",
  SETTINGS_EDIT_PROFILE: "/settings/edit-profile",
  STREAMER_PROFILE: "/streamer-profile",
  TRANSACTION_HISTORY: "/transation-history",
  GAME_SEE_ALL: "/games/seeAll",
  STREAMER_SEE_ALL: "/streamers/seeAll",
  STREAMS_SEE_ALL: "/streams/seeAll",
  NFT_WALLET: "/nft-wallet",
  GAME_DETAILS: "/game-details",
  STREAMER_STREAMING: "/streamer-streaming",
  POLY_COMMUNITY: "/poly-community",
  ADD_SOCIAL_POST: "/poly-community/add-post",
  MY_SOCIAL_POSTS: "/poly-community/my-posts",
  STREAM_VIDEO: "/stream-video",
  LEADERBOARD: "/leaderboard",
  SETTINGS: "/settings",
  FILTERS: "/filters",
  HOME: "/",
  MY_GAMES: "/my-games",
  NFT_PAGE: "/nft",
  NFT_MARKETPLACE: "/nft-marketplace",
  POLYCOINS: "/polycoins",
  MY_STREAMERS: "/my-streamers",
  EXCHANGE: "/exchange",
  CREATE_NFT: "/create-nft",
  START_STREAM: "/start-stream/:id",
  STAKING: "/staking",
  STATISTICS: "/statistics",
  ADD_SCHEDULE: "/add-schedule",
  MY_SCHEDULES: "/my-schedule",
  DISCOVER: "/discover",
  AVATAR: "/avatars",
  DELETE_ACCOUNT: "/deleteAccount",
  POLY_VERSE: "/polyverse",
  CONTACT_US: "/contact-us",
};
