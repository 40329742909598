import React from "react";

import classes from "./socialMediaShare.module.css";
import { SOCIAL_MEDIA_SHARE_ITEMS } from "./socialMedaShare.data";

function SocialMediaShare() {
  const openPage = (item) => {
    if (typeof item.link == "string" && item.link.length > 0) {
      window.open(item.link);
    }
  };

  const SocialMediaShareItem = ({ item }) => (
    <>
      {item.iconClass ? (
        <i
          className={`${item.iconClass} ${classes.icon}`}
          onClick={() => openPage(item)}
          style={{ color: item.iconColor }}
        />
      ) : (
        <img
          onClick={() => openPage(item)}
          src={item.iconUrl}
          className={classes.icon}
          alt="Social"
        />
      )}
    </>
  );

  return (
    <div className={classes.main}>
      {SOCIAL_MEDIA_SHARE_ITEMS.map((item, index) => (
        <SocialMediaShareItem key={`social-media-share-${index}`} item={item} />
      ))}
    </div>
  );
}

export default SocialMediaShare;
