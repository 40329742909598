import { lazy } from "react";

import { Home } from "../../pages/home/Home";

// import { CategoriesNFT } from "../../components/caregoriesNFT/CategoriesNFT";
import { PAGES } from "../../data/pages";
// import { NFTPage } from "../../pages/nftPage/NFTPage";
import PolyCommunity from "../../pages/polyCommunity/polyCommunity";
import { Exchange } from "../../pages/exchange/Exchange";
import Chats from "../../pages/chats/chats";
import PolyVerse from "../../pages/PolyVerse/PolyVerse";
import ContactUs from "../../pages/contactUs/contactUs";

// const News = lazy(() => import("../../pages/news/news"));
const LeaderBoard = lazy(() => import("../../pages/leaderBoard/LeaderBoard"));
const MySchedules = lazy(() => import("../../pages/schedules/MySchedules"));

const PrivacyPolicy = lazy(() =>
  import("../../pages/privacypolicy/PrivacyPolicy")
);
const AboutUs = lazy(() => import("../../pages/aboutus/AboutUs"));
const TermsandCondition = lazy(() =>
  import("../../pages/TermsandCondition/TermsandCondition")
);
const Avatar = lazy(() => import("../../pages/Avatar/AvatarController"));
const Settings = lazy(() => import("../../pages/settings/Settings"));
const ResetPassword = lazy(() => import("../../components/resetPassword"));
const DeleteAccount = lazy(() =>
  import("../../pages/deleteAccount/deleteAccount")
);

export const COMMON_ROUTES = [
  // {
  //   path: PAGES.NEWS,
  //   element: <News />,
  //   lazy: true,
  // },
  {
    path: PAGES.POLY_COMMUNITY,
    element: <PolyCommunity />,
  },
  {
    path: PAGES.EXCHANGE,
    element: <Exchange />,
  },
  {
    path: PAGES.CHATS,
    element: <Chats />,
  },
  {
    path: PAGES.LEADERBOARD,
    element: <LeaderBoard />,
    lazy: true,
  },
  {
    path: PAGES.MY_SCHEDULES,
    element: <MySchedules />,
    lazy: true,
  },
  {
    path: PAGES.PRIVACY_POLICY,
    element: <PrivacyPolicy />,
    lazy: true,
  },
  {
    path: PAGES.ABOUT_US,
    element: <AboutUs />,
    lazy: true,
  },
  {
    path: PAGES.TERMS_AND_CONDITIONS,
    element: <TermsandCondition />,
    lazy: true,
  },
  {
    path: PAGES.AVATAR,
    element: <Avatar />,
    lazy: true,
  },
  {
    path: PAGES.SETTINGS,
    element: <Settings />,
    lazy: true,
  },
  {
    path: PAGES.RESET_PASSWORD,
    element: <ResetPassword />,
    lazy: true,
  },
  {
    path: PAGES.DELETE_ACCOUNT,
    element: <DeleteAccount />,
    lazy: true,
  },
  {
    path: PAGES.HOME,
    element: <Home />,
    lazy: false,
  },
  {
    path: PAGES.POLY_VERSE,
    element: <PolyVerse />,
    lazy: false,
  },
  {
    path: PAGES.CONTACT_US,
    element: <ContactUs />,
    lazy: false,
  },
  // {
  //   path: PAGES.NFT_MARKETPLACE,
  //   element: <CategoriesNFT />,
  // },
  // {
  //   path: PAGES.NFT_PAGE,
  //   element: <NFTPage />,
  // },
];
