const Like = ({ style }) => (
  <svg
    width="27"
    style={style}
    height="22"
    viewBox="0 0 27 22"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g filter="url(#filter0_d_3097_8499)">
      <mask
        id="mask0_3097_8499"
        maskUnits="userSpaceOnUse"
        x="2"
        y="0"
        width="21"
        height="21"
      >
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M2 0.889648H22.4725V20.3906H2V0.889648Z"
          fill="white"
        />
      </mask>
      <g mask="url(#mask0_3097_8499)">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M3.82396 10.0128C5.22596 14.3748 10.765 17.9018 12.237 18.7748C13.714 17.8928 19.293 14.3268 20.65 10.0168C21.541 7.23077 20.714 3.70177 17.428 2.64277C15.836 2.13177 13.979 2.44277 12.697 3.43477C12.429 3.64077 12.057 3.64477 11.787 3.44077C10.429 2.41977 8.65496 2.12077 7.03796 2.64277C3.75696 3.70077 2.93296 7.22977 3.82396 10.0128M12.238 20.3908C12.114 20.3908 11.991 20.3608 11.879 20.2998C11.566 20.1288 4.19296 16.0648 2.39596 10.4708C2.39496 10.4708 2.39496 10.4698 2.39496 10.4698C1.26696 6.94777 2.52296 2.52177 6.57796 1.21477C8.48196 0.598767 10.557 0.869766 12.235 1.92877C13.861 0.900767 16.021 0.616767 17.887 1.21477C21.946 2.52377 23.206 6.94877 22.079 10.4698C20.34 15.9998 12.913 20.1248 12.598 20.2978C12.486 20.3598 12.362 20.3908 12.238 20.3908"
          fill="#8E8EA9"
        />
      </g>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M18.1537 8.51467C17.7667 8.51467 17.4387 8.21767 17.4067 7.82567C17.3407 7.00367 16.7907 6.30967 16.0077 6.05667C15.6127 5.92867 15.3967 5.50567 15.5237 5.11267C15.6527 4.71867 16.0717 4.50467 16.4677 4.62867C17.8307 5.06967 18.7857 6.27667 18.9027 7.70367C18.9357 8.11667 18.6287 8.47867 18.2157 8.51167C18.1947 8.51367 18.1747 8.51467 18.1537 8.51467"
        fill="#8E8EA9"
      />
    </g>
    <defs>
      <filter
        id="filter0_d_3097_8499"
        x="-2"
        y="0.889648"
        width="28.4727"
        height="27.501"
        filterUnits="userSpaceOnUse"
        colorInterpolationFilters="sRGB"
      >
        <feFlood floodOpacity="0" result="BackgroundImageFix" />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feOffset dy="4" />
        <feGaussianBlur stdDeviation="2" />
        <feComposite in2="hardAlpha" operator="out" />
        <feColorMatrix
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"
        />
        <feBlend
          mode="normal"
          in2="BackgroundImageFix"
          result="effect1_dropShadow_3097_8499"
        />
        <feBlend
          mode="normal"
          in="SourceGraphic"
          in2="effect1_dropShadow_3097_8499"
          result="shape"
        />
      </filter>
    </defs>
  </svg>
);

export default Like;
