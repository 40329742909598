import { useContext, useEffect, useState } from "react";

import { Carousel } from "3d-react-carousal";
import { useNavigate } from "react-router-dom";
import UserContext from "../../../../context/UserContext";
import VideoPlayer from "../../../streamerStreaming/videoplayer";

import VisibilityIcon from "@mui/icons-material/Visibility";

import "./homeVideoCarousel.css";

import { formatNumberToEnglish } from "../../../../functions/numberFormatter";
import { isMobile } from "react-device-detect";

import moment from "moment";
import SkeletonLoader from "../../../../components/skeletonLoader/skeletonLoader";
import { PAGES } from "../../../../data/pages";

const HomeVideoCarousel = ({ liveStreams, id, autoplay = true }) => {
  const [carouselSlides, setCarouselSlides] = useState([]);
  const { user } = useContext(UserContext);

  const navigate = useNavigate();

  const getStreamerNameOrUsername = (stream) => {
    return stream.uid?.name || stream.uid?.username;
  };

  const getStreamChildren = (i, stream) => (
    <>
      <div
        id={`${id}-info-${i}`}
        style={{ position: "relative" }}
        className="videoInfoContainerHide"
      >
        <div className="video-stream-info-container">
          <div className="video-stream-info-inner-container">
            <div className="videoInfoliveButton">LIVE</div>
            <div className="videoInfoViewerCount">
              <VisibilityIcon sx={{ fontSize: "14px" }} />
              {formatNumberToEnglish(stream.viewers ?? 0)}
            </div>
          </div>
        </div>
      </div>
      <div
        id={`${id}-streamer-info-${i}`}
        style={{ position: "relative" }}
        className="videoInfoContainerHide"
      >
        <div className="video-stream-info-container video-streamer-info-container">
          <div className="video-stream-info-inner-container">
            {isMobile ? (
              <div
                style={{ gap: "3px" }}
                className="videoInfoViewerCount videoInfoTimeLapse flex-column"
              >
                <p className="liveStreamTitle">{stream?.title}</p>
                <div className="streamer-name-container">
                  <p>{getStreamerNameOrUsername(stream)}</p>
                  <div className="circle"></div>
                  <p className="liveStreamTimeText">
                    {moment(stream?.startTime).fromNow()}
                  </p>
                </div>
              </div>
            ) : (
              <div className="videoInfoViewerCount videoInfoTimeLapse flex-column align-start">
                <p className="liveStreamTitle">{stream?.title}</p>
                <div className="streamer-name-container">
                  <p>{getStreamerNameOrUsername(stream)}</p>
                  <div className="circle"></div>
                  <p className="liveStreamTimeText">
                    {moment(stream?.startTime).fromNow()}
                  </p>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
      <VideoPlayer
        id={`${id}-${i}`}
        source={stream.playbackUrl}
        isMuted={true}
        controls={false}
        autoPlay={false}
        poster={stream.waitingImage}
      />
    </>
  );

  const onClickVideoItem = (i, stream) => {
    user
      ? navigate(`${PAGES.STREAMER_STREAMING}?id=${stream._id}`)
      : navigate(`${PAGES.NON_AUTH_STREAMING}?id=${stream._id}`);
  };

  const VideoItem = (i, stream) => (
    <>
      {isMobile ? (
        <a
          className="video-carousel-container"
          onPointerUp={() => onClickVideoItem(i, stream)}
          style={{ cursor: "pointer" }}
        >
          {getStreamChildren(i, stream)}
        </a>
      ) : (
        <a
          className="video-carousel-container"
          onClick={() => onClickVideoItem(i, stream)}
        >
          {getStreamChildren(i, stream)}
        </a>
      )}
    </>
  );

  const createSlides = () => {
    let slides = [];

    for (let i = 0; i < liveStreams.length; i++) {
      const liveStream = liveStreams[i];

      slides.push(VideoItem(i, liveStream));
    }

    setCarouselSlides(slides);
  };

  // Used to start video if there is only 1 live stream
  const startVideoAfterTimeout = () => {
    // If autoplay is false just return
    if (liveStreams?.length !== 1 || !autoplay) return;
    const timeout = 1000;
    setTimeout(() => startVideo(0), timeout);
  };

  useEffect(() => {
    createSlides();
    startVideoAfterTimeout();
  }, [liveStreams]);

  const startVideo = (index) => {
    const videoElement = document.getElementById(`${id}-${index}_html5_api`);
    if (videoElement) {
      videoElement.muted = "muted";
      if (autoplay) videoElement.play();
    }

    const videoInfoElement = document.getElementById(`${id}-info-${index}`);
    const videoStreamerInfoElement = document.getElementById(
      `${id}-streamer-info-${index}`
    );
    if (videoInfoElement) {
      videoInfoElement.classList.remove("videoInfoContainerHide");
    }
    if (videoStreamerInfoElement) {
      videoStreamerInfoElement.classList.remove("videoInfoContainerHide");
    }
  };

  // Stopping other videos means only the active carousel video should play, other videos should stop
  const stopOtherVideos = (index) => {
    for (let i = 0; i < liveStreams.length; i++) {
      if (i === index) continue;

      const videoElement = document.getElementById(`${id}-${i}_html5_api`);
      if (videoElement) videoElement.pause();

      const videoInfoElement = document.getElementById(`video-info-${i}`);
      const videoStreamerInfoElement = document.getElementById(
        `video-streamer-info-${i}`
      );
      if (videoInfoElement) {
        videoInfoElement.classList.add("videoInfoContainerHide");
      }
      if (videoStreamerInfoElement) {
        videoStreamerInfoElement.classList.add("videoInfoContainerHide");
      }
    }
  };

  const callback = function (index) {
    startVideo(index);
    stopOtherVideos(index);
  };

  return (
    <>
      {carouselSlides.length === 0 ? (
        <SkeletonLoader />
      ) : (
        <Carousel
          slides={carouselSlides}
          interval={1000}
          onSlideChange={callback}
        />
      )}
    </>
  );
};

export default HomeVideoCarousel;
