import React, { useEffect, useState } from "react";
import {
  EmailIcon,
  EmailShareButton,
  FacebookIcon,
  FacebookShareButton,
  TwitterIcon,
  TwitterShareButton,
  WhatsappIcon,
  WhatsappShareButton,
} from "react-share";
import Dialog from "../../wrappers/Dialog";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import { swalFormSuccess } from "../../functions/swal";
import IosShareIcon from "@mui/icons-material/IosShare";

function ShareDialog({ url, iconFontSize = 24 }) {
  const [link, setLink] = useState(url);

  useEffect(() => {
    setLink(url);
  }, [url]);

  const onCopyIconClick = () => {
    swalFormSuccess("Link copied!", true, "top");
    navigator.clipboard.writeText(url);
  };

  return (
    <Dialog
      actions={{
        successBtnProps: {
          title: "Share",
        },
      }}
      title="Share"
      handler={{
        cancel: (setOpen) => {
          setOpen(false);
        },
        success: (setOpen) => {
          onCopyIconClick();
          setOpen(false);
        },
      }}
      Source={({ setOpen }) => (
        <div
          className="share-dialog-source-container"
          onClick={setOpen.bind(this, true)}
        >
          <div className="share-dialog-source-inner-container">
            <p className="share-dialog-source-text">Share</p>
            <IosShareIcon
              className="share-dialog-source-icon"
              sx={{ fontSize: iconFontSize }}
            />
          </div>
        </div>
      )}
    >
      <div
        id="share_main_container"
        style={{ display: "flex", flexDirection: "column" }}
      >
        <div
          id="copy_link_box"
          style={{
            padding: "1em",
            display: "flex",
            flexDirection: "column",
            alignItems: "flex-start",
            justifyContent: "center",
          }}
        >
          <div className="share-dialog-copy-link-container">
            <h5 className="share-dialog-copy-link"> Copy the link </h5>
            <div onClick={onCopyIconClick}>
              <ContentCopyIcon className="share-dialog-copy-link-icon" />
            </div>
          </div>
          <input
            disabled={true}
            value={link}
            style={{
              backgroundColor: "#1a2028",
              borderRadius: "10px",
              padding: "1em",
              fontSize: "1em",
              color: "grey",
              border: "none",
              width: "100%",
            }}
          />
        </div>
        <div className="share-dialog-divider"></div>
        <div
          id="share_link_box"
          style={{
            padding: "1em",
            display: "flex",
            flexDirection: "column",
            alignItems: "flex-start",
            justifyContent: "center",
          }}
        >
          <h5 className="share-dialog-invite-via-text"> Invite via </h5>
          <div
            id="icons_row"
            style={{
              display: "flex",
              flexDirection: "row",
              width: "100%",
              flexWrap: "wrap",
              alignItems: "center",
              justifyContent: "space-between",
            }}
          >
            <TwitterShareButton url={url}>
              <TwitterIcon size={40} round />
            </TwitterShareButton>

            <WhatsappShareButton url={url}>
              <WhatsappIcon size={40} round />
            </WhatsappShareButton>

            <FacebookShareButton url={url}>
              <FacebookIcon size={40} round />
            </FacebookShareButton>

            <EmailShareButton url={url}>
              <EmailIcon size={40} round />
            </EmailShareButton>
          </div>
        </div>
      </div>
    </Dialog>
  );
}

export default ShareDialog;
