import React from "react";
import List from "@mui/material/List";
import Divider from "@mui/material/Divider";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import WalletIcon from "@mui/icons-material/Wallet";
import LeaderboardIcon from "@mui/icons-material/Leaderboard";
import SettingsIcon from "@mui/icons-material/Settings";
import ScheduleIcon from "@mui/icons-material/Schedule";
import PersonIcon from "@mui/icons-material/Person";
import StarIcon from "@mui/icons-material/Star";
import { useNavigate } from "react-router-dom";
import { PAGES } from "../../../../../../data/pages";
import { useUserContext } from "../../../../../../context/UserContext";
import LogoutConfirmDialog from "../../../../../dialogs/logoutConfirmDialog";
import LogoutIcon from "@mui/icons-material/Logout";
import LoginIcon from "@mui/icons-material/Login";
import Person4Icon from "@mui/icons-material/Person4";

function AccountTab() {
  const navigate = useNavigate();
  const { user, isLoggedIn } = useUserContext();

  let props = {};
  if (isLoggedIn && user.role == "streamer") {
    props["MY_SCHEDULES"] = "My Schedules";
  } else if (isLoggedIn) {
    props["BE_A_STREAMER"] = "Be a streamer";
  }

  const LINKS = {
    ...props,
    POLY_WALLET: "PolyWallet",
    LEADERBOARDS: "Leaderboards",
    VIEW_TRACTION: "View Traction",
  };

  const AFTER_DIVIDER_LINKS = {
    SETTINGS: "Settings",
  };

  const getImage = (link) => {
    switch (link) {
      case LINKS.POLY_WALLET:
        return <WalletIcon />;
      case LINKS.LEADERBOARDS:
        return <LeaderboardIcon />;
      case AFTER_DIVIDER_LINKS.SETTINGS:
        return <SettingsIcon />;
      case LINKS.MY_SCHEDULES:
        return <ScheduleIcon />;
      case LINKS.BE_A_STREAMER:
        return <PersonIcon />;
      case LINKS.VIEW_TRACTION:
        return <StarIcon />;
      default:
        return <WalletIcon />;
    }
  };

  const openPage = (text) => {
    switch (text) {
      case LINKS.POLY_WALLET:
        return PAGES.POLYCOINS;
      case LINKS.LEADERBOARDS:
        return PAGES.LEADERBOARD;
      case AFTER_DIVIDER_LINKS.SETTINGS:
        return PAGES.SETTINGS;
      case LINKS.MY_SCHEDULES:
        return PAGES.MY_SCHEDULES;
      case LINKS.BE_A_STREAMER:
        return PAGES.SUBMIT_STREAMER_REQUEST;
      default:
        return PAGES.HOME;
    }
  };

  const LogoutSource = ({ setOpen }) => (
    <ListItem
      disablePadding
      onClick={() => {
        setOpen(true);
      }}
    >
      <ListItemButton>
        <ListItemIcon>
          <LogoutIcon />
        </ListItemIcon>
        <ListItemText primary={"Logout"} />
      </ListItemButton>
    </ListItem>
  );

  const Login = () => (
    <ListItem
      disablePadding
      onClick={() => {
        navigate(PAGES.LOGIN);
      }}
    >
      <ListItemButton>
        <ListItemIcon>
          <LoginIcon />
        </ListItemIcon>
        <ListItemText primary={"Login"} />
      </ListItemButton>
    </ListItem>
  );

  return (
    <div>
      <List>
        {Object.values(LINKS).map((text, index) => (
          <ListItem
            key={`divider-link-${index}`}
            disablePadding
            onClick={() => {
              const link = openPage(text);
              if (text == LINKS.VIEW_TRACTION) {
                window.open(
                  "https://lookerstudio.google.com/u/0/reporting/407c5a25-b449-47bf-93cf-053702131de4/page/1M"
                );
              }
              navigate(link);
            }}
          >
            <ListItemButton>
              <ListItemIcon>{getImage(text)}</ListItemIcon>
              <ListItemText primary={text} />
            </ListItemButton>
          </ListItem>
        ))}
      </List>
      <Divider />
      <List>
        {Object.values(AFTER_DIVIDER_LINKS).map((text, index) => (
          <ListItem
            key={`after-divider-link-${index}`}
            disablePadding
            onClick={() => {
              const link = openPage(text);
              navigate(link);
            }}
          >
            <ListItemButton>
              <ListItemIcon>{getImage(text)}</ListItemIcon>
              <ListItemText primary={text} />
            </ListItemButton>
          </ListItem>
        ))}

        {isLoggedIn ? <LogoutConfirmDialog Source={LogoutSource} /> : <Login />}
      </List>
    </div>
  );
}

export default AccountTab;
