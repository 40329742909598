import { useState, Fragment } from "react";

import Box from "@mui/material/Box";
import Drawer from "@mui/material/Drawer";
import BottomBar from "./components/BottomBar/BottomBar";

const MobileDrawer = ({ children, openDrawer, setOpenDrawer }) => {
  const [state, setState] = useState({
    bottom: false,
  });

  const toggleDrawer = (anchor, open) => (event) => {
    if (
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }

    setOpenDrawer(false);

    setState({ ...state, [anchor]: open });
  };

  const list = (anchor) => (
    <Box
      sx={{ width: anchor === "top" || anchor === "bottom" ? "auto" : 250 }}
      role="presentation"
      //   onClick={toggleDrawer(anchor, false)}
      //   onKeyDown={toggleDrawer(anchor, false)}
    >
      {children}
    </Box>
  );

  return (
    <div>
      {openDrawer ? null : <BottomBar setOpen={setOpenDrawer} />}
      {["bottom"].map((anchor) => (
        <Fragment key={anchor}>
          <Drawer
            anchor={anchor}
            open={openDrawer}
            onClose={toggleDrawer(anchor, false)}
            sx={{
              ".MuiDrawer-paper": {
                bottom: "65px",
              },
            }}
          >
            {list(anchor)}
          </Drawer>
        </Fragment>
      ))}
    </div>
  );
};

export default MobileDrawer;
