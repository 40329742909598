import React, { useEffect, useMemo, useState } from "react";

import TextField from "@mui/material/TextField";
import SendIcon from "@mui/icons-material/Send";

import classes from "./chat.module.css";

const LOW_COMMENTS_THRESH_HOLD = 18;

function DesktopLiveChat({ commentsArr, comment = null }) {
  const [text, setText] = useState("");

  const getUserName = (user) => {
    if (!user) return "";
    return user.username || user.name;
  };

  const scrollChatContainer = () => {
    const chatContainer = document.getElementsByClassName("streamer-box")[0];
    if (chatContainer) {
      chatContainer.scrollTop = chatContainer.scrollHeight;
    }
  };

  const handleComment = () => {
    setText("");
    comment();
  };

  useEffect(() => {
    if (commentsArr?.length > 0) scrollChatContainer();
  }, [commentsArr]);

  const commentsContainer = useMemo(() => {
    return Object.keys(commentsArr).map((keyName, index) => {
      const cardData = commentsArr[keyName];
      return (
        <div
          style={{ width: "100%", margin: "10px 0px" }}
          key={`comment-arr-${index}`}
        >
          <div
            style={{
              display: "flex",
              flexDirection: "column",
            }}
          >
            <p
              style={{
                fontWeight: "bold",
                fontSize: "12px",
                color: "var(--primary-color)",
              }}
            >
              {getUserName(cardData.uid)}:{"  "}
              <span className="stream-comment-class">{cardData.comment}</span>
            </p>
          </div>
        </div>
      );
    });
  }, [commentsArr]);

  const NoCommentsText = () => (
    <div className={classes.no_comments_container}>
      <h2>No Comments</h2>
      <p className={classes.no_comments_text}>Start the conversation!</p>
    </div>
  );

  const textField = (
    <div className="reply-input">
      <TextField
        variant="standard"
        size="medium"
        placeholder="Say Something"
        InputProps={{
          disableUnderline: true,
        }}
        onKeyUp={(e) => {
          if (e.key === "Enter") {
            e.preventDefault();
            handleComment();
          }
        }}
        id={"comment-text-streaming"}
        onChange={(e) => setText(e.target.value)}
      />
      <span className="sendIcons" onClick={() => handleComment()}>
        <SendIcon
          sx={{
            fontSize: "20px",
            color:
              text.trim().length == 0 ? "lightgray" : "var(--primary-color)",
            marginLeft: "5px",
          }}
        />
      </span>
    </div>
  );

  return (
    <div
      style={{ width: "100%", height: "100%" }}
      className={`${
        commentsArr.length <= LOW_COMMENTS_THRESH_HOLD
          ? classes.low_comments_container
          : ""
      }`}
    >
      {commentsArr.length == 0 ? NoCommentsText() : commentsContainer}
      {textField}
    </div>
  );
}

export default DesktopLiveChat;
