/* eslint-disable no-use-before-define */
import React, { createContext, useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import UserService from "../services/UserService";
import { BoxLoading } from "react-loadingg";
import { GoogleAuth } from "@codetrix-studio/capacitor-google-auth";
import { Capacitor } from "@capacitor/core";
// import {
//   FacebookLogin,
//   FacebookLoginResponse,
// } from "@capacitor-community/facebook-login";
import Swal from "sweetalert2";
import { io } from "socket.io-client";
import { getCurrencyRates } from "../functions/currencyConverter";
import { BASE_URL, IS_PROD } from "../data/env";
import { getRequestOptions, isAuth, removeToken } from "../functions/auth";
import { setupAdmobBanner } from "../functions/admob";
import { PAGES } from "../data/pages";

// const FACEBOOK_PERMISSIONS = [
//   "email",
//   "user_birthday",
//   "user_photos",
//   "user_gender",
// ];

const UserContext = createContext();

let socket;
if (IS_PROD) {
  socket = io(BASE_URL);
} else {
  socket = io("http://localhost:3310");
}

export const GlobalProvider = ({ children }) => {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(true);
  const [streamerId, setStreamerId] = useState(1);
  const [message, setMessage] = useState("");
  const [user, setUser] = useState(null);
  const [currencyRates, setCurrencyRates] = useState(null);
  const [pgemTradingPairInfo, setPgemTradingPairInfo] = useState(null);
  const servApiUrl = React.useState(
    IS_PROD ? `${BASE_URL}/api/` : "http://localhost:3310/api/"
  )[0];
  const [show_chat_box, setShowChatBox] = React.useState(false);
  const [isLoggedIn, setIsLoggedIn] = React.useState(false);

  // FacebookLogin.initialize({ appId: "906152794013304" });

  if (!Capacitor.isNativePlatform()) {
    GoogleAuth.initialize({
      // clientId:
      //   "748393372635-uvuu55vum36fkqplm7021nru7k4matpt.apps.googleusercontent.com",
      clientId:
        "28244825677-oqd76fq73as974f2aifkjoat3t6lnvhk.apps.googleusercontent.com",
      scopes: ["profile", "email"],
      grantOfflineAccess: false,
    });
  }

  const loginAlert = () => {
    Swal.fire({
      icon: "success",
      title: "Welcome aboard",
      position: "center",
      showConfirmButton: false,
      timer: 1500,
    });
  };

  const googleLoginSuccess = async (data, redirectLocation = null) => {
    try {
      const body = {
        idToken: data.authentication.accessToken,
        deviceToken: data.authentication.idToken,
        user: {
          name: data.givenName,
          googleId: data.id,
          photo: data.imageUrl,
          email: data.email,
        },
      };
      await fetch(`${servApiUrl}google`, {
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify(body),
        method: "POST",
      })
        .then((response) => response.json())
        .then(async (data) => {
          if (data["token"]) {
            localStorage.setItem("pg-auth-token", data["token"]);
            const user = await UserService.getUser(servApiUrl);
            if (user) {
              setUser(user);
              loginAlert();
              setTimeout(() => {
                navigate(redirectLocation ?? PAGES.HOME);
              }, 100);
            }
          } else {
            alert(data.msg);
          }
        });
    } catch (error) {
      console.log(error);
    }
  };

  const googleLoginFailure = (data) => {
    Swal.fire({
      icon: "error",
      title: "Something went wrong",
      text: "Please try to login using the login form while we are working to fix this issue.",
      position: "top",
      showConfirmButton: false,
      toast: true,
      timer: 5000,
    });
  };

  // const facebookLoginSucces = async (data) => {
  //   try {
  //     const { accessToken, email, name, picture } = data;

  //     const body = {
  //       accessToken: data.accessToken,
  //       deviceToken: data.signedRequest,
  //       // user: { email, name, imageUrl: picture?.data?.url },
  //       language: "english",
  //     };

  //     console.log("success: ", data);

  //     await fetch(`${servApiUrl}facebook`, {
  //       headers: { "Content-Type": "application/json" },
  //       body: JSON.stringify(body),
  //       method: "POST",
  //     })
  //       .then((response) => response.json())
  //       .then(async (data) => {
  //         console.log(data);
  //         if (data["token"]) {
  //           localStorage.setItem("pg-auth-token", data["token"]);
  //           const user = await UserService.getUser(servApiUrl);
  //           if (user) {
  //             setUser(user);
  //             setTimeout(() => {
  //               navigate("/home");
  //             }, 500);
  //           }
  //         } else {
  //           alert(data.msg);
  //         }
  //       });
  //   } catch (error) {
  //     console.log(error);
  //   }
  // };

  // const facebookLoginFailure = (data) => {
  //   console.log("failure: ", data);
  // };

  function googleSignIn(redirectLocation = null) {
    GoogleAuth.signIn()
      .then((result) => {
        googleLoginSuccess(result, redirectLocation);
      })
      .catch((reason) => {
        googleLoginFailure(reason);
      });
  }

  // function tryLogin() {
  //   FacebookLogin.login({ permissions: FACEBOOK_PERMISSIONS })
  //     .then((result) => {
  //       facebookLoginSucces(result);
  //     })
  //     .catch((reason) => {
  //       facebookLoginFailure(reason);
  //     });
  // }

  const getUser = async () => {
    await UserService.getUser(servApiUrl).then((data) => {
      setUser(data);
    });
  };

  const getCurrencyRatesAndStoreIntoState = async () => {
    const currencies = await getCurrencyRates("USD");
    setCurrencyRates(currencies);
    return currencies;
  };

  const getPGEMTradingPairInformation = async (currencies) => {
    const API_URL = isLoggedIn
      ? `${servApiUrl}user/get-bitmart-trading-pair`
      : `${servApiUrl}noAuth/get-bitmart-trading-pair`;
    const requestOptions = getRequestOptions("POST", isLoggedIn);
    const info = await fetch(API_URL, requestOptions);
    const response = await info.json();
    const dollarToIdr = currencies?.IDR;
    const tradingInfo = { ...response };
    tradingInfo.usd_price = parseFloat(response.last_price);
    tradingInfo.idr_price = (tradingInfo.usd_price * dollarToIdr).toFixed(3);
    setPgemTradingPairInfo(tradingInfo);
  };

  const getDataAndStoreIntoContext = async () => {
    if (isAuth()) {
      await getUser();
    }
    const currencies = await getCurrencyRatesAndStoreIntoState();
    if (IS_PROD) {
      await getPGEMTradingPairInformation(currencies);
    }

    setLoading(false);
  };

  // const getDataAndStoreIntoContextAfterLogin = async () => {
  //   const currencies = await getCurrencyRatesAndStoreIntoState();
  //   await getPGEMTradingPairInformation(currencies);
  // };

  useEffect(() => {
    getDataAndStoreIntoContext();

    if (Capacitor.isNativePlatform()) {
      setupAdmobBanner();
    }
  }, []);

  useEffect(() => {
    if (user) setIsLoggedIn(true);
  }, [user]);

  const login = (user, redirectLocation = null) => {
    let requestOptions = {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify(user),
    };
    fetch(`${servApiUrl}user/login`, requestOptions)
      .then((response) => response.json())
      .then(async (data) => {
        if (data["token"]) {
          localStorage.setItem("pg-auth-token", data["token"]);
          const user = await UserService.getUser(servApiUrl);
          // await getDataAndStoreIntoContextAfterLogin();
          if (user) {
            setUser(user);
            loginAlert();
            navigate(redirectLocation ?? PAGES.HOME);
          }
        } else {
          Swal.fire({
            icon: "error",
            title: "Incorrect credentials",
            toast: true,
            position: "center",
            showConfirmButton: false,
            timer: 1500,
          });
        }
      });
  };

  const logout = () => {
    removeToken();
    setIsLoggedIn(false);
    setUser(null);
  };

  return (
    <UserContext.Provider
      value={{
        streamerId,
        setStreamerId,
        servApiUrl,
        message,
        login,
        setMessage,
        setUser,
        user,
        show_chat_box,
        setShowChatBox,
        logout,
        googleSignIn,
        // tryLogin,
        // facebookSignIn: { facebookLoginFailure, facebookLoginSucces },
        socket,
        pgemTradingPairInfo,
        currencyRates,
        isLoggedIn,
        setLoading,
      }}
    >
      {!loading ? children : <BoxLoading />}
    </UserContext.Provider>
  );
};

export const useUserContext = () => useContext(UserContext);

export default UserContext;
