import { lazy } from "react";
import { PAGES } from "../pages";

import HomeNonAuth from "../../pages/home/HomeNonAuth";
import NonAuthStreaming from "../../pages/streamerStreaming/NonAuthStreaming";
import Welcome_back from "../../components/welcomeback/Welcome_back";

const Create_Profile = lazy(() =>
  import("../../components/create profile/Create_Profile")
);
const ForgotPassword = lazy(() => import("../../components/forgotPassword"));
const Preference = lazy(() => import("../../components/preference/Preference"));
const StartScreen = lazy(() => import("../../pages/startScreen/StartScreen"));

export const UN_PROTECTED_ROUTES = [
  {
    path: PAGES.CREATE_PROFILE,
    element: <Create_Profile />,
    lazy: true,
  },
  {
    path: PAGES.FORGET_PASSWORD,
    element: <ForgotPassword />,
    lazy: true,
  },
  {
    path: PAGES.LOGIN,
    element: <Welcome_back />,
    lazy: false,
  },
  {
    path: PAGES.NON_AUTH_STREAMING,
    element: <NonAuthStreaming />,
    lazy: false,
  },
  {
    path: PAGES.START_SCREEN,
    element: <StartScreen />,
    lazy: true,
  },
  {
    path: PAGES.PREFERENCE,
    element: <Preference />,
    lazy: true,
  },
];
