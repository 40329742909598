export const ENVS = {
  PROD: "prod",
  DEV: "dev",
};

// Variable to control environment
const ENV = ENVS.PROD;

export const IS_PROD = ENV == ENVS.PROD;

export const BASE_URL = "https://yugengamers.com";
