import { Button } from "@mui/material";
import { makeStyles } from "@mui/styles";
import React, { useState, useEffect } from "react";
import classes from "./PaymentShowCase.module.css";

const useStyles = makeStyles((theme) => ({
  confirmBtn: {
    border: "1px rgba(198, 255, 0, 1) solid",
    filter: "drop-shadow(0px 16px 30px rgba(0, 0, 0, 0.06))",
    borderRadius: "8px",
    padding: "10px",
    flex: 1,
    whiteSpace: "nowrap",
    background: "#C6FF00",
    fontFamily: "Poppins",
    fontStyle: "normal",
    fontWeight: 600,
    fontSize: "13.8808px",
    color: "#212134",
    textTransform: "none",
    display: "flex",
    justifyContent: "space-evenly",
  },
  actionRoot: {
    borderTop: "1.32px rgba(198, 255, 0, 1) solid",
    padding: "20px 0px",
    display: "flex",
    gap: "25px",
  },
}));

const PaymentShowCase = ({
  bid = true,
  setOpen,
  title,
  balanceList,
  btnProps = {},
}) => {
  const muiClasses = useStyles();
  const [balance, setBalance] = useState([]);

  useEffect(() => {
    let data = [
      {
        title: "Your bidding balance",
        money: "50.00",
      },
      {
        title: "Your balance",
        money: "05.00",
      },
      {
        title: "Service Fee",
        money: "05.00",
      },
    ];

    if (Array.isArray(balanceList)) setBalance(balanceList);
    else setBalance(data.slice(bid ? 0 : 1));
  }, [bid]);

  return (
    <div className={classes.container}>
      <h2>
        {title ||
          "You are about to place a bid for NFT NAME from NFT COLLECTION."}
      </h2>

      {bid && (
        <div className={classes.yourBid}>
          <p>Your Bid</p>
          <div>
            <p>100</p> <p>PGEM</p>
          </div>
          <sub>Must be at least 0.00111 PGEM</sub>
        </div>
      )}
      <div className={classes.balance}>
        {balance.map(({ title, money }) => {
          return (
            <div>
              <p>{title}</p> <p>${money}</p>
            </div>
          );
        })}
      </div>

      <footer className={muiClasses.actionRoot}>
        <div style={{ padding: "0px 20px", fontSize: "0.9rem" }}>
          PAY USING
          <p>PGM Token</p>
        </div>
        <Button
          onClick={setOpen.bind(this, false)}
          classes={{ root: muiClasses.confirmBtn }}
        >
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
            }}
          >
            <p style={{ margin: 0 }}>
              $
              {balance
                .reduce((prev, curr) => {
                  prev = parseInt(prev) + parseInt(curr.money);
                  return prev;
                }, 0)
                .toFixed(2)}
            </p>
            Total
          </div>
          {btnProps.title || (bid ? "PLACE A BID" : "BUY NOW")}
        </Button>
      </footer>
    </div>
  );
};

export default PaymentShowCase;
