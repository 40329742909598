import MenuIcon from "@mui/icons-material/Menu";
import { memo } from "react";

import classes from "./BottomBar.module.css";

const BottomBar = ({ setOpen }) => {
  return (
    <div className={classes.container}>
      <div className={classes.innerContainer}>
        <div className={classes.touchContainer}></div>
        <div className={classes.line1}></div>
        <div className={classes.line2}></div>
        <div className={classes.box}></div>
        <div className={classes.fillBox1}></div>
        <div className={classes.fillBox2}></div>
        <div
          onClick={() => {
            setOpen(true);
          }}
          className={classes.iconContainer}
        >
          <MenuIcon />
        </div>
      </div>
    </div>
  );
};

export default memo(BottomBar);
