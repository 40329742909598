import DownArrow from "@mui/icons-material/ArrowDownward";
import { Button } from "@mui/material";
import React, { useContext } from "react";
import logo from "../../images/logo.svg";
import Dialog from "../../wrappers/Dialog";
import UserContext from "../../context/UserContext";
import { swalFormError } from "../../functions/swal";
import "./css/ConfirmDialogs.css";
import useIsAuth from "../../hooks/auth/useIsAuth";

function ConfirmSwapDialog({ swapData }) {
  const { servApiUrl, setMessage } = useContext(UserContext);
  const { checkAuthAndRedirect } = useIsAuth();

  const postOrder = async (setOpen) => {
    if (
      !swapData ||
      !swapData.quote ||
      !swapData.amount ||
      swapData.amount <= 0
    ) {
      swalFormError("Please fill all the fields.");
      return;
    }

    let requestOptions = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + localStorage.getItem("pg-auth-token"),
      },
      body: JSON.stringify({
        quote: swapData.quote,
        base: "PGEM",
        amount: swapData.amount,
        orderType: "BUY",
      }),
    };
    await fetch(`${servApiUrl}user/postOrder`, requestOptions)
      .then((response) => response.json())
      .then((data) => {
        if (!data.success) {
          setMessage({ message: data.msg, type: "error" });
        } else {
          setMessage({ message: data.msg, type: "success" });
          setOpen(false);
        }
      });
  };

  const exchangeTable = (
    <>
      <table id="confirm-dialog-buy_sell-table">
        <thead>
          <tr>
            <th></th>
            <th></th>
            <th></th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>
              <div className="confirm-swap-dialog-flex-center">
                <img
                  alt="Fan token"
                  src={swapData?.market?.quote_id?.streamer_id?.avatar}
                  className="confirm-polyx-buysell-dialog-image"
                />
              </div>
            </td>
            <td>
              <div className="confirm-swap-dialog-flex-center">
                <b className="confirm-swap-dialog-padding border-1-text-color">
                  {swapData.amount}
                </b>
              </div>
            </td>
            <td>
              <div className="confirm-swap-dialog-flex-center">
                <span className="confirm-polyx-buysell-dialog-quote">
                  {swapData.quote}
                </span>
              </div>
            </td>
          </tr>
          <tr>
            <td></td>
            <td>
              <div className="confirm-swap-dialog-flex-center">
                <DownArrow className="confirm-polyx-buysell-dialog-down_arrow" />
              </div>
            </td>
            <td></td>
          </tr>
          <tr>
            <td>
              <div className="confirm-swap-dialog-flex-center">
                <img
                  alt="Logo"
                  src={logo}
                  className="confirm-polyx-buysell-dialog-image"
                />
              </div>
            </td>
            <td>
              <div className="confirm-swap-dialog-flex-center">
                <b className="confirm-swap-dialog-padding border-1-text-color">
                  {(
                    swapData.amount *
                    (swapData.market.base_pool / swapData.market.quote_pool)
                  ).toFixed(4)}
                </b>
              </div>
            </td>
            <td>
              <div className="confirm-swap-dialog-flex-center">
                <span className="confirm-polyx-buysell-dialog-quote">PGEM</span>
              </div>
            </td>
          </tr>
        </tbody>
      </table>
    </>
  );

  const handleButtonClick = (setOpen) => {
    if (!checkAuthAndRedirect()) return;

    setOpen(true);
  };

  return (
    <Dialog
      actions={{
        successBtnProps: {
          title: "Confirm Buy",
        },
      }}
      title="Confirm Swap"
      handler={{
        cancel: (setOpen) => {
          setOpen(false);
        },
        success: (setOpen) => {
          postOrder(setOpen);
        },
      }}
      Source={({ setOpen }) => (
        <Button
          onClick={() => {
            handleButtonClick(setOpen);
          }}
          variant="contained"
          className="buttonBuy"
        >
          BUY
        </Button>
      )}
    >
      <div
        id="share_main_container"
        style={{
          display: "flex",
          flexDirection: "column",
          color: "var(--text-color)",
        }}
      >
        <div
          id="swap_info_box"
          style={{
            padding: "10px",
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
            width: "100%",
          }}
        >
          {exchangeTable}
          {/* <div className="confirm-polyx-buysell-dialog-container">
            <img
              alt="Fan token"
              src={swapData?.market?.quote_id?.streamer_id?.avatar}
              className="confirm-polyx-buysell-dialog-image"
            />
            <b>{swapData.amount}</b>{" "}
            <span className="confirm-polyx-buysell-dialog-quote">
              {swapData.quote}
            </span>
          </div>
          <DownArrow className="confirm-polyx-buysell-dialog-down_arrow" />
          <div className="confirm-polyx-buysell-dialog-container">
            <img
              alt="Logo"
              src={logo}
              className="confirm-polyx-buysell-dialog-image"
            />
            <b>
              {(
                swapData.amount *
                (swapData.market.base_pool / swapData.market.quote_pool)
              ).toFixed(4)}
            </b>
            <span className="confirm-polyx-buysell-dialog-quote">PGEM</span>
          </div> */}
          {/* <p style={{ color: "gray", fontSize: "0.8em" }} align="center">
            {" "}
            Output is estimated. You will receive at least 0.0508688 ETH or the
            transaction will revert.{" "}
          </p> */}
        </div>
        <div
          style={{
            width: "100%",
            height: "1px",
            backgroundColor: "var(--primary-color)",
            marginBottom: "10px",
          }}
        />
        <div
          id="swap_details_box"
          style={{
            display: "flex",
            flexDirection: "column",
            width: "100%",
          }}
        >
          {" "}
          <span
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between",
              alignItems: "center",
              color: "var(--text-color)",
              margin: "0px",
            }}
          >
            {" "}
            <p
              style={{
                fontSize: "0.7em",
                color: "var(--text-color)",
                margin: "0px",
              }}
            >
              Price
            </p>{" "}
            <h5 style={{ margin: "0px" }}>
              {" "}
              <b>
                {(
                  swapData.market.base_pool / swapData.market.quote_pool
                ).toFixed(4)}{" "}
                {swapData.quote}/PGEM
              </b>{" "}
            </h5>{" "}
          </span>
          <span
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between",
              alignItems: "center",
              color: "var(--text-color)",
              margin: "0px",
            }}
          >
            {" "}
            <p
              style={{
                fontSize: "0.7em",
                color: "var(--text-color)",
                margin: "0px",
              }}
            >
              Minimum Requirements
            </p>{" "}
            <h5 style={{ margin: "0px" }}>
              {" "}
              <b>10 PGEM</b>{" "}
            </h5>{" "}
          </span>
          <span
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between",
              alignItems: "center",
              color: "var(--text-color)",
              margin: "0px",
            }}
          >
            {" "}
            <p
              style={{
                fontSize: "0.7em",
                color: "var(--text-color)",
                margin: "0px",
              }}
            >
              Price Impact
            </p>{" "}
            <h5 style={{ margin: "0px" }}>
              {" "}
              <b>0.01%</b>{" "}
            </h5>{" "}
          </span>
          <span
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between",
              alignItems: "center",
              color: "var(--text-color)",
              margin: "0px",
            }}
          >
            {" "}
            <p
              style={{
                fontSize: "0.7em",
                color: "var(--text-color)",
                margin: "0px",
              }}
            >
              Liquidity Provider Fee
            </p>{" "}
            <h5 style={{ margin: "0px" }}>
              {" "}
              <b>0.5 PGEM</b>{" "}
            </h5>{" "}
          </span>{" "}
        </div>
      </div>
    </Dialog>
  );
}

export default ConfirmSwapDialog;
