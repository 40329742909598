import Box from "@mui/material/Box";
import Divider from "@mui/material/Divider";
// import Drawer from "@mui/material/Drawer";
import React, { useContext } from "react";
import { Link, useNavigate } from "react-router-dom";
import UserContext from "../../context/UserContext";
import eyeIcon from "../../images/icons/eye.png";
import polygameLogoIcon from "../../images/logo.svg";
import infoIcon from "../../images/icons/info.png";
import Logout from "../dialogs/Logout";
import "./sidebar.css";
import { isDesktop } from "react-device-detect";
import DesktopSidebar from "./components/desktopSidebar/desktopSidebar";

import { MOBILE_AUTH_SIDEBAR_ROUTES } from "../../data/sidebarRoutes/authSidebarRoutes";
import { MOBILE_NON_AUTH_SIDEBAR_ROUTES } from "../../data/sidebarRoutes/nonAuthSidebarRoutes";

const drawerWidth = 270;

export const Sidebar = ({ handleDrawerToggle, mobileOpen }) => {
  const { user, activeLink, setActiveLink } = useContext(UserContext);
  const navigate = useNavigate();

  const optionStyles = { borderRadius: "50%", height: "32px" };

  const isValidRoute = (route) =>
    route.for.includes(user.role) || route.for[0] == "all";

  const drawer = (
    <div className="sidebar">
      <div className="sidebarMenu">
        {user
          ? MOBILE_AUTH_SIDEBAR_ROUTES.slice(
              0,
              MOBILE_AUTH_SIDEBAR_ROUTES.length - 1
            ).map((route, index) => (
              <>
                {isValidRoute(route) && (
                  <Link
                    key={`mobile-sidebar-auth-${index}`}
                    to={route.link}
                    className="link-style"
                  >
                    <div className="dashboardIcon sidebar-icons">
                      {route.image}
                      <span>{route.name}</span>
                    </div>
                  </Link>
                )}
              </>
            ))
          : null}
        {!user
          ? MOBILE_NON_AUTH_SIDEBAR_ROUTES.slice(
              0,
              MOBILE_NON_AUTH_SIDEBAR_ROUTES.length
            ).map((route, index) => (
              <>
                {
                  <Link
                    key={`mobile-sidebar-non-auth-${index}`}
                    to={route.link}
                    className="link-style"
                  >
                    <div className="dashboardIcon sidebar-icons">
                      {route.image}
                      <span>{route.name}</span>
                    </div>
                  </Link>
                }
              </>
            ))
          : null}
        <Divider className="divider" />
        {user
          ? MOBILE_AUTH_SIDEBAR_ROUTES.slice(
              MOBILE_AUTH_SIDEBAR_ROUTES.length - 1,
              MOBILE_AUTH_SIDEBAR_ROUTES.length
            ).map((route, index) => (
              <>
                {isValidRoute(route) && (
                  <Link
                    key={`mobile-sidebar-auth-${index}`}
                    to={route.link}
                    className="link-style"
                  >
                    <div className="dashboardIcon sidebar-icons">
                      {route.image}
                      <span>{route.name}</span>
                    </div>
                  </Link>
                )}
              </>
            ))
          : null}
        <div style={{ padding: "0px", alignItems: "center" }}>
          <a
            target="_blank"
            href="https://linktr.ee/polygame.io"
            className="link-style"
          >
            <div className="dashboardIcon sidebar-icons">
              <img
                style={{
                  ...optionStyles,
                  width: "32px",
                }}
                src={infoIcon}
                alt="About icon"
                className="homeIcon icon-8"
              />
              <span>About Us</span>
            </div>
          </a>
        </div>
        <div style={{ padding: "0px", alignItems: "center" }}>
          <a
            target="_blank"
            href="https://datastudio.google.com/reporting/407c5a25-b449-47bf-93cf-053702131de4/page/1M"
            className="link-style"
          >
            <div className="dashboardIcon sidebar-icons">
              <img
                style={{
                  ...optionStyles,
                  width: "32px",
                }}
                src={eyeIcon}
                alt="Traction icon"
                className="homeIcon icon-8"
              />
              <span>View Traction</span>
            </div>
          </a>
        </div>
        {/* <div style={{ padding: "0px", alignItems: "center" }}>
          <a
            target="_blank"
            href="https://docsend.com/view/is2jzeuvf966kask"
            className="link-style"
          >
            <div className="dashboardIcon sidebar-icons">
              <img
                style={{
                  ...optionStyles,
                  width: "32px",
                }}
                src={polygameLogoIcon}
                alt="Tokenomics"
                className="homeIcon icon-8"
              />
              <span>Tokenomics</span>
            </div>
          </a>
        </div> */}
        {user ? (
          <div className="logout">
            <Logout />
          </div>
        ) : (
          ""
        )}
      </div>
    </div>
  );

  return (
    <Box
      sx={{
        width: { xs: 0, lg: drawerWidth },
        flexShrink: { sm: 0 },
        width: isDesktop ? "100px" : "0px",
      }}
      aria-label="mailbox folders"
    >
      {/* <Drawer
        variant="temporary"
        open={mobileOpen}
        onClose={handleDrawerToggle}
        ModalProps={{
          keepMounted: true, // Better open performance on mobile.
        }}
        sx={{
          display: { xs: "block", lg: "none" },
          "& .MuiDrawer-paper": {
            boxSizing: "border-box",
            width: drawerWidth,
            backgroundColor: "#1a2028",
          },
        }}
      >
        {drawer}
      </Drawer> */}
      {isDesktop ? (
        <DesktopSidebar
          user={user}
          navigate={navigate}
          activeLink={activeLink}
          setActiveLink={setActiveLink}
        />
      ) : null}
    </Box>
  );
};
