import { Close } from "@mui/icons-material";
import AppModal from "../../../../components/modals/appModal/appModal";

import { ANDROID_APP_URL } from "../../../../data/appInfo";

import classes from "./DownloadAppModal.module.css";

const DownloadAppModal = ({ setOpen }) => {
  const handleClose = () => {
    setOpen(false);
  };

  const handleDownloadApp = () => {
    window.location.assign(ANDROID_APP_URL);
  };

  const Header = () => (
    <div className={classes.header_container}>
      <p>Download App</p>
      <div onClick={handleClose}>
        <Close />
      </div>
    </div>
  );

  const Content = () => (
    <div className={classes.content_container}>
      <p>This feature is only available on mobile app</p>
      <p>Please download the app to use this feature</p>
      <div className={classes.button_container}>
        <button className={classes.button} onClick={handleDownloadApp}>
          Download App
        </button>
      </div>
    </div>
  );

  return (
    <AppModal setOpen={setOpen}>
      <div className={classes.container}>
        <Header />
        <Content />
      </div>
    </AppModal>
  );
};

export default DownloadAppModal;
