import { memo, useEffect, useState } from "react";

import ReactApexChart from "react-apexcharts";

import "./chart-styles.css";

const ExchangeLineChart = ({ data }) => {
  const [series, setSeries] = useState([
    {
      data: [],
    },
  ]);
  const [options] = useState({
    chart: {
      id: "area-datetime",
      type: "area",
      height: 350,
      zoom: {
        autoScaleYaxis: true,
      },
      background: "transparent",
    },
    annotations: {
      yaxis: [
        {
          borderColor: "#999",
          label: {
            show: true,
            text: "Support",
            style: {
              color: "#fff",
              background: "#00E396",
            },
          },
        },
      ],
      xaxis: [
        {
          borderColor: "#999",
          yAxisIndex: 0,
          label: {
            show: true,
            text: "Rally",
            style: {
              color: "#fff",
              background: "#775DD0",
            },
          },
        },
      ],
    },
    dataLabels: {
      enabled: false,
    },
    markers: {
      size: 0,
      style: "hollow",
    },
    xaxis: {
      type: "datetime",
      tickAmount: 6,
    },
    yaxis: {
      labels: {
        formatter: function (value) {
          return value.toFixed(2);
        },
      },
    },
    tooltip: {
      x: {
        format: "dd MMM yyyy",
      },
      enabled: true,
      fillSeriesColor: true,
      theme: "light",
    },
    fill: {
      colors: "#9147ff",
    },
    stroke: {
      colors: ["#9147ff"],
      width: 3,
    },
    theme: {
      mode: "light",
    },
  });

  useEffect(() => {
    if (!data) return;

    setSeries([
      {
        data,
      },
    ]);
  }, [data]);

  return (
    <div id="chart">
      <div id="chart-timeline">
        <ReactApexChart
          options={options}
          series={series}
          type="area"
          height={350}
        />
      </div>
    </div>
  );
};

export default memo(ExchangeLineChart);
