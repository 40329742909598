import React from "react";
import classes from "./heroheading.module.css";

function HeroHeading() {
  return (
    <div className={classes.home_top_heading}>
      <h2>
        Watch<span>it</span>.Own<span>it</span>.Earn<span>it</span>
      </h2>
    </div>
  );
}

export default HeroHeading;
