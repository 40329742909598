import { useState } from "react";
import { useUserContext } from "../../context/UserContext";
import { getRequestOptions } from "../../functions/auth";
import { swalFormError } from "../../functions/swal";

const useGetTrades = () => {
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState([]);
  const { servApiUrl, isLoggedIn } = useUserContext();

  const getTrades = async (time, symbol) => {
    setLoading(true);

    const payload = { time, symbol };

    const requestOptions = getRequestOptions("POST", isLoggedIn, payload);

    const API_URL = isLoggedIn
      ? `${servApiUrl}trades/get-trades`
      : `${servApiUrl}noAuth/get-trades`;

    try {
      const res = await fetch(API_URL, requestOptions);
      const response = await res.json();
      if (response.success) {
        setData(response.data);
      } else {
        setData([]);
      }
    } catch (err) {
      console.error(err);
      swalFormError("Some error occurred");
    } finally {
      setLoading(false);
    }
  };

  return {
    getTrades,
    loading,
    data,
  };
};

export default useGetTrades;
