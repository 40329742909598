import React, { useState, useEffect, useContext, useMemo } from "react";
import "./polycoins.css";
import polycoins from "../../images/polycoins.svg";
import polycoins_image from "../../images/polycoins_image.svg";
import polycoins_image_mobile from "../../images/polycoins_image_mobile.svg";
import DashboardLayout from "../../wrappers/dashboard/index";
import ContentWrapper from "../../wrappers/default/components/content";
import Stack from "@mui/material/Stack";
import UserContext from "../../context/UserContext";
import StreamerDashboard from "./streamerDashboard/streamerDashboard";
import { getAuthToken } from "../../functions/auth";
import InfoIcon from "@mui/icons-material/Info";
import CalculationsPopup from "./components/calculationsPopup/calculationsPopup";
import { getDefaultUserAvatar } from "../../functions/default";

const USER_WATCH_SESSION_TIMES_INITIAL_STATE = {
  ytd: {
    mins: 0,
    secs: 0,
  },
  mtd: {
    mins: 0,
    secs: 0,
  },
  today: {
    mins: 0,
    secs: 0,
  },
};

export const Polycoins = () => {
  var [polycoinsData, setPolycoinsData] = useState([]);
  var [wallets, setWallets] = useState([]);
  const { servApiUrl, user, pgemTradingPairInfo, currencyRates } =
    useContext(UserContext);
  const [userWatchSessionTimes, setUserWatchSessionTimes] = useState(null);
  const [moneyEarned, setMoneyEarned] = useState(null);
  const [openCalculationsPopup, setOpenCalculationsPopup] = useState(false);

  function getPgemEarnings(money) {
    const pgemUsdPrice = pgemTradingPairInfo?.usd_price;
    const idrToDollar = 1 / currencyRates?.IDR;
    const usdEarned = money * idrToDollar;
    return (usdEarned / pgemUsdPrice).toFixed(4);
  }

  function getPgemToIdr(pgem) {
    if (!pgem) return "00:00";
    const pgemUsdPrice = pgemTradingPairInfo?.usd_price;
    const dollarToIdr = currencyRates?.IDR;
    const usdEarned = pgem * pgemUsdPrice;
    const idrEarned = (usdEarned * dollarToIdr).toFixed(2);
    return idrEarned;
  }

  var getPolycoins = () => {
    let requestOptions = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + localStorage.getItem("pg-auth-token"),
      },
      body: JSON.stringify({ symbol: "PGEM" }),
    };
    fetch(`${servApiUrl}user/getWallet/`, requestOptions)
      .then((response) => response.json())
      .then((data) => {
        setPolycoinsData(data);
      });
  };

  var getWallets = () => {
    let requestOptions = {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: getAuthToken(),
      },
    };
    fetch(`${servApiUrl}user/getWallets/`, requestOptions)
      .then((response) => response.json())
      .then((data) => {
        setWallets(data);
      });
  };

  const getMoneyEarned = (ytd) => {
    const mins = ytd.mins;
    const secs = ytd.secs;
    let totalSecs = mins * 60 + secs;
    totalSecs /= 5;
    let money = totalSecs;
    money = money.toFixed(2);
    money = parseFloat(money);
    return money;
  };

  const getUserWatchSessionTimes = async () => {
    if (!user) return;
    const requestOptions = {
      method: "GET",
      headers: {
        Authorization: "Bearer " + localStorage.getItem("pg-auth-token"),
      },
    };

    const res = await fetch(
      `${servApiUrl}user/get-user-watch-session-times/${user._id}`,
      requestOptions
    );

    const response = await res.json();
    if (response?.success) {
      const userTimes = response.data;
      const userWatchSessionTimesObject =
        USER_WATCH_SESSION_TIMES_INITIAL_STATE;

      userWatchSessionTimesObject.ytd.mins = Math.floor(
        userTimes.ytd?.duration / 60
      );
      userWatchSessionTimesObject.ytd.secs = Math.floor(
        userTimes.ytd?.duration % 60
      );

      userWatchSessionTimesObject.mtd.mins = Math.floor(
        userTimes.mtd?.duration / 60
      );
      userWatchSessionTimesObject.mtd.secs = Math.floor(
        userTimes.mtd?.duration % 60
      );

      userWatchSessionTimesObject.today.mins = Math.floor(
        userTimes.today?.duration / 60
      );
      userWatchSessionTimesObject.today.secs = Math.floor(
        userTimes.today?.duration % 60
      );

      const moneyEarningsObject = {};
      moneyEarningsObject.ytd = userTimes.ytd?.earnings;
      moneyEarningsObject.mtd = userTimes.mtd?.earnings;
      moneyEarningsObject.today = userTimes.today?.earnings;

      setMoneyEarned(moneyEarningsObject);
      setUserWatchSessionTimes(userWatchSessionTimesObject);
    }
  };

  useEffect(async () => {
    getUserWatchSessionTimes();
    getPolycoins();
    getWallets();
  }, []);

  const pgem_token = wallets?.filter((wallet) => {
    return wallet?.wallet?.coin_id?.symbol == "PGEM";
  });

  const remaining_tokens = wallets?.filter((wallet) => {
    return wallet?.wallet?.coin_id?.symbol !== "PGEM";
  });

  const getModifiedTimeString = (time) => (time < 10 ? "0" + time : time);

  const getTime = (time) => {
    return (
      getModifiedTimeString(time.mins) + ":" + getModifiedTimeString(time.secs)
    );
  };

  const openCalculationsPopupModal = () => {
    setOpenCalculationsPopup(true);
  };

  const watchToEarnTable = useMemo(() => {
    if (!userWatchSessionTimes || !moneyEarned) return;

    return (
      <>
        <table id="polycoins-w2earn-table">
          <thead>
            <tr>
              <th>Type</th>
              <th>Time</th>
              <th>PGEM</th>
              <th>IDR</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>Yesterday</td>
              <td>{getTime(userWatchSessionTimes.today)}</td>
              <td>{getPgemEarnings(moneyEarned.today)}</td>
              <td>{moneyEarned.today?.toFixed(2)}</td>
            </tr>
            <tr>
              <td>Month</td>
              <td>{getTime(userWatchSessionTimes.mtd)}</td>
              <td>{getPgemEarnings(moneyEarned.mtd)}</td>
              <td>{moneyEarned.mtd?.toFixed(2)}</td>
            </tr>
            <tr>
              <td>Year</td>
              <td>{getTime(userWatchSessionTimes.ytd)}</td>
              <td>{getPgemEarnings(moneyEarned.ytd)}</td>
              <td>{moneyEarned.ytd?.toFixed(2)}</td>
            </tr>
          </tbody>
        </table>
        <div className="watch_to_earn_calculations_container">
          <div onClick={openCalculationsPopupModal}>
            <InfoIcon />
          </div>
          <p>Click to learn how this information is calculated</p>
        </div>
      </>
    );
  }, [userWatchSessionTimes, moneyEarned]);

  return (
    <>
      {openCalculationsPopupModal ? (
        <CalculationsPopup
          open={openCalculationsPopup}
          setOpen={setOpenCalculationsPopup}
        />
      ) : null}
      <DashboardLayout>
        <ContentWrapper
          breadCrumbs={[
            {
              title: "PolyWallet",
              image: <img src={polycoins} height="100%" />,
            },
          ]}
        >
          <div className="polycoins-main">
            <div className="polyconis">
              <div className="polyconis-content">
                <p style={{ fontSize: "2.5rem" }}>Polycard</p>

                <div className="balance">
                  <div>
                    <h6>
                      {polycoinsData.balance
                        ? polycoinsData?.balance.toFixed(2)
                        : "00.00"}{" "}
                      PGEM
                    </h6>
                    {<h6>{getPgemToIdr(polycoinsData?.balance)} IDR</h6>}
                  </div>
                </div>
                <div className="card_holder">
                  <p>Polycoins Address</p>
                  <h6>{polycoinsData?.user_id}</h6>
                </div>
              </div>
              <img src={polycoins_image} className="polycoins_image" />
              <img
                src={polycoins_image_mobile}
                className="polycoins_image_mobile"
              />
            </div>
            {user?.role !== "streamer" ? (
              watchToEarnTable
            ) : (
              <StreamerDashboard servApiUrl={servApiUrl} user={user} />
            )}
            <div className="details">
              <div className="pairtype">
                <p>FanToken</p>
                <p>Balance</p>
              </div>
            </div>
            <div className="vline"></div>
            {pgem_token &&
              pgem_token.map((wallet, index) => {
                // let wallet = wallets[keyName];
                // console.log(wallets");
                return (
                  <div className="Box_card" key={`pgem-wallet-${index}`}>
                    <div className="coin_content">
                      <div classname="fantoken">
                        <Stack direction="row" spacing={2}>
                          <img
                            src={getDefaultUserAvatar(
                              wallet.wallet?.coin_id?.streamer_id?.avatar
                            )}
                            style={{
                              width: "40px",
                              margin: "auto 10px",
                              "border-radius": "50%",
                              height: "40px",
                            }}
                          />
                          <h2 style={{ "line-height": "70px" }}>
                            {wallet.wallet?.coin_id?.symbol}
                          </h2>
                        </Stack>
                      </div>
                      <div>
                        <h3
                          style={{ "line-height": "70px", margin: "0px 5px" }}
                        >
                          {wallet.wallet?.balance?.toFixed(2)}
                        </h3>
                      </div>
                    </div>
                  </div>
                );
              })}
            {remaining_tokens &&
              remaining_tokens.map((wallet, index) => {
                // let wallet = wallets[keyName];
                // console.log(wallets");
                return (
                  <div className="Box_card" key={`remaining-token-${index}`}>
                    <div className="coin_content">
                      <div classname="fantoken">
                        <Stack direction="row" spacing={2}>
                          <img
                            src={getDefaultUserAvatar(
                              wallet.wallet?.coin_id?.streamer_id?.avatar
                            )}
                            style={{
                              width: "40px",
                              margin: "auto 10px",
                              "border-radius": "50%",
                              height: "40px",
                            }}
                          />
                          <h2 style={{ "line-height": "70px" }}>
                            {wallet.wallet?.coin_id?.symbol}
                          </h2>
                        </Stack>
                      </div>
                      <div
                        style={{
                          width: "40px",
                          margin: "auto 10px",
                          "border-radius": "50%",
                        }}
                      >
                        <h5>
                          {window.innerWidth > 768
                            ? parseFloat(wallet?.market?.last_price).toFixed(2)
                            : parseFloat(wallet?.market?.last_price).toFixed(2)}
                          /PGEM
                        </h5>
                      </div>
                      <div style={{ width: "150px" }}>
                        <h3
                          style={{
                            "line-height": "70px",
                            margin: "0px 5px",
                            textAlign: "end",
                          }}
                        >
                          {wallet.wallet?.balance?.toFixed(2)}
                        </h3>
                      </div>
                    </div>
                  </div>
                );
              })}

            {/* <div className="Box">
            <img className="img" src={media} />
            <p>Watch And Earn </p>
            <div className="login_icon">
              <img src={login_icon} />
            </div>
          </div> */}
            {/* <div className="Box">
            <img className="img" src={media} />
            <p>Connect Wallet </p>
            <div className="login_icon">
              <img src={login_icon} />
            </div>
          </div> */}
          </div>
        </ContentWrapper>
      </DashboardLayout>
    </>
  );
};
