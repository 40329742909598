import React, { Component } from "react";
import PropTypes from "prop-types";

import videojs from "video.js";
import "video.js/dist/video-js.css";
import "@videojs/themes/dist/city/index.css";

import "@videojs/http-streaming";

import "videojs-contrib-ads/dist/videojs.ads.css";
import "videojs-ima/dist/videojs.ima.css";

import "videojs-contrib-ads";
import "videojs-ima";
import { Capacitor } from "@capacitor/core";
import { showInterstitialVideoAd } from "../../functions/admob";
import { AdMob, InterstitialAdPluginEvents } from "@capacitor-community/admob";

class VideoPlayer extends Component {
  componentDidMount() {
    this.player = videojs(
      this.videoNode,
      this.props,
      function onPlayerReady() {}
    );
    const player = this.player;

    if (this.props.runAds === true && Capacitor.isNativePlatform()) {
      const MINUTES_INTERVAL = 1;
      const midrollInterval = MINUTES_INTERVAL * 60; // 1 minutes
      var lastMidrollTime = 0; // The time when the last mid-roll ad was played
      let playInterstatitalAd = true;

      AdMob.addListener(InterstitialAdPluginEvents.Loaded, () => {
        player.pause();
      });

      AdMob.addListener(InterstitialAdPluginEvents.Dismissed, () => {
        player.play();
      });

      player.on("play", () => {
        const currentTime = player.currentTime();
        lastMidrollTime = currentTime; // Update the last mid-roll ad time
        playInterstatitalAd = true;
      });

      player.on("timeupdate", function () {
        const currentTime = player.currentTime();
        const timeSinceLastMidroll = currentTime - lastMidrollTime;

        if (
          timeSinceLastMidroll >= midrollInterval &&
          playInterstatitalAd == true
        ) {
          playInterstatitalAd = false;
          showInterstitialVideoAd(player);
        }
      });

      return;
    }
    if (this.props.runAds === true) {
      // player.ads(); // initialize videojs-contrib-ads
      var vastTagPreroll =
        "https://pubads.g.doubleclick.net/gampad/ads?iu=/21775744923/external/single_preroll_skippable&sz=640x480&ciu_szs=300x250%2C728x90&gdfp_req=1&output=vast&unviewed_position_start=1&env=vp&impl=s&correlator=";
      // var vastTagMidroll =
      //   "https://pubads.g.doubleclick.net/gampad/ads?iu=/21775744923/external/single_ad_samples&sz=640x480&cust_params=sample_ct%3Dlinear&ciu_szs=300x250%2C728x90&gdfp_req=1&output=vast&unviewed_position_start=1&env=vp&impl=s&correlator=";
      var vastTagMidroll =
        "https://pubads.g.doubleclick.net/gampad/ads?iu=/21775744923/external/single_preroll_skippable&sz=640x480&ciu_szs=300x250%2C728x90&gdfp_req=1&output=vast&unviewed_position_start=1&env=vp&impl=s&correlator=";
      var vastTagPostroll =
        "https://pubads.g.doubleclick.net/gampad/ads?iu=/21775744923/external/vmap_ad_samples&sz=640x480&cust_params=sample_ar%3Dpostonly&ciu_szs=300x250&gdfp_req=1&ad_rule=1&output=vmap&unviewed_position_start=1&env=vp&impl=s&correlator=";
      var prerollTriggered = true;
      var postrollTriggered = false;
      var midrollRequested = false;
      const minutesIntervalMidRoll = 1;
      var midrollInterval = minutesIntervalMidRoll * 60; // 1 minutes
      var lastMidrollTime = 0; // The time when the last mid-roll ad was played

      if (!prerollTriggered) {
        player.ima({
          adTagUrl: vastTagPreroll,
          showControlsForAds: true,
          debug: false,
        });
      } else {
        player.ima({
          adTagUrl: "",
          showControlsForAds: true,
          debug: false,
        });
      }

      player.ima.initializeAdDisplayContainer();

      function requestMidrollAd() {
        midrollRequested = true;
        player.ima.changeAdTag(vastTagMidroll);
        player.ima.requestAds();
      }

      player.on("timeupdate", function () {
        var currentTime = player.currentTime();
        var timeSinceLastMidroll = currentTime - lastMidrollTime;

        if (timeSinceLastMidroll >= midrollInterval && !midrollRequested) {
          lastMidrollTime = currentTime; // Update the last mid-roll ad time
          requestMidrollAd();
        }
      });

      player.on("ended", function () {
        if (!postrollTriggered) {
          postrollTriggered = true;
          // console.log("Postroll triggered");

          player.ima.requestAds({
            adTagUrl: vastTagPostroll,
          });

          // console.log("Postroll ads requested");
        }
      });

      player.on("adsready", function () {
        if (midrollRequested) {
          // console.log("Ads ready - midroll");
        } else {
          console.log("Ads ready - preroll");
          player.src(
            "https://cph-p2p-msl.akamaized.net/hls/live/2000341/test/master.m3u8"
          );
        }
      });

      player.on("aderror", function () {
        console.log("Ads aderror");
        player.play();
      });

      player.on("adend", function () {
        if (lastMidrollTime > 0) {
          // console.log("A midroll ad has finished playing.");
          midrollRequested = false;
        } else {
          console.log("The preroll ad has finished playing.");
          prerollTriggered = true;
        }

        player.play();
      });
    }
  }

  componentWillUnmount() {
    if (this.player) {
      this.player.dispose();
    }
  }

  render() {
    let videoProps = {};
    if (this.props.isMuted === true) {
      videoProps.muted = "muted";
    }

    return (
      <video
        autoPlay={this.props.autoPlay ?? true}
        {...videoProps}
        ref={(node) => (this.videoNode = node)}
        style={{ width: this.props.width, height: this.props.height }}
        className="video-js vjs-default-skin videoPlayer vjs-theme-city"
        controls={this.props.controls ?? true}
        id={this.props.id ?? ""}
        preload="auto"
        playsInline
        webkitplaysinline="true"
        poster={this.props.poster}
      >
        <source src={this.props.source} type="application/x-mpegURL" />
      </video>
    );
  }
}

VideoPlayer.propTypes = {
  width: PropTypes.string,
  height: PropTypes.string,
  source: PropTypes.string.isRequired,
  isMuted: PropTypes.bool,
  autoPlay: PropTypes.bool,
  id: PropTypes.string,
  controls: PropTypes.bool,
  runAds: PropTypes.bool,
  poster: PropTypes.string,
};

export default VideoPlayer;
