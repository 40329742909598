import { useContext, useEffect, useMemo } from "react";
import { useNavigate } from "react-router-dom";

import Grid from "@mui/material/Grid";
import AddIcon from "@mui/icons-material/Add";

import DashboardLayout from "../../wrappers/dashboard";
import { PAGES } from "../../data/pages";
import { getAuthToken } from "../../functions/auth";
import useGetSocialMediaItems from "../../hooks/api/useGetSocialMediaItems";
import UserContext from "../../context/UserContext";
import useIsAuth from "../../hooks/auth/useIsAuth";
import SocialMediaShare from "../../components/socialMediaShare/socialMediaShare";
import PolyCommunityItem from "./components/polyCommunityItem/polyCommunityItem";

import PermMediaIcon from "@mui/icons-material/PermMedia";
import InfoIcon from "@mui/icons-material/Info";

import "./polyCommunity.css";
import { CommonLoading } from "react-loadingg";
import useInfiniteScroll from "../../hooks/useInfiniteScroll";

function PolyCommunity() {
  const { servApiUrl, user } = useContext(UserContext);
  const navigate = useNavigate();
  const {
    loading,
    setData: setSocialMediaItems,
    data: socialMediaItems,
    increasePageAndGetItems,
    stopLoading,
  } = useGetSocialMediaItems(servApiUrl);
  useInfiniteScroll(increasePageAndGetItems, loading);
  const { checkAuthAndRedirect } = useIsAuth();
  const isMobileWidth = window.screen.width < 650;

  useEffect(() => {
    increasePageAndGetItems();
  }, []);

  const toggleLikeInItemsState = (_id) => {
    const socialMediaItemsClone = [...socialMediaItems];

    for (let i = 0; i < socialMediaItemsClone.length; i++) {
      const socialItem = socialMediaItemsClone[i];
      if (socialItem._id === _id) {
        if (typeof socialItem.likedUsers !== "undefined") {
          socialItem.likedUsers[user._id] = !socialItem.likedUsers[user._id];
          if (socialItem.likedUsers[user._id] === true) {
            socialItem.likes += 1;
          } else {
            socialItem.likes -= 1;
          }
        } else {
          socialItem.likedUsers[user._id] = true;
          socialItem.likes += 1;
        }
        break;
      }
    }

    setSocialMediaItems(socialMediaItemsClone);
  };

  const likeUnlikeSocialMediaItem = async (_id) => {
    if (!checkAuthAndRedirect({ redirectTo: PAGES.POLY_COMMUNITY })) return;
    const res = await fetch(`${servApiUrl}user/like-unlike-social-media-item`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: getAuthToken(),
      },
      body: JSON.stringify({ itemId: _id, userId: user._id }),
    });
    const response = await res.json();

    if (response.success === true) {
      toggleLikeInItemsState(_id);
    }
  };

  const showItems = useMemo(() => {
    return (
      <>
        {socialMediaItems.map((item, index) => (
          <div
            style={{
              width: "100%",
            }}
            key={`social-media-item-${index}`}
          >
            <PolyCommunityItem
              type={item.type}
              link={item.link}
              likes={item.likes}
              postedBy={item.postedBy}
              commentsCount={item.commentsCount}
              _id={item._id}
              likedUsers={{ ...item.likedUsers }}
              user={user}
              likeUnlikeSocialMediaItem={likeUnlikeSocialMediaItem}
              description={item?.description}
              servApiUrl={servApiUrl}
              thumbnail={item.thumbnail}
            />
          </div>
        ))}
      </>
    );
  }, [socialMediaItems]);

  const endPostContainer = (
    <div className="poly_community_end_post_container">
      <InfoIcon />
      <p>No more posts. Please check later.</p>
    </div>
  );

  const socialItems = useMemo(() => {
    return (
      <div style={{ marginTop: "30px" }}>
        <Grid container spacing={2}>
          {loading && socialMediaItems.length == 0 ? (
            <CommonLoading />
          ) : (
            showItems
          )}
        </Grid>
        {stopLoading ? endPostContainer : null}
      </div>
    );
  }, [socialMediaItems, loading, stopLoading]);

  const addPostButton = (
    <div
      style={{
        display: "flex",
        justifyContent: isMobileWidth ? "center" : "flex-end",
        width: "100%",
        position: "relative",
      }}
    >
      <div className="polycommunity_add_post_container">
        {user?.role == "streamer" ? (
          <div
            className="polycommunity_add_post_button"
            onClick={() => navigate(PAGES.ADD_SOCIAL_POST)}
          >
            Add Post
            <AddIcon sx={{ fontSize: "20px" }} />
          </div>
        ) : null}
        <div
          className="polycommunity_add_post_button"
          onClick={() => navigate(PAGES.MY_SOCIAL_POSTS)}
        >
          My Posts
          <PermMediaIcon sx={{ fontSize: "20px" }} />
        </div>
      </div>
    </div>
  );

  return (
    <DashboardLayout showSidebar={false} noRightMargin={true}>
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          flexDirection: "column",
          alignItems: "center",
          gap: "20px",
        }}
      >
        {user ? addPostButton : null}
        <h2>Connect with us</h2>
        <SocialMediaShare />
      </div>
      {socialItems}
    </DashboardLayout>
  );
}

export default PolyCommunity;
