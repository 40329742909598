import classes from "./contactUs.module.css";
import DashboardLayout from "../../wrappers/dashboard/index";
import { COMPANY_INFO } from "../../data/companyInfo";

const ContactUs = () => {
  return (
    <DashboardLayout>
      <div className={classes.container}>
        <div className={classes.inner_container}>
          <p>Send your questions, queries or any problems you are facing on:</p>
          <a href={`mailto:${COMPANY_INFO.supportMail}`} target="_blank">
            {COMPANY_INFO.supportMail}
          </a>
          <p>{COMPANY_INFO.address}</p>
        </div>
      </div>
    </DashboardLayout>
  );
};

export default ContactUs;
