import { useEffect } from "react";

import { AUTH_SIDEBAR_ROUTES } from "../../../../data/sidebarRoutes/authSidebarRoutes";
import { NON_AUTH_SIDEBAR_ROUTES } from "../../../../data/sidebarRoutes/nonAuthSidebarRoutes";
import LogoutIcon from "@mui/icons-material/Logout";
import LogoutConfirmDialog from "../../../dialogs/logoutConfirmDialog";

import "./desktopSidebar.css";

const DesktopSidebar = ({ user, navigate }) => {
  const sidebarFunctionality = () => {
    const sidebar = document.querySelector(".desktop-sidebar");
    const submenuItems = document.querySelectorAll(".submenu_item");

    sidebar.addEventListener("mouseenter", () => {
      if (sidebar.classList.contains("hoverable")) {
        sidebar.classList.remove("close");
      }
    });
    sidebar.addEventListener("mouseleave", () => {
      if (sidebar.classList.contains("hoverable")) {
        sidebar.classList.add("close");
      }
    });

    submenuItems.forEach((item, index) => {
      item.addEventListener("click", () => {
        item.classList.toggle("show_submenu");
        submenuItems.forEach((item2, index2) => {
          if (index !== index2) {
            item2.classList.remove("show_submenu");
          }
        });
      });
    });

    if (window.innerWidth < 768) {
      sidebar.classList.add("close");
    } else {
      sidebar.classList.add("close");
    }
  };

  const onClickLink = (link) => {
    navigate(link);
  };

  useEffect(() => {
    sidebarFunctionality();
  }, []);

  const isValidRoute = (route) =>
    route.for.includes(user.role) || route.for[0] == "all";

  const LogoutSource = ({ setOpen }) => (
    <li className="item">
      <a
        onClick={() => setOpen(true)}
        className="nav_link"
        style={{ paddingLeft: "18px" }}
      >
        <span className="navlink_icon">
          <LogoutIcon sx={{ fontSize: "32px" }} />
        </span>
        <span className="navlink">Logout</span>
      </a>
    </li>
  );

  return (
    <div className="sidebar-container">
      <nav className="desktop-sidebar hoverable">
        <div className="menu_content">
          <ul className="menu_items">
            {user
              ? AUTH_SIDEBAR_ROUTES.slice(
                  0,
                  AUTH_SIDEBAR_ROUTES.length - 2
                ).map((route, index) => (
                  <>
                    {isValidRoute(route) && (
                      <li className="item" key={`sidebar-route-${index}`}>
                        <a
                          onClick={() => onClickLink(route.link)}
                          className="nav_link"
                        >
                          <span className="navlink_icon">{route.image}</span>
                          <span className="navlink">{route.name}</span>
                        </a>
                      </li>
                    )}
                  </>
                ))
              : null}
            {!user
              ? NON_AUTH_SIDEBAR_ROUTES.slice(
                  0,
                  NON_AUTH_SIDEBAR_ROUTES.length - 2
                ).map((route, index) => (
                  <li className="item" key={`non-auth-sidebar-route-${index}`}>
                    <a
                      onClick={() => onClickLink(route.link)}
                      className="nav_link"
                    >
                      <span className="navlink_icon">{route.image}</span>
                      <span className="navlink">{route.name}</span>
                    </a>
                  </li>
                ))
              : null}
          </ul>
          {user ? (
            <>
              <ul className="menu_items">
                <div className="menu_title menu_dahsboard"></div>
                {AUTH_SIDEBAR_ROUTES.slice(
                  AUTH_SIDEBAR_ROUTES.length - 2,
                  AUTH_SIDEBAR_ROUTES.length
                ).map((route, index) => (
                  <>
                    {user && isValidRoute(route) && (
                      <li className="item" key={`sidebar-route-${index}`}>
                        <a
                          onClick={() => onClickLink(route.link)}
                          className="nav_link"
                        >
                          <span className="navlink_icon">{route.image}</span>
                          <span className="navlink">{route.name}</span>
                        </a>
                      </li>
                    )}
                  </>
                ))}
                <div style={{ marginTop: "5px" }}>
                  <LogoutConfirmDialog Source={LogoutSource} />
                </div>
              </ul>
            </>
          ) : (
            <>
              <ul className="menu_items">
                <div className="menu_title menu_dahsboard"></div>
                {NON_AUTH_SIDEBAR_ROUTES.slice(
                  NON_AUTH_SIDEBAR_ROUTES.length - 2,
                  NON_AUTH_SIDEBAR_ROUTES.length
                ).map((route, index) => (
                  <li className="item" key={`non-auth-sidebar-route-${index}`}>
                    <a
                      onClick={() => onClickLink(route.link)}
                      className="nav_link"
                    >
                      <span className="navlink_icon">{route.image}</span>
                      <span className="navlink">{route.name}</span>
                    </a>
                  </li>
                ))}
              </ul>
            </>
          )}
        </div>
      </nav>
    </div>
  );
};

export default DesktopSidebar;
