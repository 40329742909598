import React, { useState } from "react";
import DashboardLayout from "../dashboard/index";
import classes from "./seeAllGamesAndStreams.module.css";
import searchIcon from "../../images/searchsvg.svg";
import filterimg from "../../images/filtersvg.svg";

const SeeAllGamesAndStreams = ({
  children,
  title = "Find what you want to watch?",
}) => {
  const [search, setSearch] = useState("");

  return (
    <DashboardLayout>
      <main className={classes.container}>
        <h1>{title}</h1>
        <div className={classes.search}>
          <span style={{ backgroundImage: `url(${searchIcon})` }}></span>
          <input
            value={search}
            onChange={(e) => setSearch(e.target.value)}
            type="text"
            placeholder="search"
          />
          <span style={{ backgroundImage: `url(${filterimg})` }}></span>
        </div>

        {/*<section className={classes.filters}>
          {filter.map(({ title, img }) => (
            <div>
              <span style={{ backgroundImage: `url(${img})` }}></span>
              {title}
            </div>
          ))}
          </section>*/}

        <section className={classes.content}>
          {typeof children === "function" ? children(search) : children}
        </section>
      </main>
    </DashboardLayout>
  );
};

export default SeeAllGamesAndStreams;
