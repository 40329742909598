import { useEffect, useState } from "react";
import { getRequestOptions } from "../../functions/auth";
import { useUserContext } from "../../context/UserContext";

const useGetChatRoom = (baseUrl, name, setData, setCurrentGroupId) => {
  const [loading, setLoading] = useState(true);
  const { isLoggedIn } = useUserContext();

  const getChatRoomComments = async () => {
    setLoading(true);

    const API_URL = isLoggedIn
      ? `${baseUrl}user/get-chat-room-comments`
      : `${baseUrl}noAuth/get-chat-room-comments`;

    const requestOptions = getRequestOptions("POST", isLoggedIn, { name });

    const res = await fetch(API_URL, requestOptions);
    const response = await res.json();
    if (response.success) {
      setData(response.comments);
      setCurrentGroupId(response.id);
    }

    setTimeout(() => {
      setLoading(false);
    }, 2000);
  };

  useEffect(() => {
    getChatRoomComments();
  }, [name]);

  return { loading };
};

export default useGetChatRoom;
